import { combineReducers } from 'redux'

import sidebar from './sidebarReducers'
import layout from './layoutReducer'
import theme from './themeReducer'
import settings from './settingsReducer'

import printer from './printerReducer'

import error from './errorReducer'
import auth from './authReducer'
import rooms from './roomReducer'
import chat from './chatReducer'

import { reducer as toastr } from 'react-redux-toastr'

export default combineReducers({
  sidebar,
  layout,
  theme,
  settings,
  printer,
  error,
  auth,
  rooms,
  chat,
  toastr,
})
