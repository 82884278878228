import React, { useContext, useState } from 'react'
import { Context } from './ReservationContext'
import useRooms from 'hooks/useRooms'
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

export function ChangeRoom() {
  const ctx = useContext(Context)
  const [rooms] = useRooms()

  const [selectedRoomId, setSelectedRoomId] = useState(
    rooms.find(room => room.status === 'Disponível')?.roomId
  )

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Alterar Quarto
        </CardTitle>
      </CardHeader>

      <CardBody>
        <div className='d-flex'>
          <select
            className='form-control'
            style={{ width: 300 }}
            defaultValue={
              rooms.find(room => room.status === 'Disponível')?.first
            }
            onChange={e => setSelectedRoomId(e.target.value)}
          >
            {rooms
              .filter(room => room.status === 'Disponível')
              .map(room => (
                <option key={room.roomId} value={room.roomId}>
                  {room.title} - {room.roomType.title}
                </option>
              ))}
          </select>

          <Button
            className='ml-2'
            color='primary'
            onClick={() => ctx.changeRoom(selectedRoomId)}
            disabled={!rooms.some(room => room.status === 'Disponível')}
          >
            Alterar
          </Button>
        </div>
      </CardBody>
    </Card>
  )
}
