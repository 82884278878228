import React from 'react'
import { baseURL } from 'api'
import { Container, Row, Col } from 'reactstrap'
import { Offline, Online } from 'react-detect-offline'
// import DetectPrinter from 'services/printer/detect-printer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import Version from './Version'

const Footer = () => {
  return (
    <footer className='footer'>
      <Container fluid>
        <Row className='text-muted'>
          <Col xs='6' className='text-left d-flex'>
            <div>
              <Online polling={{ url: baseURL + '/online' }}>
                <div>
                  <FontAwesomeIcon icon={faCircle} className='text-success' />{' '}
                  Online
                </div>
              </Online>
              <Offline polling={{ url: baseURL + '/online' }}>
                <div>
                  <FontAwesomeIcon icon={faCircle} className='text-danger' />{' '}
                  Verifique sua conexão...
                </div>
              </Offline>
            </div>
            {/* <div className='ml-3'>
              <DetectPrinter />
            </div> */}
          </Col>
          <Col xs='6' className='text-right'>
            <p className='mb-0'>
              <Version />
              &copy; {new Date().getFullYear()} -{' '}
              <span href='/' className='text-muted'>
                Geekery
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
