import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleReceiveAutomaticVehicleCaptures } from 'redux/actions/settingsActions'
import {
  Container,
  CustomInput,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap'

export default function SettingsPicker() {
  const dispatch = useDispatch()
  const { showAutomaticVehicleCaptures } = useSelector(state => state.settings)

  return (
    <Container fluid className='p-0'>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='h3 mb-3'>Configurações</h1>
      </div>

      <Card>
        <CardHeader>
          <CardTitle tag='h5' className='mb-0'>
            Gerais
          </CardTitle>
        </CardHeader>

        <CardBody>
          <div>
            <CustomInput
              type='checkbox'
              id='showAutomaticVehicleCaptures'
              name='showAutomaticVehicleCaptures'
              checked={showAutomaticVehicleCaptures}
              onChange={() => dispatch(toggleReceiveAutomaticVehicleCaptures())}
              label='Mostrar Tela de Checkin na Captura Automática de Veículos'
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}
