import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/min/locales.min'
import api from 'api'
import { toastr } from 'react-redux-toastr'
import { calculateDuration } from 'util/reservation-duration'
import { stringToCurrency } from 'util/currency-util'
import { capitalize } from 'util/string-utils'
import { Card, CardHeader, CardTitle, Table, Row, Col } from 'reactstrap'
import HeaderDatePicker from 'components/HeaderDatePicker'
import Loader from 'components/Loader'
import ReservationListStats from './ReservationListStats'

import CameraIcon from '@material-ui/icons/LinkedCameraTwoTone'
import Car from '@material-ui/icons/DirectionsCarTwoTone'
import Motorcycle from '@material-ui/icons/MotorcycleTwoTone'
import Pedestrian from '@material-ui/icons/DirectionsWalkTwoTone'

export default function ReservationList({ history, location }) {
  const [date, setDate] = useState(
    location.search
      ? moment(new URLSearchParams(location.search).get('date'))
      : moment()
  )
  const [reservations, setReservations] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getReservations = () => {
    setIsLoading(true)
    setReservations([])
    api
      .get(`/reservations?date=${date.format('YYYY-MM-DD')}`)
      .then(({ data }) => {
        setReservations(data)
        setIsLoading(false)
      })
      .catch(err => {
        toastr.error(err.response.data)
        setIsLoading(false)
      })
  }

  useEffect(getReservations, [date])

  const iconOpacity = test => (test ? 1 : 0.1)

  return (
    <>
      <Row>
        <Col xs='12' md='6'>
          <h1 className='h3 mb-3'>Hospedagens</h1>
        </Col>

        <Col xs='12' md='6'>
          <div className='d-flex float-right mb-3'>
            <HeaderDatePicker date={date} setDate={setDate} />
          </div>
        </Col>
      </Row>

      <ReservationListStats reservations={reservations} />

      <Card className='flex-fill w-100'>
        <CardHeader>
          <CardTitle tag='h5' className='mb-0'>
            <div>
              <strong>
                {capitalize(date.locale('pt-BR').format('dddd, DD/MM/YYYY'))}
              </strong>
            </div>
          </CardTitle>
        </CardHeader>

        {isLoading ? (
          <Loader />
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th className='d-none d-md-table-cell'>#</th>
                <th className='d-none d-md-table-cell'>Placa</th>
                <th>Unidade</th>
                <th>Checkin</th>
                <th className='d-none d-md-table-cell'>Checkout</th>
                <th>Permanência</th>
                <th>Fatura</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reservations?.map((reservation, idx) => (
                <tr
                  key={reservation.reservationId}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    history.push(
                      `/reservations/${
                        reservation.reservationId
                      }?returns=/reservations?date=${date.format('YYYY-MM-DD')}`
                    )
                  }
                >
                  <td className='d-none d-md-table-cell'>{idx + 1}</td>
                  <td className='d-none d-md-table-cell'>
                    {reservation.vehicle === null ? (
                      <em>Sem Veículo</em>
                    ) : (
                      reservation.vehicle?.plate
                    )}
                  </td>
                  <td>{reservation.room?.title}</td>
                  <td>{moment(reservation.checkin).format('HH:mm:ss')}</td>
                  <td className='d-none d-md-table-cell'>
                    {reservation.checkout
                      ? moment(reservation.checkout).format('HH:mm:ss')
                      : 'Em andamento'}
                  </td>
                  <td>
                    {reservation.checkout
                      ? calculateDuration(
                          moment(reservation.checkin),
                          moment(reservation.checkout)
                        )
                      : calculateDuration(
                          moment(reservation.checkin),
                          moment()
                        )}
                  </td>
                  <td>
                    {reservation.invoiceTotal
                      ? stringToCurrency(reservation.invoiceTotal)
                      : '-'}
                  </td>
                  <td>
                    <div>
                      <CameraIcon
                        style={{
                          height: 18,
                          opacity: iconOpacity(!!reservation.automaticCapture),
                        }}
                      />
                      <Car
                        style={{
                          height: 20,
                          opacity: iconOpacity(
                            reservation.transportation === 'Carro'
                          ),
                        }}
                      />
                      <Motorcycle
                        style={{
                          height: 20,
                          opacity: iconOpacity(
                            reservation.transportation === 'Moto'
                          ),
                        }}
                      />
                      <Pedestrian
                        style={{
                          height: 20,
                          opacity: iconOpacity(
                            reservation.transportation === 'Pedestre'
                          ),
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card>
    </>
  )
}
