import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { stringToCurrency } from 'util/currency-util'
import { Button, Container, Input, Row, Col, Card, Table } from 'reactstrap'
import useGetApi from 'hooks/useGetApi'
import Loader from 'components/Loader'

export default function Products() {
  const [products, isLoading] = useGetApi('/products')

  if (isLoading) return <Loader />

  return (
    <Container fluid className='p-0'>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='h3 mb-3'>Produtos</h1>

        <div>
          <Link to='/admin/products/new'>
            <Button outline color='primary' className='mt-n1 ml-2'>
              Novo Produto
            </Button>
          </Link>

          <Link to='/admin/products/productFlow'>
            <Button
              outline
              color='primary'
              className='mt-n1 ml-2'
              disabled={true}
            >
              Movimentação
            </Button>
          </Link>
        </div>
      </div>

      <Row>
        <Col>
          <ProductTable products={products} />
        </Col>
      </Row>
    </Container>
  )
}

function ProductTable({ products }) {
  const history = useHistory()

  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    setFilteredProducts(products || [])
  }, [products])

  const filterProducts = e => {
    const searchString = e.target.value
    if (searchString && products) {
      setFilteredProducts(
        products.filter(product =>
          product.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(searchString.toLowerCase())
        )
      )
    } else {
      setFilteredProducts(products || [])
    }
  }

  return (
    <>
      <Col xs='12' md='6' lg='5' xl='4'>
        <Input
          type='text'
          placeholder='Pesquisar...'
          aria-label='Search'
          style={{ marginBottom: 20 }}
          onChange={filterProducts}
        />
      </Col>

      <Card>
        <Table striped hover>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Categoria</th>
              <th>Preço</th>
              <th>Estoque</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map(product => (
              <tr
                key={product.productId}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push(`/admin/products/${product.productId}`)
                }
              >
                <td>{product.title}</td>
                <td>{product.category}</td>
                <td>{stringToCurrency(product.price)}</td>
                <td style={{ color: product.stock <= 0 ? 'red' : '' }}>
                  {product.stock}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  )
}
