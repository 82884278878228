import moment from 'moment'
import store from 'redux/store'
import { stringToCurrency } from 'util/currency-util'

const tablePadding = '8px'

export function saleInvoiceData(products, payments) {
  const date = moment().format('DD/MM/YYYY HH:mm:ss')

  const productsItems = products.map(item => invoiceItem(item))
  const productsTotal = products.reduce(
    (acc, product) => acc + product.subtotal,
    0
  )

  const paymentItems = payments.map(payment => paymentItem(payment))
  const paymentsTotal = payments.reduce(
    (acc, item) => acc + parseFloat(item.amount),
    0
  )

  const data = [
    title(),
    subtitle('Via Hóspede'),
    subtitle(date, '8px'),
    {
      type: 'table',
      style: 'margin-top: 8px; margin-bottom: 40px; line-height: 0px;',
      tableHeader: invoiceHeader(),
      tableBody: productsItems,
      tableFooter: invoiceFooter(productsTotal),
      tableHeaderStyle: 'border-bottom: 1px solid #000;',
      tableFooterStyle: 'border-top: 1px solid #000;',
    },

    // Company
    title(),
    subtitle('Via Estabelecimento'),
    subtitle(date, '8px'),
    {
      type: 'table',
      style: 'margin-top: 8px; line-height: 0px;',
      tableHeader: invoiceHeader(),
      tableBody: productsItems,
      tableFooter: invoiceFooter(productsTotal),
      tableHeaderStyle: 'border-bottom: 1px solid #000;',
      tableFooterStyle: 'border-top: 1px solid #000;',
    },
    {
      type: 'table',
      style: 'margin-top: 8px; margin-bottom: 8px; line-height: 0px;',
      tableBodyStyle: 'border: none;',
      tableBody: paymentItems,
      tableFooter: paymentFooter(paymentsTotal),
      tableFooterStyle: 'border-top: 1px solid #000;',
    },
  ]

  return data
}

function title() {
  const companyName = store.getState().auth.currentCompany.name
  return {
    type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
    value: companyName,
    style: 'text-align: center;',
    css: { 'font-weight': '800', 'font-size': '16px' },
  }
}

function subtitle(value, marginBottom = '0px') {
  return {
    type: 'text',
    value: value,
    style: 'text-align: center;',
    css: { 'font-size': '12px', 'margin-bottom': marginBottom },
  }
}

function invoiceHeader() {
  return [
    {
      type: 'text',
      value: 'Item',
      style: 'text-align: left;',
      css: { 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: 'Qtd',
    },
    {
      type: 'text',
      value: 'Subtotal',
      style: 'text-align: right;',
    },
  ]
}

function invoiceItem({ title, quantity, subtotal }) {
  return [
    {
      type: 'text',
      value: title,
      style: 'text-align: left;',
      css: { 'font-weight': '600', 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: quantity,
      css: { 'font-weight': '600' },
    },
    {
      type: 'text',
      value: stringToCurrency(subtotal),
      style: 'text-align: right;',
      css: { 'font-weight': '600' },
    },
  ]
}

function invoiceFooter(total) {
  return [
    {
      type: 'text',
      value: 'TOTAL A PAGAR',
      style: 'text-align: left;',
      css: { 'font-weight': '700', 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: '',
    },
    {
      type: 'text',
      value: stringToCurrency(total),
      style: 'text-align: right;',
      css: { 'font-weight': '700' },
    },
  ]
}

function paymentItem(payment) {
  return [
    {
      type: 'text',
      value: payment.method,
      style: 'text-align: left;',
      css: { 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: '',
    },
    {
      type: 'text',
      value: stringToCurrency(payment.amount),
      style: 'text-align: right;',
    },
  ]
}

function paymentFooter(total) {
  return [
    {
      type: 'text',
      value: 'TOTAL PAGO',
      style: 'text-align: left;',
      css: { 'font-weight': '700', 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: '',
    },
    {
      type: 'text',
      value: stringToCurrency(total),
      style: 'text-align: right;',
      css: { 'font-weight': '700' },
    },
  ]
}
