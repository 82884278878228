import React, { useContext } from 'react'
import moment from 'moment'
import { Context } from './ShiftContext'
import { useHistory } from 'react-router-dom'
import { stringToCurrency } from 'util/currency-util'

import { Badge, Card, CardHeader, CardTitle, Table } from 'reactstrap'

export const FinancialTransactions = () => {
  return (
    <>
      <Revenue />
      <Expenses />
    </>
  )
}

function Revenue() {
  const ctx = useContext(Context)
  const history = useHistory()

  const revenueTransactions = ctx.shift.financialTransactions
    .filter(t => t.type === 'Receita')
    .sort((a, b) => new Date(a.date) - new Date(b.date))

  return (
    <Card className='flex-fill w-100'>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Receita
        </CardTitle>
      </CardHeader>
      <Table striped hover className='my-0'>
        <thead>
          <tr>
            <th>#</th>
            <th style={{ width: '20%' }}>Data</th>
            <th>Tipo</th>
            <th>Valor</th>
            <th className='d-none d-md-table-cell'>Pagamento</th>
            <th className='d-none d-md-table-cell'></th>
          </tr>
        </thead>
        <tbody>
          {revenueTransactions.map((transaction, idx) => (
            <tr
              key={transaction.transactionId}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const returnPath = '?returns=/shifts/' + ctx.shift.shiftId
                if (transaction.invoice.reservation?.reservationId) {
                  history.push(
                    '/reservations/' +
                      transaction.invoice.reservation.reservationId +
                      returnPath
                  )
                } else {
                  history.push(
                    '/counter-sale/' +
                      transaction.invoice.invoiceId +
                      returnPath
                  )
                }
              }}
            >
              <td>{idx + 1}</td>
              <td>{moment(transaction.date).format('DD/MM/YY HH:mm')}</td>
              <td>
                {transaction.site} {transaction.metadata?.room}
              </td>
              <td>{stringToCurrency(transaction.value)}</td>
              <td className='d-none d-md-table-cell'>
                {[
                  ...new Set(
                    transaction.invoice?.payments.map(payment => payment.method)
                  ),
                ]
                  .sort()
                  .join(', ')}
              </td>
              <td className='d-none d-md-table-cell text-right'>
                <Badge
                  color={
                    transaction.type === 'Receita'
                      ? 'success'
                      : transaction.type === 'Despesa'
                      ? 'danger'
                      : 'warning'
                  }
                >
                  {transaction.type}
                </Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}

function Expenses() {
  const ctx = useContext(Context)
  const expenseTransactions = ctx.shift.financialTransactions
    .filter(t => t.type !== 'Receita')
    .sort((a, b) => new Date(a.date) - new Date(b.date))

  return (
    <Card className='flex-fill w-100'>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Despesas
        </CardTitle>
      </CardHeader>
      <Table striped className='my-0'>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Data</th>
            <th>Categoria</th>
            <th>Valor</th>
            <th className='d-none d-md-table-cell'>Descrição</th>
            <th className='d-none d-md-table-cell'></th>
          </tr>
        </thead>
        <tbody>
          {expenseTransactions.map(transaction => (
            <tr key={transaction.transactionId}>
              <td>{moment(transaction.date).format('DD/MM/YY HH:mm')}</td>
              <td>
                {transaction.expense
                  ? transaction.expense.category
                  : transaction.type}
              </td>
              <td>{stringToCurrency(transaction.value)}</td>
              <td className='d-none d-md-table-cell'>
                {transaction.metadata.description}
              </td>
              <td className='d-none d-md-table-cell text-right'>
                <Badge
                  color={
                    transaction.type === 'Receita'
                      ? 'success'
                      : transaction.type === 'Despesa'
                      ? 'danger'
                      : 'warning'
                  }
                >
                  {transaction.type}
                </Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
