import React, { useContext } from 'react'
import { Context, ReservationProvider } from './ReservationContext'
import useSessionCheck from 'hooks/sessionCheck'

import {
  Badge,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import { Header } from './Header'
import { ReservationInfo } from './ReservationInfo'
import { Invoice } from './invoice/Invoice'
import { Payment } from './payment/Payment'
import { Guest } from './guest/Guest'
import { ReservationTimeline } from './timeline/Timeline'

import { ChangeRoom } from './ChangeRoom'
import { ProductSale } from '../sale/ProductSale'
import { Discount } from './discount/Discount'

export default function Reservation({ match }) {
  useSessionCheck()
  return (
    <ReservationProvider reservationId={match.params.reservationId}>
      <Container fluid className='p-0'>
        <Header />
        <ReservationInfo />

        <Row>
          <Col md='3' xl='2'>
            <Navigation />
          </Col>
          <Col md='9' xl='10'>
            <ActivePage />
          </Col>
        </Row>
      </Container>
    </ReservationProvider>
  )
}

const ActivePage = () => {
  const { activePage, reservationInfo, onSaveProduct } = useContext(Context)

  return (
    <>
      {activePage === 'Invoice' && <Invoice />}
      {activePage === 'Payments' && <Payment />}
      {activePage === 'Guest' && <Guest />}
      {activePage === 'Timeline' && <ReservationTimeline />}

      {activePage === 'ChangeRoom' && <ChangeRoom />}
      {activePage === 'ProductSale' && (
        <ProductSale
          reservationId={reservationInfo.reservation.reservationId}
          onSave={onSaveProduct}
        />
      )}
      {activePage === 'Discount' && <Discount />}
    </>
  )
}

const Navigation = () => {
  const { activePage, setActivePage, reservationInfo } = useContext(Context)

  return (
    <div>
      <div className='d-block d-md-none'>
        <Nav tabs>
          <NavItem>
            <NavLink
              active={activePage === 'Invoice'}
              onClick={() => setActivePage('Invoice')}
            >
              Fatura
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activePage === 'Payments'}
              onClick={() => setActivePage('Payments')}
            >
              Pagamentos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activePage === 'Guest'}
              onClick={() => setActivePage('Guest')}
            >
              Hóspede
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activePage === 'Timeline'}
              onClick={() => setActivePage('Timeline')}
            >
              Linha do Tempo
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <Card className='d-none d-md-block'>
        <CardHeader>
          <CardTitle tag='h5' className='mb-0'>
            Menu
          </CardTitle>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem
            tag='a'
            action
            active={activePage === 'Invoice'}
            onClick={() => setActivePage('Invoice')}
          >
            Fatura
          </ListGroupItem>
          <ListGroupItem
            tag='a'
            action
            active={activePage === 'Payments'}
            onClick={() => setActivePage('Payments')}
          >
            Pagamentos
          </ListGroupItem>
          <ListGroupItem
            tag='a'
            action
            active={activePage === 'Guest'}
            onClick={() => setActivePage('Guest')}
          >
            Hóspede
            {reservationInfo.reservation.vehicle?.metadata?.comments &&
              reservationInfo.reservation.vehicle?.metadata?.comments.length >
                0 && (
                <Badge
                  color='danger'
                  className='float-right'
                  style={{ marginTop: 2 }}
                >
                  {
                    reservationInfo.reservation.vehicle?.metadata.comments
                      .length
                  }
                </Badge>
              )}
          </ListGroupItem>
          <ListGroupItem
            tag='a'
            action
            active={activePage === 'Timeline'}
            onClick={() => setActivePage('Timeline')}
          >
            Linha do Tempo
          </ListGroupItem>
        </ListGroup>
      </Card>
    </div>
  )
}
