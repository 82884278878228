import React, { createContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import useGetApi from 'hooks/useGetApi'
import api from 'api'
import { isElectron } from 'util/electron-util'
import { endShiftReport } from 'services/printer/shift-report'

const Context = createContext()

function ShiftContext({ children, shiftId }) {
  const history = useHistory()
  const [shift, isLoading, shiftError] = useGetApi(`/shifts/${shiftId}`)

  const printer = useSelector(state => state.printer)

  const [isClosingShift, setIsClosingShift] = useState(false)

  const closeShift = shiftId => {
    setIsClosingShift(true)
    api
      .post(`shifts/${shiftId}/close`)
      .then(({ data }) => {
        setIsClosingShift(false)
        printShift()
        if (data.shiftId) history.push(`/shifts/${data.shiftId}`)
      })
      .catch(e => {
        setIsClosingShift(false)
        toastr.error(e.response.data)
      })
  }

  function printShift() {
    if (isElectron) {
      const { ipcRenderer } = window.require('electron')
      const data = endShiftReport(shift)
      ipcRenderer.send('print', JSON.stringify({ printer, content: data }))
    }
  }

  return (
    <Context.Provider
      value={{
        shift,
        isLoading,
        shiftError,
        isClosingShift,
        closeShift,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, ShiftContext }
