import moment from 'moment'
import store from 'redux/store'
import { calculateDuration } from 'util/reservation-duration'
import { stringToCurrency } from 'util/currency-util'

const tablePadding = '8px'

export function guestCheckoutInvoiceData(
  reservationInfo,
  hospitalityItems,
  productItems,
  discountItems,
  invoiceTotal
) {
  const header = [
    headerLine(`Unidade: ${reservationInfo.room.title}`),
    headerLine(
      `Entrada: ${moment(reservationInfo.reservation.checkin).format(
        'DD/MM/YYYY HH:mm:ss'
      )}`
    ),
    headerLine(`Saída: ${moment().format('DD/MM/YYYY HH:mm:ss')}`),
    headerLine(
      `Permanência: ${calculateDuration(
        moment(reservationInfo.reservation.checkin),
        moment()
      )}`
    ),
  ]

  const invoiceItems = [
    ...hospitalityItems,
    ...productItems,
    ...discountItems,
  ].map(item => invoiceItem(item))

  const data = [
    title(),
    subtitle('Via Hóspede'),
    ...header,
    {
      type: 'table',
      style: 'margin-top: 8px; line-height: 0px;',
      tableHeader: invoiceHeader(),
      tableBody: invoiceItems,
      tableFooter: invoiceFooter(invoiceTotal),
      tableHeaderStyle: 'border-bottom: 1px solid #000;',
      tableFooterStyle: 'border-top: 1px solid #000;',
    },
  ]

  return data
}

export function companyCheckoutInvoiceData(
  reservationInfo,
  hospitalityItems,
  productItems,
  discountItems,
  invoiceTotal
) {
  const header = [
    headerLine(`Unidade: ${reservationInfo.room.title}`),
    headerLine(
      `Entrada: ${moment(reservationInfo.reservation.checkin).format(
        'DD/MM/YYYY HH:mm:ss'
      )}`
    ),
    headerLine(
      `Saída: ${moment(reservationInfo.reservation.checkout).format(
        'DD/MM/YYYY HH:mm:ss'
      )}`
    ),
    headerLine(`Liberação Quarto: ${moment().format('DD/MM/YYYY HH:mm:ss')}`),
    headerLine(
      `Permanência: ${calculateDuration(
        moment(reservationInfo.reservation.checkin),
        moment(reservationInfo.reservation.checkout)
      )}`
    ),
  ]

  const invoiceItems = [
    ...hospitalityItems,
    ...productItems,
    ...discountItems,
  ].map(item => invoiceItem(item))

  const payments = reservationInfo.reservation.invoice.payments.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  )
  const paymentsTotal = payments.reduce(
    (acc, item) => acc + parseFloat(item.amount),
    0
  )
  const paymentItems = payments.map(payment => paymentItem(payment))

  const data = [
    title(),
    subtitle('Via Estabelecimento'),
    ...header,
    {
      type: 'table',
      style: 'margin-top: 8px; line-height: 0px;',
      tableHeader: invoiceHeader(),
      tableBody: invoiceItems,
      tableFooter: invoiceFooter(invoiceTotal),
      tableHeaderStyle: 'border-bottom: 1px solid #000;',
      tableFooterStyle: 'border-top: 1px solid #000;',
    },
    {
      type: 'table',
      style: 'margin-top: 8px; margin-bottom: 8px; line-height: 0px;',
      tableBodyStyle: 'border: none;',
      tableBody: paymentItems,
      tableFooter: paymentFooter(paymentsTotal),
      tableFooterStyle: 'border-top: 1px solid #000;',
    },
    {
      type: 'qrCode',
      value: reservationInfo.reservation.reservationId,
      height: 60,
      width: 60,
      position: 'center',
    },
  ]

  return data
}

function title() {
  const companyName = store.getState().auth.currentCompany.name
  return {
    type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
    value: companyName,
    style: 'text-align: center;',
    css: { 'font-weight': '800', 'font-size': '16px' },
  }
}

function subtitle(value) {
  return {
    type: 'text',
    value,
    style: 'text-align: center;',
    css: { 'font-size': '12px', 'margin-bottom': '8px' },
  }
}

function headerLine(value) {
  return {
    type: 'text',
    value: value,
    style: 'text-align: left;',
    css: { 'font-size': '14px' },
  }
}

function invoiceHeader() {
  return [
    {
      type: 'text',
      value: 'Item',
      style: 'text-align: left;',
      css: { 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: 'Qtd',
    },
    {
      type: 'text',
      value: 'Subtotal',
      style: 'text-align: right;',
    },
  ]
}

function invoiceItem({ item, quantity, price }) {
  return [
    {
      type: 'text',
      value: item,
      style: 'text-align: left;',
      css: { 'font-weight': '600', 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: quantity,
      css: { 'font-weight': '600' },
    },
    {
      type: 'text',
      value: stringToCurrency(price),
      style: 'text-align: right;',
      css: { 'font-weight': '600' },
    },
  ]
}

function invoiceFooter(total) {
  return [
    {
      type: 'text',
      value: 'TOTAL A PAGAR',
      style: 'text-align: left;',
      css: { 'font-weight': '700', 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: '',
    },
    {
      type: 'text',
      value: stringToCurrency(total),
      style: 'text-align: right;',
      css: { 'font-weight': '700' },
    },
  ]
}

function paymentItem(payment) {
  return [
    {
      type: 'text',
      value: payment.method,
      style: 'text-align: left;',
      css: { 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: '',
    },
    {
      type: 'text',
      value: stringToCurrency(payment.amount),
      style: 'text-align: right;',
    },
  ]
}

function paymentFooter(total) {
  return [
    {
      type: 'text',
      value: 'TOTAL PAGO',
      style: 'text-align: left;',
      css: { 'font-weight': '700', 'padding-left': tablePadding },
    },
    {
      type: 'text',
      value: '',
    },
    {
      type: 'text',
      value: stringToCurrency(total),
      style: 'text-align: right;',
      css: { 'font-weight': '700' },
    },
  ]
}
