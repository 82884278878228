import React, { useContext } from 'react'
import { connect, useSelector } from 'react-redux'
import { toggleSidebar } from '../redux/actions/sidebarActions'
import { signOut } from '../redux/actions/authActions'
import { useHistory } from 'react-router-dom'
import api from 'api'

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import { PlusCircle, Settings, User, LogOut, Shuffle } from 'react-feather'
import { SocketContext } from '../api/SocketProvider'

const NavbarComponent = ({ dispatch }) => {
  const history = useHistory()
  const user = useSelector(state => state.auth.user)

  const { addManualCheckin } = useContext(SocketContext)

  return (
    <Navbar color='white' light expand>
      <span
        className='sidebar-toggle d-flex mr-2'
        onClick={() => {
          dispatch(toggleSidebar())
        }}
      >
        <i className='hamburger align-self-center' />
      </span>

      <Collapse navbar>
        <Nav className='ml-auto' navbar>
          {/* Checkin Button */}
          <PlusCircle
            className='mt-2 mr-4'
            style={{ cursor: 'pointer' }}
            onClick={() => addManualCheckin()}
          />
          {/* End of Checkin Button */}

          <UncontrolledDropdown nav inNavbar>
            <span className='d-inline-block d-sm-none'>
              <DropdownToggle nav caret>
                <Settings size={18} className='align-middle' />
              </DropdownToggle>
            </span>
            <span className='d-none d-sm-inline-block'>
              <DropdownToggle nav caret>
                <img
                  src={user ? user.imageURL + `?t=${Date.now()}` : null}
                  className='avatar img-fluid rounded-circle mr-1'
                  alt=''
                />
                <span className='text-dark ml-2'>
                  <strong>{user?.firstname}</strong>
                </span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              {user?.companies?.length > 1 && (
                <>
                  <DropdownItem
                    onClick={() => history.push('/company-selection')}
                  >
                    <Shuffle size={18} className='align-middle mr-2' />
                    Alterar Empresa
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              )}

              <DropdownItem
                onClick={() => {
                  api.put('/auth/signout').then(() => {
                    dispatch(signOut())
                    history.push('/auth/sign-in')
                  })
                }}
              >
                <LogOut size={18} className='align-middle mr-2' />
                Sair
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default connect(store => ({
  app: store.app,
}))(NavbarComponent)
