import React, { createContext, useContext, useState } from 'react'
import api from 'api'
import useRooms from 'hooks/useRooms'
import { SocketContext } from 'api/SocketProvider'
import { toastr } from 'react-redux-toastr'

export const CheckinContext = createContext()

export function CheckinProvider({ children }) {
  const { vehicleQueue, setVehicleQueue, vehicleQueueRef } = useContext(
    SocketContext
  )
  const [rooms] = useRooms()

  const [activeIndex, setActiveIndex] = useState(0)

  const isAutomaticCheckin = !!vehicleQueue[activeIndex]?.captureId

  const initialValues = index => {
    return {
      roomId: '',
      transportation: 'Carro',
      plate: vehicleQueue[index]?.plate || '',
      noPlate: false,
      isOvernight: false,
      vehicleCaptureId: vehicleQueue[index]?.captureId,
    }
  }

  const submit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    api
      .post('/reservations', values, {
        params: { roomId: values.roomId },
      })
      .then(() => {
        setSubmitting(false)
        resetForm()
        setVehicleQueue([])
        toastr.success('Checkin efetuado')
      })
      .catch(e => {
        setSubmitting(false)
        toastr.error(e.response.data)
      })
  }

  const searchVisits = async plate => {
    if (/[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/.test(plate)) {
      return api
        .get(`/vehicles/${plate}/visits`)
        .then(({ data }) => data.total)
        .catch(() => null)
    } else {
      return null
    }
  }

  const setModeForActiveVehicle = mode => {
    vehicleQueueRef.current = vehicleQueueRef.current.map(item => {
      if (item.captureId === vehicleQueue[activeIndex]?.captureId) {
        return {
          ...item,
          mode,
        }
      } else {
        return item
      }
    })
    setVehicleQueue(vehicleQueueRef.current)
  }

  const cancelCheckin = (reason, description) => {
    const captureId = vehicleQueue[activeIndex].captureId
    const body = {
      reason,
      description,
    }
    api.post(`/alpr/captures/${captureId}/cancel`, body)
  }

  return (
    <CheckinContext.Provider
      value={{
        rooms,
        vehicleQueue,
        setVehicleQueue,
        isAutomaticCheckin,
        activeIndex,
        setActiveIndex,
        initialValues,
        searchVisits,
        submit,
        setModeForActiveVehicle,
        cancelCheckin,
      }}
    >
      {children}
    </CheckinContext.Provider>
  )
}
