import React, { useContext, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Context } from '../ReservationContext'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  Input,
  FormGroup,
} from 'reactstrap'

export function Discount() {
  const ctx = useContext(Context)

  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Adicionar Desconto
        </CardTitle>
      </CardHeader>

      <CardBody>
        <FormGroup>
          <Label for='amountinput'>Valor</Label>
          <br />
          <CurrencyInput
            className='form-control'
            name='amountinput'
            placeholder='R$'
            allowNegativeValue={false}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            maxLength={10}
            value={amount}
            onValueChange={value => setAmount(value)}
          />
        </FormGroup>

        <FormGroup>
          <Label for='description'>Descrição</Label>
          <Input
            id='description'
            type='textarea'
            name='description'
            onChange={e => setDescription(e.target.value)}
            style={{ resize: 'none' }}
          />
        </FormGroup>

        <Button
          color='primary'
          disabled={
            !description ||
            !amount ||
            description.length === 0 ||
            isNaN(parseFloat(amount)) ||
            amount <= 0 ||
            ctx.isSavingDiscount
          }
          onClick={() =>
            ctx
              .addDiscount(description, amount)
              .then(success => success && ctx.setActivePage('Invoice'))
          }
        >
          Adicionar
        </Button>
      </CardBody>
    </Card>
  )
}
