import moment from 'moment'
import store from 'redux/store'
import { stringToCurrency } from 'util/currency-util'

const tablePadding = '8px'

export function endShiftReport(shift) {
  const operator = shift.user.firstname
  const start = moment(shift.startDate).format('DD/MM/YYYY HH:mm:ss')
  const end = moment().format('DD/MM/YYYY HH:mm:ss')
  const guestNumber =
    shift.financialTransactions.filter(t => t.site === 'Hospedagem').length || 0

  const revenueTransactions = shift.financialTransactions
    .filter(t => t.type === 'Receita')
    .sort((a, b) => new Date(a.date) - new Date(b.date))
  const revenueTotal = revenueTransactions.reduce(
    (acc, item) => acc + parseFloat(item.value),
    0
  )

  const expensesTransactions = shift.financialTransactions
    .filter(t => t.type === 'Despesa')
    .sort((a, b) => new Date(a.date) - new Date(b.date))
  const expensesTotal = expensesTransactions.reduce(
    (acc, item) => acc + parseFloat(item.value),
    0
  )

  const withdrawals = shift.financialTransactions
    .filter(t => t.type === 'Sangria')
    .sort((a, b) => new Date(a.date) - new Date(b.date))
  const withdrawalsTotal = withdrawals.reduce(
    (acc, item) => acc + parseFloat(item.value),
    0
  )

  const payments = revenueTransactions
    .map(t => t.invoice.payments)
    .flat()
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.method]:
          parseFloat(acc[item.method] || 0) + parseFloat(item.amount),
      }),
      {}
    )

  const balance =
    revenueTotal -
    expensesTotal -
    withdrawalsTotal -
    Object.keys(payments)
      .filter(method => method !== 'Dinheiro')
      .reduce((acc, method) => acc + payments[method], 0)

  const data = [
    title(),
    subtitle('Fechamento de Turno'),
    infoLine(`Operador: ${operator}`),
    infoLine(`Começo: ${start}`),
    infoLine(`Final: ${end}`),
    infoLine(`Hospedagens: ${guestNumber}`),
    table(
      tableHeader(
        { value: 'Fluxo de Caixa', alignment: 'left' },
        { value: 'Total', alignment: 'right' }
      ),
      tableBody(
        [
          { value: '+ Faturamento' },
          { value: stringToCurrency(revenueTotal), alignment: 'right' },
        ],
        [
          { value: '- Despesas' },
          { value: stringToCurrency(expensesTotal), alignment: 'right' },
        ],
        [
          { value: '- Sangrias' },
          { value: stringToCurrency(withdrawalsTotal), alignment: 'right' },
        ],
        ...Object.keys(payments)
          .sort()
          .map(payment => [
            { value: payment === 'Dinheiro' ? `(${payment})` : `- ${payment}` },
            { value: stringToCurrency(payments[payment]), alignment: 'right' },
          ])
      ),
      tableFooter(
        { value: 'Caixa Final', alignment: 'left' },
        { value: stringToCurrency(balance), alignment: 'right' }
      )
    ),
    table(
      tableHeader(
        { value: 'Receita', alignment: 'left' },
        { value: 'Pagamento', alignment: 'right' },
        { value: 'Valor', alignment: 'right' }
      ),
      tableBody(
        ...revenueTransactions.map(t => {
          const payments = [
            ...new Set(t.invoice.payments.map(payment => payment.method)),
          ].sort()
          return [
            { value: t.metadata?.room || t.site, alignment: 'left' },
            {
              value:
                payments.length > 1
                  ? `${payments[0]} +${payments.length - 1}`
                  : payments[0],
              alignment: 'right',
            },
            { value: stringToCurrency(t.value), alignment: 'right' },
          ]
        }),
        []
      )
    ),
    table(
      tableHeader(
        { value: 'Despesa', alignment: 'left' },
        { value: 'Valor', alignment: 'right' }
      ),
      tableBody(
        ...[...expensesTransactions, ...withdrawals].map(t => [
          { value: t.expense ? t.expense.category : t.type, alignment: 'left' },
          { value: stringToCurrency(t.value), alignment: 'right' },
        ])
      ),
      []
    ),
    table(
      tableHeader(
        { value: 'Venda Produto', alignment: 'left' },
        { value: 'Qnt', alignment: 'right' }
      ),
      tableBody(
        ...shift.productsSold.map(product => [
          { value: product.title, alignment: 'left' },
          { value: product.quantity, alignment: 'right' },
        ])
      ),
      []
    ),
  ]

  return data
}

function title() {
  const companyName = store.getState().auth.currentCompany.name
  return {
    type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
    value: companyName,
    style: 'text-align: center;',
    css: { 'font-weight': '800', 'font-size': '16px' },
  }
}

function subtitle(value) {
  return {
    type: 'text',
    value,
    style: 'text-align: center;',
    css: { 'font-size': '12px', 'margin-bottom': '8px' },
  }
}

function infoLine(value) {
  return {
    type: 'text',
    value: value,
    style: 'text-align: left;',
    css: { 'font-size': '14px' },
  }
}

function table(header, body, footer) {
  return {
    type: 'table',
    style: 'margin-top: 8px; line-height: 0px;',
    tableHeader: header,
    tableBody: body,
    tableFooter: footer,
    tableHeaderStyle: 'border-bottom: 1px solid #000;',
    tableFooterStyle: 'border-top: 1px solid #000;',
  }
}

function tableHeader(...items) {
  return items.map((item, idx) => ({
    type: 'text',
    value: item.value,
    style: `text-align: ${item.alignment || 'left'};`,
    css: { 'padding-left': idx === 0 ? tablePadding : '0px' },
  }))
}

function tableBody(...row) {
  return row.map(column => {
    return column.map((item, idx) => ({
      type: 'text',
      value: item.value,
      style: `text-align: ${item.alignment || 'left'};`,
      css: {
        'font-weight': '600',
        'padding-left': idx === 0 ? tablePadding : '0px',
      },
    }))
  })
}

function tableFooter(...items) {
  return items.map((item, idx) => ({
    type: 'text',
    value: item.value,
    style: `text-align: ${item.alignment || 'left'};`,
    css: {
      'font-weight': '600',
      'padding-left': idx === 0 ? tablePadding : '0px',
    },
  }))
}
