import React, { useContext } from 'react'
import { Context, ShiftContext } from './ShiftContext'

import { Col, Row, Container } from 'reactstrap'
import Loader from 'components/Loader'

import { Header } from './Header'
import { TopInfo } from './TopInfo'
import { Details } from './Details'
import { Payments } from './Payments'
import { Products } from './Products'
import { FinancialTransactions } from './FinancialTransactions'

export default function CurrentShift({ match }) {
  const shiftId = match.params.shiftId || 'current'
  return (
    <ShiftContext shiftId={shiftId}>
      <ShiftLayout />
    </ShiftContext>
  )
}

function ShiftLayout() {
  const ctx = useContext(Context)

  if (ctx.shiftError) return <div>Erro</div>
  if (ctx.isLoading || !ctx.shift) return <Loader />

  return (
    <Container fluid className='p-0'>
      <Header />
      <TopInfo />

      <Row>
        <Col md='3'>
          <Details />
          <Payments />
          <Products />
        </Col>
        <Col md='9'>
          <FinancialTransactions />
        </Col>
      </Row>
    </Container>
  )
}
