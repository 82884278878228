import React, { useEffect, useState } from 'react'
import moment from 'moment'
import api from 'api'
import { toastr } from 'react-redux-toastr'
import { Card, CardHeader, CardTitle, Table, Row, Col } from 'reactstrap'
import HeaderDatePicker from 'components/HeaderDatePicker'
import Loader from 'components/Loader'

export default function Surveillance({ history, location }) {
  const [date, setDate] = useState(
    location.search
      ? moment(new URLSearchParams(location.search).get('date'))
      : moment()
  )
  const [vehicles, setVehicles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getSurveillance = () => {
    setIsLoading(true)
    api
      .get(`/alpr/captures?date=${date.format('YYYY-MM-DD')}`)
      .then(({ data }) => {
        setVehicles(data)
        setIsLoading(false)
      })
      .catch(err => {
        toastr.error(err.response?.data)
        setIsLoading(false)
      })
  }

  useEffect(getSurveillance, [date])

  return (
    <>
      <Row>
        <Col xs='12' md='6'>
          <h1 className='h3 mb-3'>Veículos</h1>
        </Col>

        <Col xs='12' md='6'>
          <div className='d-flex float-right mb-3'>
            <HeaderDatePicker date={date} setDate={setDate} />
          </div>
        </Col>
      </Row>

      <Card className='flex-fill w-100'>
        <CardHeader>
          <CardTitle tag='h5' className='mb-0'>
            <div>
              {vehicles.length} veículos identificados em{' '}
              {date.format('DD/MM/YYYY')}
            </div>
          </CardTitle>
        </CardHeader>

        {isLoading ? (
          <Loader />
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th width='20'>#</th>
                <th width='200'>Imagem</th>
                <th>Placa</th>
                <th>Horário</th>
                <th>Observação</th>
              </tr>
            </thead>
            <tbody>
              {vehicles?.map((vehicle, idx) => {
                let statusDescription = {}
                if (vehicle.status === 'Cancelado') {
                  statusDescription = vehicle.history.find(
                    history => history.status === 'Cancelado'
                  )
                }

                let color = ''
                if (vehicle.status === 'Checkin Efetuado') {
                  color = 'success'
                } else if (vehicle.status === 'Cancelado') {
                  color = 'warning'
                } else if (vehicle.status === 'Capturado') {
                  color = 'danger'
                }

                return (
                  <tr
                    key={vehicle.captureId}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (vehicle.reservation?.reservationId)
                        history.push(
                          '/reservations/' +
                            vehicle.reservation.reservationId +
                            '?returns=/alpr?date=' +
                            date.format('YYYY-MM-DD')
                        )
                    }}
                  >
                    <td>
                      <strong>{idx + 1}</strong>
                    </td>
                    <td>
                      <div>
                        {vehicle.imgURIs &&
                          vehicle.imgURIs.plateURIs &&
                          vehicle.imgURIs.plateURIs.length > 0 && (
                            <img
                              src={vehicle.imgURIs.plateURIs[0].imgURI}
                              alt=''
                              style={{ borderRadius: 4 }}
                            />
                          )}
                      </div>
                      <div
                        className={`badge badge-${color}`}
                        style={{ width: 150 }}
                      >
                        {vehicle.status}
                      </div>
                    </td>
                    <td>{vehicle.plate}</td>
                    <td>{moment(vehicle.date).format('HH:mm:ss')}</td>
                    <td>
                      {statusDescription?.reason}
                      <br />
                      {statusDescription?.description}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      </Card>
    </>
  )
}
