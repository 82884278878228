import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadRooms } from 'redux/actions/roomActions'

export default () => {
  const dispatch = useDispatch()

  const { currentCompanyId, token } = useSelector(state => state.auth)
  const { rooms, isLoading } = useSelector(state => state.rooms)

  useEffect(() => {
    if (token && rooms.length === 0) dispatch(loadRooms(currentCompanyId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId, token])

  return [rooms, isLoading]
}
