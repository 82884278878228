import React, { useState, useEffect } from 'react'
import moment from 'moment'
import api from 'api'
import { toastr } from 'react-redux-toastr'
import { stringToCurrency } from 'util/currency-util'
import Loader from 'components/Loader'
import HeaderDatePicker from 'components/HeaderDatePicker'
import { Card, CardHeader, CardTitle, Table, Col, Row } from 'reactstrap'

export default function AllShifts({ history }) {
  const [shifts, setShifts] = useState([])
  const [date, setDate] = useState(moment())
  const [isLoading, setIsLoading] = useState(false)

  const getShifts = () => {
    setIsLoading(true)
    api
      .get(`/shifts?date=${date.format('YYYY-MM-DD')}`)
      .then(({ data }) => {
        setShifts(data)
        setIsLoading(false)
      })
      .catch(err => {
        toastr.error(err.response?.data)
        setIsLoading(false)
      })
  }

  useEffect(getShifts, [date])

  return (
    <>
      <Row>
        <Col xs='12' md='6'>
          <h1 className='h3 mb-3'>Turnos</h1>
        </Col>

        <Col xs='12' md='6'>
          <div className='d-flex float-right mb-3'>
            <HeaderDatePicker date={date} setDate={setDate} />
          </div>
        </Col>
      </Row>

      <Card className='flex-fill w-100'>
        <CardHeader>
          <CardTitle tag='h5' className='mb-0'>
            Turnos começados em {date.format('DD/MM/YYYY')}
          </CardTitle>
        </CardHeader>

        {isLoading ? (
          <Loader />
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Operador</th>
                <th>Início</th>
                <th>Termino</th>
                <th className='text-center'>Hospedagens</th>
                <th className='text-center'>Faturamento</th>
                <th className='text-center'>Despesas</th>
              </tr>
            </thead>
            <tbody>
              {shifts?.map(shift => (
                <tr
                  key={shift.shiftId}
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/shifts/${shift.shiftId}`)}
                >
                  <td>{shift.user.firstname}</td>
                  <td>{moment(shift.startDate).format('HH:mm')}</td>
                  <td>
                    {shift.endDate
                      ? moment(shift.endDate).format('HH:mm')
                      : '-'}
                  </td>
                  <td className='text-center'>{shift.guests}</td>
                  <td className='text-center'>
                    {stringToCurrency(shift.revenue)}
                  </td>
                  <td className='text-center'>
                    {stringToCurrency(shift.expenses)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card>
    </>
  )
}
