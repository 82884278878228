import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/min/locales.min'
import api from 'api'
import sinesp from 'sinesp-api'
import { Context } from '../ReservationContext'
import { toastr } from 'react-redux-toastr'
import { stringToCurrency } from 'util/currency-util'
import pluralize from 'util/pluralize'
import Loader from 'components/Loader'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Col,
  Container,
  Form,
  InputGroup,
  InputGroupAddon,
  Input,
  Media,
  Row,
  Table,
} from 'reactstrap'
import Pedestrian from '@material-ui/icons/DirectionsWalkTwoTone'

import Timeline from 'components/Timeline'
import TimelineItem from 'components/TimelineItem'

export const Guest = () => {
  const { reservationInfo } = useContext(Context)
  const [vehicleInfo, setVehicleInfo] = useState(null)

  useEffect(() => {
    getVehicleInfo()
  }, [])

  const getVehicleInfo = () => {
    if (reservationInfo.reservation.vehicleId) {
      api
        .get(`/vehicles/${reservationInfo.reservation.vehicleId}`)
        .then(({ data }) => {
          setVehicleInfo(data)
        })
        .catch(e => {
          toastr.error(e.response?.data || 'Erro')
        })
    }
  }

  if (reservationInfo.reservation.transportation === 'Pedestre') {
    return <PedestrianPlaceholder />
  } else if (!vehicleInfo) return <Loader />

  const isVehicleWithPlate =
    (reservationInfo.reservation.transportation === 'Carro' ||
      reservationInfo.reservation.transportation === 'Moto' ||
      !reservationInfo.reservation.transportation) &&
    !!reservationInfo.reservation.vehicleId

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xl='6'>
          <GuestInfo
            vehicleInfo={vehicleInfo}
            isVehicleWithPlate={isVehicleWithPlate}
          />
          {isVehicleWithPlate && (
            <GuestVisits
              vehicleInfo={vehicleInfo}
              isVehicleWithPlate={isVehicleWithPlate}
            />
          )}
        </Col>
        <Col xl='6'>
          <GuestImages />
          {isVehicleWithPlate && <GuestComments />}
        </Col>
      </Row>
    </Container>
  )
}

const GuestInfo = ({ vehicleInfo, isVehicleWithPlate }) => {
  const { reservationInfo } = useContext(Context)

  const [sinespResult, setSinespResult] = useState(null)
  const [sinespLoading, setSinespLoading] = useState(false)

  const checkSinesp = async () => {
    if (sinespResult) return
    setSinespLoading(true)
    await sinesp
      .search(reservationInfo.reservation.vehicle.plate)
      .then(setSinespResult)
      .catch(err => toastr.error('Erro', err.message))
    setSinespLoading(false)
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Dados
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Table size='sm' className='my-2'>
          <tbody>
            <tr>
              <th>Placa</th>
              <td>
                {isVehicleWithPlate ? vehicleInfo.vehicle.plate : 'Sem placa'}
              </td>
            </tr>
            {isVehicleWithPlate && (
              <>
                <tr>
                  <th>Visitas</th>
                  <td>{vehicleInfo.visits.total}</td>
                </tr>
                {sinespResult && (
                  <>
                    <tr>
                      <th>Marca/Modelo</th>
                      <td>{sinespResult.marca}</td>
                    </tr>
                    <tr>
                      <th>Cor</th>
                      <td>{sinespResult.cor}</td>
                    </tr>
                    <tr>
                      <th>Ano</th>
                      <td>
                        {sinespResult.ano}/{sinespResult.anoModelo}
                      </td>
                    </tr>
                    <tr>
                      <th>Localidade</th>
                      <td>
                        {sinespResult.municipio} - {sinespResult.uf}
                      </td>
                    </tr>
                    <tr>
                      <th>Situação</th>
                      <td
                        style={{
                          color:
                            sinespResult.codigoRetorno === '0'
                              ? 'green'
                              : 'red',
                        }}
                      >
                        {sinespResult.situacao}
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </Table>

        {!sinespResult && isVehicleWithPlate && (
          <div className='text-center mt-2'>
            <Button
              className='mx-auto text-center'
              color='primary'
              disabled={sinespLoading}
              onClick={checkSinesp}
            >
              Pesquisar Situação do Veículo
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

const GuestVisits = ({ vehicleInfo, isVehicleWithPlate }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Últimas Visitas
        </CardTitle>
      </CardHeader>
      <CardBody>
        {isVehicleWithPlate &&
          (vehicleInfo?.visits.history.length > 0 ? (
            <Timeline className='mt-2'>
              {vehicleInfo?.visits.history.map(item => (
                <TimelineItem key={item.reservationId}>
                  <strong>
                    {moment(item.checkin).locale('pt-BR').format('LLL')}
                  </strong>
                  <p>Fatura de {stringToCurrency(item.total)}</p>
                </TimelineItem>
              ))}
            </Timeline>
          ) : (
            <div className='text-center my-2'>Primeira visita</div>
          ))}
      </CardBody>
    </Card>
  )
}

const GuestComments = () => {
  const { reservationInfo, onNewComment } = useContext(Context)
  const [content, setContent] = useState('')
  const [isSendingContent, setIsSendingContent] = useState(false)

  const sendComment = e => {
    e.preventDefault()
    setIsSendingContent(true)
    api
      .post(`/vehicles/${reservationInfo.reservation.vehicleId}/comments`, {
        content,
      })
      .then(({ data }) => {
        onNewComment(data)
        setContent('')
        setIsSendingContent(false)
      })
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Comentários sobre hóspede
          {reservationInfo.reservation.vehicle.metadata?.comments && (
            <Badge color='danger' className='float-right ml-2'>
              {reservationInfo.reservation.vehicle.metadata.comments.length}{' '}
              comentário
              {pluralize(
                reservationInfo.reservation.vehicle.metadata.comments.length
              )}
            </Badge>
          )}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {reservationInfo.reservation.vehicle.metadata?.comments ? (
          reservationInfo.reservation.vehicle.metadata.comments.map(
            (comment, idx) => (
              <Media key={idx} style={{ marginBottom: 8 }}>
                <Media body>
                  {comment.content}
                  <br />
                  <small className='text-muted'>
                    {comment.username} comentou em{' '}
                    {moment(comment.timestamp).format('DD/MM/YYYY HH:mm')}
                  </small>
                  <br />
                </Media>
              </Media>
            )
          )
        ) : (
          <div className='text-center mb-4'>Nenhum comentário</div>
        )}

        <Form className='py-3 border-top' onSubmit={sendComment}>
          <InputGroup>
            <Input
              type='textarea'
              name='content'
              placeholder='Digite um comentário aqui'
              style={{ resize: 'none' }}
              value={content}
              onChange={e => setContent(e.target.value)}
              disabled={isSendingContent}
            />
            <InputGroupAddon addonType='append'>
              <Button type='submit' color='primary' disabled={isSendingContent}>
                Enviar
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </CardBody>
    </Card>
  )
}

const GuestImages = () => {
  const { reservationInfo } = useContext(Context)

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  if (!reservationInfo.reservation.capture) return <div></div>

  const items = [
    {
      src: reservationInfo.reservation.capture.imgURIs.plateURIs[0].imgURI,
      altText: '',
      caption: '',
    },
    {
      src: reservationInfo.reservation.capture.imgURIs.vehicleURIs[0].imgURI,
      altText: '',
      caption: '',
    },
  ]

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} height={160} />
        <CarouselCaption
          captionText={item.caption}
          // captionHeader={item.caption}
        />
      </CarouselItem>
    )
  })

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Imagens
        </CardTitle>

        <CardBody>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
            color='black'
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
              color='black'
            />
            {slides}
            <CarouselControl
              direction='prev'
              directionText='Previous'
              onClickHandler={previous}
              color='black'
            />
            <CarouselControl
              direction='next'
              directionText='Next'
              onClickHandler={next}
              color='black'
            />
          </Carousel>
        </CardBody>
      </CardHeader>
    </Card>
  )
}

const PedestrianPlaceholder = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Pedestrian style={{ height: 100, width: 100, marginTop: 70 }} />
      <div>
        <strong style={{ fontSize: 16 }}>Pedestre</strong>
      </div>
    </div>
  )
}
