import React from 'react'
import GoBack from 'components/GoBack'
import useGetApi from 'hooks/useGetApi'

export default function CounterSaleInvoice() {
  const [productFlow] = useGetApi(`/products/productFlow`)

  return (
    <div>
      <div className='d-flex mb-2'>
        <h1 className='h3 mb-3'>
          <GoBack />
          Venda Balcão
        </h1>
      </div>
      {JSON.stringify(productFlow, null, 2)}
    </div>
  )
}
