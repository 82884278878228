import React, { useContext } from 'react'
import { Context } from '../ReservationContext'
import { stringToCurrency } from 'util/currency-util'
import { Card, CardHeader, CardTitle, Table } from 'reactstrap'
import { Trash, Printer } from 'react-feather'
import { IconButton } from '@material-ui/core'

export function Invoice() {
  const ctx = useContext(Context)

  return (
    <Card>
      <CardHeader>
        <div className='card-actions float-right'>
          {ctx.reservationInfo?.reservation?.roomReleasedAt && (
            <IconButton onClick={() => ctx.printCompanyInvoice()}>
              <Printer className='feather' />
            </IconButton>
          )}
        </div>
        <CardTitle tag='h5' className='mb-0'>
          Fatura
        </CardTitle>
      </CardHeader>
      <Table>
        <thead>
          <tr>
            <th className='d-none d-md-table-cell' style={{ width: '20%' }}>
              Data
            </th>
            <th>Item</th>
            <th className='text-center' style={{ width: '10%' }}>
              Qtd
            </th>
            <th style={{ width: '15%' }}>Subtotal</th>
            <th style={{ width: '10%' }}></th>
          </tr>
        </thead>
        <tbody>
          {[
            ...ctx.hospitalityItems,
            ...ctx.productItems,
            ...ctx.discountItems,
          ].map(item => (
            <tr key={item.itemId}>
              <td className='d-none d-md-table-cell'>{item.date}</td>
              <td>{item.item}</td>
              <td className='text-center'>{item.quantity}</td>
              <td>{stringToCurrency(item.price)}</td>
              <td className='table-action text-right'>
                {!ctx.reservationInfo?.reservation?.roomReleasedAt &&
                  (item.type === 'Consumo' || item.type === 'Desconto') && (
                    <button
                      className='btn btn-icon btn-light btn-sm'
                      disabled={ctx.isDeletingInvoiceItem}
                      onClick={() => {
                        if (item.type === 'Consumo') {
                          ctx.deleteInvoiceItem(item)
                        } else if (item.type === 'Desconto') {
                          ctx.deleteDiscount(item)
                        }
                      }}
                    >
                      <Trash className='align-middle' size={18} />
                    </button>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className='d-none d-md-table-cell'></td>
            <td>
              <strong>TOTAL</strong>
            </td>
            <td></td>
            <td>
              <strong>{stringToCurrency(ctx.invoiceTotal)}</strong>
            </td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    </Card>
  )
}
