import React, { useState } from 'react'
import moment from 'moment'
import api from 'api'
import useGetApi from 'hooks/useGetApi'
import { toastr } from 'react-redux-toastr'
import { stringToCurrency } from 'util/currency-util'
import CurrencyInput from 'react-currency-input-field'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Label,
  Input,
  Table,
  Row,
  Col,
} from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import { Trash } from 'react-feather'

const categories = [
  'A&B',
  'Água',
  'Alimentação',
  'Aluguel',
  'Café da Manhã',
  'Combustível',
  'Documentação',
  'Energia',
  'Funcionário',
  'Funcionário - Vale',
  'Impostos',
  'Internet',
  'Manutenção',
  'Material Escritório',
  'Material Limpeza',
  'Outro',
  'Publicidade',
  'Serviços',
  'Telefonia',
  'Treinamento',
]

export default function ShiftExpenses({ location }) {
  const [outflow, isLoading, , setOutflow] = useGetApi(
    '/shifts/current/financial-outflow'
  )
  const isWithdrawal = new URLSearchParams(location.search).get('withdrawal')

  if (isLoading) return <div>Carregando...</div>

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col lg='3'>
          <AddExpense setOutflow={setOutflow} isWithdrawal={isWithdrawal} />
        </Col>
        <Col>
          <ExpensesTable outflow={outflow} setOutflow={setOutflow} />
        </Col>
      </Row>
    </Container>
  )
}

const AddExpense = ({ setOutflow, isWithdrawal }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Adicionar
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={{
            category: '',
            amount: 0,
            description: '',
          }}
          validate={values => {
            const errors = {}

            if (values.category === '') {
              errors.category = 'Categoria não pode estar vazio'
            }
            if (values.amount <= 0) {
              errors.amount = 'Valor inválido'
            }

            if (values.description === '') {
              errors.description = 'Informe uma descrição'
            }

            return errors
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            values.amount = parseFloat(values.amount.replace(',', '.'))
            setSubmitting(true)
            api
              .post('/shifts/current/financial-outflow', values)
              .then(({ data: outflow }) => {
                setOutflow(outflow)
                setSubmitting(false)
                resetForm()
              })
              .catch(e => {
                setSubmitting(false)
                toastr.error(e.response.data)
              })
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form style={{ flex: 1 }}>
              <Row>
                <Label htmlFor='category'>Categoria</Label>
                <Field
                  as={Input}
                  type='select'
                  name='category'
                  id='exampleCustomSelect'
                  style={{ width: '100%' }}
                  valid={
                    touched.category &&
                    (categories.includes(values.category) ||
                      touched.category === 'Sangria')
                  }
                  invalid={touched.category && !!errors.category}
                >
                  <option value={''}>Selecione a categoria</option>
                  {!!isWithdrawal ? (
                    <option value='Sangria'>Sangria</option>
                  ) : (
                    categories.map(category => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))
                  )}
                </Field>
              </Row>

              <Row className='mt-3'>
                <Label htmlFor='amount'>Valor</Label>
                <CurrencyInput
                  className='form-control'
                  style={{ width: '100%' }}
                  name='amount'
                  placeholder='R$'
                  allowNegativeValue={false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  maxLength={10}
                  value={values.amount}
                  onValueChange={value => setFieldValue('amount', value)}
                  // valid={touched.amount && values.amount > 0}
                  // invalid={touched.amount && !!errors.amount}
                />
              </Row>

              <Row className='mt-3'>
                <Label htmlFor='description'>Descrição</Label>
                <Input
                  id='description'
                  type='textarea'
                  name='description'
                  value={values.description}
                  onChange={e => setFieldValue('description', e.target.value)}
                  style={{ resize: 'none' }}
                  valid={touched.description && values.description.length > 2}
                  invalid={touched.description && !!errors.description}
                />
              </Row>

              <Button
                className='mt-4'
                color='primary'
                type='submit'
                disabled={isSubmitting}
              >
                Adicionar
              </Button>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

const ExpensesTable = ({ outflow, setOutflow }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteItem = transactionId => {
    setIsDeleting(true)
    api
      .delete(`/shifts/current/financial-outflow/${transactionId}`)
      .then(({ data: outflow }) => {
        setIsDeleting(false)
        setOutflow(outflow)
      })
      .catch(e => {
        setIsDeleting(false)
        toastr.error(e.response.data)
      })
  }

  return (
    <Card className='flex-fill w-100'>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Saídas Financeiras
        </CardTitle>
      </CardHeader>

      <Table striped>
        <thead>
          <tr>
            <th>Data</th>
            <th>Categoria</th>
            <th>Valor</th>
            <th>Descrição</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {outflow?.map(item => (
            <tr key={item.transactionId}>
              <td>{moment(item.date).format('DD/MM/YYYY HH:mm')}</td>
              <td>
                {item.type === 'Despesa' ? item.expense.category : item.type}
              </td>
              <td>{stringToCurrency(item.value)}</td>
              <td>{item.metadata.description}</td>
              <td className='text-right'>
                <button
                  className='btn btn-icon btn-light btn-sm'
                  disabled={isDeleting}
                  onClick={() => deleteItem(item.transactionId)}
                >
                  <Trash className='align-middle' size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
