import * as types from '../constants'

const initialState = {
  name: localStorage.getItem('@printerName'),
  paperSize: localStorage.getItem('@printerPaperSize'),
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_PRINTER_NAME:
      localStorage.setItem('@printerName', action.payload)
      return {
        ...state,
        name: action.payload,
      }
    case types.SET_PRINTER_PAPER_SIZE:
      localStorage.setItem('@printerPaperSize', action.payload)
      return {
        ...state,
        paperSize: action.payload,
      }
    default:
      return state
  }
}
