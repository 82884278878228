import React, { useContext, useState } from 'react'
import { Context } from './ShiftContext'
import { Link } from 'react-router-dom'
import ConfirmationDialog from 'components/ConfirmationDialog'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export function Header() {
  const ctx = useContext(Context)
  const [isCloseShiftModalOpen, setIsCloseShiftModalOpen] = useState(false)

  const isOpen = !ctx.shift.endDate

  return (
    <div className='d-flex justify-content-between mb-2'>
      <h1 className='h3 mb-3'>Turno</h1>

      {isOpen && (
        <div>
          <Link to='/current-shift/financial-outflow'>
            <Button outline color='primary' className='mt-n1 ml-2'>
              <FontAwesomeIcon icon={faPlus} /> Despesa
            </Button>
          </Link>
          <Link to='/current-shift/financial-outflow?withdrawal=1'>
            <Button outline color='primary' className='mt-n1 ml-2'>
              <FontAwesomeIcon icon={faPlus} /> Sangria
            </Button>
          </Link>

          <Button
            outline
            color='primary'
            className='mt-n1 ml-2'
            disabled={ctx.isClosingShift}
            onClick={() => setIsCloseShiftModalOpen(true)}
          >
            Fechar Turno
          </Button>

          <ConfirmationDialog
            isOpen={isCloseShiftModalOpen}
            setIsOpen={setIsCloseShiftModalOpen}
            title='Fechar Turno'
            body='Gostaria de encerrar este turno?'
            onConfirm={() => ctx.closeShift(ctx.shift.shiftId)}
          />
        </div>
      )}

      {!isOpen && (
        <div>
          <Button outline color='primary' className='mt-n1 ml-2'>
            Imprimir
          </Button>
        </div>
      )}
    </div>
  )
}
