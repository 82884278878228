import React, { useContext } from 'react'
import moment from 'moment'
import { Context } from '../ReservationContext'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap'

import Timeline from 'components/Timeline'
import TimelineItem from 'components/TimelineItem'

export const ReservationTimeline = () => {
  const ctx = useContext(Context)

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xl='6'>
          <Card>
            <CardHeader>
              <CardTitle tag='h5' className='mb-0'>
                Linha do Tempo
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Timeline className='mt-2'>
                {ctx.reservationInfo.reservation.metadata.timeline.map(
                  (item, idx) => (
                    <TimelineItem key={idx}>
                      <strong>{item.title}</strong>
                      <span className='float-right text-muted text-sm'>
                        {moment(item.timestamp).format('DD/MM/YY HH:mm')}
                      </span>
                      <p>{item.description}</p>
                    </TimelineItem>
                  )
                )}
              </Timeline>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
