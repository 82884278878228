import React from 'react'
import moment from 'moment'

import { Button, ButtonGroup } from 'reactstrap'

import DatePicker from 'react-datepicker'
import pt from 'date-fns/locale/pt'
import 'react-datepicker/dist/react-datepicker.css'

export default function HeaderDatePicker({ date, setDate }) {
  return (
    <>
      <div>
        <DatePicker
          className='form-control'
          value={date.format('DD/MM/YYYY')}
          selected={date.toDate()}
          onChange={value => setDate(moment(value))}
          maxDate={new Date()}
          locale={pt}
        />
      </div>

      <ButtonGroup>
        <Button
          color='primary'
          className='ml-2'
          onClick={() => setDate(date.clone().add(-1, 'day'))}
        >
          Anterior
        </Button>
        <Button
          color='primary'
          className=''
          onClick={() => setDate(date.clone().add(1, 'day'))}
          disabled={moment().isSame(date, 'date')}
        >
          Próximo
        </Button>
      </ButtonGroup>

      <Button
        color='primary'
        className='ml-2'
        onClick={() => setDate(moment())}
        disabled={moment().isSame(date, 'date')}
      >
        Hoje
      </Button>
    </>
  )
}
