import axios from 'axios'
import store from 'redux/store'
import { signOut } from 'redux/actions/authActions'
import history from 'util/history'

// export const baseURL = 'http://localhost:4040'
export const baseURL = 'https://api.motelo.geekery.com.br'

const headers = {
  'Content-Type': 'application/json',
  Platform: 'electron',
}

const instance = axios.create({
  baseURL,
  headers,
})

instance.interceptors.request.use(
  config => {
    const token = store.getState().auth.token
    if (token) config.headers.Authorization = `Bearer ${token}`

    config.headers.companyId = store.getState().auth.currentCompany?.companyId

    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.status === 401) {
      store.dispatch(signOut())
      history.push('/auth/sign-in')
    }
    return Promise.reject(error)
  }
)

export default instance
