import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRoom, setRooms } from 'redux/actions/roomActions'
import { io } from 'socket.io-client'
import { baseURL } from '.'
import { CheckinGuest } from 'pages/checkin/CheckinGuest'
import {
  setChatUsers,
  updateChatUserOnline,
  updateChatUnreadMessageCount,
} from 'redux/actions/chatActions'
import newMessageSound from '../assets/sound/new_message.wav'

export const SocketContext = createContext()

export function SocketProvider({ children }) {
  const dispatch = useDispatch()
  // const { showAutomaticVehicleCaptures } = useSelector(state => state.settings)
  const { token, user, currentCompany } = useSelector(state => state.auth)

  const [vehicleQueue, setVehicleQueue] = useState([])

  const vehicleQueueRef = useRef(vehicleQueue)
  const userRef = useRef(user)
  const currentCompanyRef = useRef(currentCompany)
  // const showAutomaticVehicleCapturesRef = useRef(showAutomaticVehicleCaptures)

  userRef.current = user
  currentCompanyRef.current = currentCompany

  const addManualCheckin = () => {
    if (!vehicleQueueRef.current.some(vehicle => !!vehicle.manual)) {
      const guest = new CheckinGuest()
      vehicleQueueRef.current = [guest]
      setVehicleQueue(vehicleQueueRef.current)
    }
  }

  const removeManualCheckins = () => {
    vehicleQueueRef.current = vehicleQueueRef.current.filter(guest =>
      guest.isAutomaticCheckin()
    )
    setVehicleQueue(vehicleQueueRef.current)
  }

  const chatSocket = useMemo(
    () =>
      io(baseURL + '/chat', {
        autoConnect: false,
        reconnection: true,
        auth: { token },
      }),
    [token]
  )

  useEffect(() => {
    const mainSocket = io(baseURL + '/company', {
      autoConnect: false,
      reconnection: true,
      auth: { token },
    })

    if (token) {
      mainSocket.connect()
      chatSocket.connect()
    } else {
      mainSocket.disconnect()
      chatSocket.disconnect()
    }

    const onNewVehicle = data => {
      // if (showAutomaticVehicleCapturesRef.current) {
      // }
      vehicleQueueRef.current = data.map(item => new CheckinGuest(item))
      setVehicleQueue(vehicleQueueRef.current)
    }
    const onRoomUpdate = data => {
      if (data.companyId === currentCompanyRef.current.companyId)
        dispatch(setRoom(data.room))
    }

    const onRoomsUpdate = data => {
      if (data.companyId === currentCompanyRef.current.companyId)
        dispatch(setRooms(data.rooms))
    }

    const onChatUsers = data => dispatch(setChatUsers(data))
    const onChatUserOnline = data => dispatch(updateChatUserOnline(data))
    const onChatNewMessage = data => {
      if (data.receiverId === userRef.current.userId)
        new Audio(newMessageSound).play()
      dispatch(updateChatUnreadMessageCount(data))
    }

    mainSocket.on('vehicle:alpr', onNewVehicle)
    mainSocket.on('room:update', onRoomUpdate)
    mainSocket.on('rooms:update', onRoomsUpdate)

    chatSocket.on('chat:users', onChatUsers)
    chatSocket.on('chat:user-online', onChatUserOnline)
    chatSocket.on('chat:new-message', onChatNewMessage)

    return () => {
      dispatch(setRooms([]))

      mainSocket.off('vehicle:alpr', onNewVehicle)
      mainSocket.off('room:update', onRoomUpdate)
      mainSocket.off('rooms:update', onRoomsUpdate)

      chatSocket.off('chat:users', onChatUsers)
      chatSocket.off('chat:user-online', onChatUserOnline)
      chatSocket.off('chat:new-message', onChatNewMessage)

      mainSocket.disconnect()
      chatSocket.disconnect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentCompany])

  return (
    <SocketContext.Provider
      value={{
        chatSocket,
        vehicleQueueRef,
        vehicleQueue,
        setVehicleQueue,
        addManualCheckin,
        removeManualCheckins,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}
