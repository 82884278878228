import React, { useEffect, useRef, useState } from 'react'
import { isElectron } from 'util/electron-util'

const Version = () => {
  const [version, setVersion] = useState(null)
  const [showDownloading, setShowDownloading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(null)
  const [showUpdate, setShowUpdate] = useState(false)

  const renderer = useRef(null)

  useEffect(() => {
    if (isElectron) {
      const { ipcRenderer } = window.require('electron')
      renderer.current = ipcRenderer

      ipcRenderer.send('app-version')

      ipcRenderer.on('app-version', (event, arg) => {
        ipcRenderer.removeAllListeners('app-version')
        setVersion(arg.version)
      })

      ipcRenderer.on('update-available', () => {
        ipcRenderer.removeAllListeners('update-available')
        setShowDownloading(true)
      })

      ipcRenderer.on('download-progress', (event, arg) => {
        setDownloadProgress(arg)
      })

      ipcRenderer.on('update-downloaded', () => {
        ipcRenderer.removeAllListeners('update-downloaded')
        setShowUpdate(true)
      })
    }
  }, [])

  return (
    <>
      {showDownloading && !showUpdate && (
        <span className='text-muted mr-2'>
          Baixando atualização
          {downloadProgress && ` (${downloadProgress.toFixed(0)}%)`}
        </span>
      )}
      {showUpdate && (
        <span
          className='text-primary mr-1'
          style={{ cursor: 'pointer' }}
          onClick={() => renderer.current?.send('quit-and-install')}
        >
          Atualizar
        </span>
      )}
      {version && `Versão ${version} `}
    </>
  )
}

export default Version
