import React from 'react'
import moment from 'moment'
import { stringToCurrency } from 'util/currency-util'
import { Col, Card, CardHeader, CardTitle, Table, Row } from 'reactstrap'
import GoBack from 'components/GoBack'
import useGetApi from 'hooks/useGetApi'

export default function CounterSaleInvoice({ match }) {
  const [saleInfo] = useGetApi(`/sale/${match.params.invoiceId}`)

  return (
    <div>
      <div className='d-flex mb-2'>
        <h1 className='h3 mb-3'>
          <GoBack />
          Venda Balcão
        </h1>
      </div>

      {saleInfo && (
        <Row>
          <Col xs='12' md='6'>
            <InvoiceItems items={saleInfo.invoiceItems} />
          </Col>
          <Col xs='12' md='6'>
            <Payments payments={saleInfo.payments} />
          </Col>
        </Row>
      )}
    </div>
  )
}

function InvoiceItems({ items }) {
  return (
    <Card>
      <CardHeader>
        {/* <div className='card-actions float-right'>
          {ctx.reservationInfo?.reservation?.roomReleasedAt && (
            <IconButton onClick={() => ctx.printCompanyInvoice()}>
              <Printer className='feather' />
            </IconButton>
          )}
        </div> */}
        <CardTitle tag='h5' className='mb-0'>
          Fatura
        </CardTitle>
      </CardHeader>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Data</th>
            <th>Item</th>
            <th className='text-center' style={{ width: '10%' }}>
              Qtd
            </th>
            <th style={{ width: '15%' }}>Subtotal</th>
            <th style={{ width: '10%' }}></th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.itemId}>
              <td>{moment(item.date).format('DD/MM/YYYY HH:mm')}</td>
              <td>{item.item}</td>
              <td className='text-center'>{item.quantity}</td>
              <td>{stringToCurrency(item.price)}</td>
              <td className='table-action text-right'>
                {/* {!ctx.reservationInfo?.reservation?.roomReleasedAt &&
                  (item.type === 'Consumo' || item.type === 'Desconto') && (
                    <button
                      className='btn btn-icon btn-light btn-sm'
                      disabled={ctx.isDeletingInvoiceItem}
                      onClick={() => {
                        if (item.type === 'Consumo') {
                          ctx.deleteInvoiceItem(item)
                        } else if (item.type === 'Desconto') {
                          ctx.deleteDiscount(item)
                        }
                      }}
                    >
                      <Trash className='align-middle' size={18} />
                    </button>
                  )} */}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>
              <strong>TOTAL</strong>
            </td>
            <td></td>
            <td>
              <strong>
                {stringToCurrency(
                  items.reduce((acc, item) => item.price + acc, 0)
                )}
              </strong>
            </td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    </Card>
  )
}

function Payments({ payments }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Pagamentos
        </CardTitle>
      </CardHeader>
      <Table>
        <thead>
          <tr>
            <th>Forma</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {payments.map(payment => {
            return (
              <tr key={payment.paymentId}>
                <td>{payment.method}</td>
                <td>{stringToCurrency(payment.amount)}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Card>
  )
}
