import React, { useEffect, useState } from 'react'
import { isElectron } from 'util/electron-util'
import { useDispatch, useSelector } from 'react-redux'
import { setPrinterName, setPrinterPaper } from 'redux/actions/printerActions'
import useSessionCheck from 'hooks/sessionCheck'
import {
  Container,
  CustomInput,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap'

export default function PrinterPicker() {
  useSessionCheck()
  return (
    <Container fluid className='p-0'>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='h3 mb-3'>Impressoras</h1>
      </div>

      <Row>
        <Col>
          <Printer />
        </Col>
        <Col>
          <PaperSize />
        </Col>
      </Row>
    </Container>
  )
}

function Printer() {
  const dispatch = useDispatch()
  const defaultPrinter = useSelector(state => state.printer)

  const [printers, setPrinters] = useState([])
  console.log(defaultPrinter)
  useEffect(() => {
    if (!isElectron) return
    const { ipcRenderer } = window.require('electron')
    ipcRenderer.send('printers')
    ipcRenderer.on('printers', (_, printers) => {
      // console.log(printers)
      setPrinters(printers)
    })
  }, [])

  const setPrinter = printerName => {
    dispatch(setPrinterName(printerName))
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Escolha a impressora de cupom padrão
        </CardTitle>
      </CardHeader>

      <CardBody>
        <div>
          {printers.length === 0 ? (
            <div>Nenhuma impressora encontrada</div>
          ) : (
            printers.map((printer, idx) => (
              <CustomInput
                key={idx}
                type='radio'
                id={`printerRadio${idx + 1}`}
                name='printer'
                checked={printer.name === defaultPrinter.name}
                onChange={() => setPrinter(printer.name)}
                label={printer.name} // maybe printer.displayName
              />
            ))
          )}
        </div>
      </CardBody>
    </Card>
  )
}

function PaperSize() {
  const dispatch = useDispatch()
  const defaultPrinter = useSelector(state => state.printer)

  const setPaperSize = size => {
    dispatch(setPrinterPaper(size))
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Escolha a tamanho do papel
        </CardTitle>
      </CardHeader>

      <CardBody>
        <div>
          <CustomInput
            type='radio'
            id='paperRadio1'
            name='paper'
            label='140px'
            checked={defaultPrinter.paperSize === '140px'}
            onChange={() => setPaperSize('140px')}
          />
          <CustomInput
            type='radio'
            id='paperRadio2'
            name='paper'
            label='170px'
            checked={defaultPrinter.paperSize === '170px'}
            onChange={() => setPaperSize('10p7x')}
          />
          <CustomInput
            type='radio'
            id='paperRadio3'
            name='paper'
            label='200px'
            checked={defaultPrinter.paperSize === '200px'}
            onChange={() => setPaperSize('200px')}
          />
          <CustomInput
            type='radio'
            id='paperRadio4'
            name='paper'
            label='250px'
            checked={defaultPrinter.paperSize === '250px'}
            onChange={() => setPaperSize('250px')}
          />
          <CustomInput
            type='radio'
            id='paperRadio5'
            name='paper'
            label='280px'
            checked={defaultPrinter.paperSize === '280px'}
            onChange={() => setPaperSize('280px')}
          />
          <CustomInput
            type='radio'
            id='paperRadio6'
            name='paper'
            label='300px'
            checked={defaultPrinter.paperSize === '300px'}
            onChange={() => setPaperSize('300px')}
          />
        </div>
      </CardBody>
    </Card>
  )
}
