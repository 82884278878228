import React, { useCallback, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { SocketContext } from 'api/SocketProvider'
import { clearUnreadMessageCount } from 'redux/actions/chatActions'
import api from 'api'
import useWindowDimensions from 'hooks/useWindowDimentions'
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Media,
} from 'reactstrap'

export default function ChatMessageList({ addressed }) {
  const dispatch = useDispatch()
  const { height } = useWindowDimensions()

  const { chatSocket } = useContext(SocketContext)
  const { user } = useSelector(state => state.auth)

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')

  const getMessages = () => {
    api.get(`/chat/${addressed.userId}`).then(({ data }) => {
      setMessages(data)
      dispatch(clearUnreadMessageCount(addressed.userId))
    })
  }

  const onNewMessage = useCallback(data => {
    if (data.senderId === addressed.userId || data.senderId === user.userId) {
      setMessages(messages => [data, ...messages])
    }
  }, [])

  useEffect(() => {
    getMessages()
    chatSocket.on('chat:new-message', onNewMessage)
    return () => {
      chatSocket.off('chat:new-message', onNewMessage)
    }
  }, [])

  const sendMessage = e => {
    e.preventDefault()
    if (!message.trim()) return

    chatSocket.emit('chat:send', {
      content: message.trim(),
      addressedId: addressed.userId,
    })
    setMessage('')
  }

  return (
    <>
      <div className='py-2 px-4 border-bottom d-none d-lg-block'>
        <Media className='align-items-center py-1'>
          <div className='position-relative'>
            <img
              src={addressed.imageUrl}
              className='rounded-circle mr-1'
              alt=''
              width='40'
              height='40'
            />
          </div>
          <Media body className='pl-3'>
            <strong>{`${addressed.firstname} ${addressed.surname}`}</strong>
            <div className='text-muted small'>
              {user.companies.map(company => company.name).join(', ')}
            </div>
          </Media>
        </Media>
      </div>

      <div className='position-relative'>
        <div
          className='p-4'
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            height: height - 336,
            overflowY: 'scroll',
          }}
        >
          {messages?.length > 0 ? (
            messages.map(message => (
              <ChatMessage
                key={message.messageId}
                position={
                  message.receiverId === addressed.userId ? 'right' : 'left'
                }
                name={
                  message.receiverId === addressed.userId
                    ? 'Você'
                    : `${addressed.firstname} ${addressed.surname}`
                }
                time={moment(message.date).format('DD/MM/YY HH:mm')}
              >
                {message.content}
              </ChatMessage>
            ))
          ) : (
            <div className='text-center'>Nenhuma mensagem</div>
          )}
        </div>
      </div>

      <Form className='flex-grow-0 py-3 px-4 border-top' onSubmit={sendMessage}>
        <InputGroup>
          <Input
            type='text'
            placeholder='Digite sua mensagem'
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <InputGroupAddon addonType='append'>
            <Button type='submit' color='primary'>
              Enviar
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Form>
    </>
  )
}

const ChatMessage = ({ position, name, children, time }) => (
  <div className={`chat-message-${position} py-1`}>
    <div>
      <div className='text-muted small text-nowrap mt-2'>{time}</div>
    </div>
    <div
      className={`flex-shrink-1 bg-light rounded py-2 px-3 ${
        position === 'right' ? 'mr-3' : 'ml-3'
      }`}
    >
      <div className='font-weight-bold mb-1'>{name}</div>
      {children}
    </div>
  </div>
)
