import React from 'react'
import moment from 'moment'
import { stringToCurrency } from 'util/currency-util'
import { Key, Clock, DollarSign } from 'react-feather'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'

export default function ReservationListStats({ reservations }) {
  const reservationsCount = reservations.length

  const revenue = reservations.reduce(
    (acc, item) =>
      item.invoiceTotal ? parseFloat(item.invoiceTotal) + acc : acc,
    0
  )

  const avgTicket = reservationsCount > 0 ? revenue / reservationsCount : 0

  const totalMinutes =
    reservationsCount > 0
      ? (
          reservations.reduce((acc, item) => {
            return (
              moment(item.checkout ? item.checkout : Date.now()).diff(
                item.checkin,
                'minutes'
              ) + acc
            )
          }, 0) / reservationsCount
        ).toFixed()
      : 0

  return (
    <Row className='justify-content-around'>
      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <Key className='feather-lg text-primary' />
              </div>
              <Media body>
                <h3 className='mb-2'>{reservationsCount}</h3>
                <div className='mb-0'>Hospedagens</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <DollarSign className='feather-lg text-primary' />
              </div>
              <Media body>
                <h3 className='mb-2'>{stringToCurrency(revenue, 0)}</h3>
                <div className='mb-0'>Faturamento</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <DollarSign className='feather-lg text-primary' />
              </div>
              <Media body>
                <h3 className='mb-2'>{stringToCurrency(avgTicket)}</h3>
                <div className='mb-0'>Tíquete Médio</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <Clock className='feather-lg text-primary' />
              </div>
              <Media body>
                <h3 className='mb-2'>{totalMinutes} min</h3>
                <div className='mb-0'>Permanência Média</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
