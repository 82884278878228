import React, { useContext } from 'react'
import { Context } from './ShiftContext'
import { stringToCurrency } from 'util/currency-util'
import { Download, User, DollarSign } from 'react-feather'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'

export const TopInfo = () => {
  const ctx = useContext(Context)

  const financialTransactions = ctx.shift.financialTransactions

  const guestNumber =
    financialTransactions?.filter(t => t.site === 'Hospedagem').length || 0

  const revenue = financialTransactions.reduce(
    (acc, item) =>
      item.type === 'Receita' ? acc + parseFloat(item.value) : acc,
    0
  )

  const expenses = financialTransactions.reduce(
    (acc, item) =>
      item.type === 'Despesa' ? acc + parseFloat(item.value) : acc,
    0
  )

  return (
    <Row>
      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <User className='feather-lg text-primary' />
              </div>
              <Media body>
                <h3 className='mb-2'>{ctx.shift.user.firstname}</h3>
                <div className='mb-0'>Operador</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <Download
                  className='feather-lg text-warning'
                  style={{ transform: 'rotate(270deg)' }}
                />
              </div>
              <Media body>
                <h3 className='mb-2'>{guestNumber}</h3>
                <div className='mb-0'>Saídas</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <DollarSign className='feather-lg text-success' />
              </div>
              <Media body>
                <h3 className='mb-2'>{stringToCurrency(revenue)}</h3>
                <div className='mb-0'>Faturamento</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <DollarSign className='feather-lg text-danger' />
              </div>
              <Media body>
                <h3 className='mb-2'>{stringToCurrency(expenses)}</h3>
                <div className='mb-0'>Despesas</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
