import React, { useContext } from 'react'
import { ProductContext } from './ProductContext'
import CurrencyInput from 'react-currency-input-field'

import {
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap'

export function ProductInfo({ formik }) {
  const { productInfo, categories } = useContext(ProductContext)
  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor='title'>Produto</Label>
              <Input
                type='text'
                id='title'
                name='title'
                placeholder='Produto'
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik?.errors.title}</FormFeedback>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label htmlFor='price'>Preço</Label>
              <CurrencyInput
                className='form-control'
                name={'price'}
                placeholder='R$'
                allowNegativeValue={false}
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                maxLength={10}
                value={formik.values.price}
                onValueChange={value => formik.setFieldValue('price', value)}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik?.errors.price}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md='6'>
            <FormGroup>
              <Label htmlFor='category'>Categoria</Label>
              <Input
                type='select'
                id='category'
                name='category'
                placeholder='Categoria'
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {(productInfo?.categories || categories)?.map(item => (
                  <option key={item.category} value={item.category}>
                    {item.category}
                  </option>
                ))}
                <option value='new'>Nova Categoria</option>
              </Input>
              <FormFeedback>{formik?.errors.category}</FormFeedback>
            </FormGroup>
          </Col>

          {(formik.values.category === 'new' ||
            productInfo?.categories.length === 0) && (
            <Col md='6'>
              <FormGroup>
                <Label htmlFor='newCategory'>Nova Categoria</Label>
                <Input
                  type='text'
                  id='newCategory'
                  name='newCategory'
                  placeholder='Nova Categoria'
                  value={formik.values.newCategory}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormFeedback>{formik?.errors.newCategory}</FormFeedback>
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col md='6'>
            <FormGroup>
              <Label htmlFor='barcode'>Cód. Barras</Label>
              <Input
                type='text'
                id='barcode'
                name='barcode'
                placeholder='Cód. Barras (opcional)'
                value={formik.values.barcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik?.errors.barcode}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
