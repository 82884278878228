import React from 'react'
import { useSelector } from 'react-redux'
import {
  // BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import {
  home as homeRoutes,
  companySelection as companySelectionRoute,
  rooms as roomRoutes,
  reservation as reservationRoutes,
  counterSale as counterSaleRoutes,
  currentShift as currentShiftRoutes,
  chat as chatRoutes,
  shifts as shiftRoutes,
  alpr as surveillanceRoutes,
  admin as adminRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes,
} from './index-template'
import history from '../util/history'

import SignIn from 'pages/auth/SignIn'
import DashboardLayout from 'layouts/Dashboard'
// import LandingLayout from 'layouts/Landing'
import AuthLayout from 'layouts/Auth'
import Page404 from 'pages/auth/Page404'

import ScrollToTop from 'components/ScrollToTop'

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <CustomRoute
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <CustomRoute
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  )

const CustomRoute = ({ isPrivate, ...rest }) => {
  const auth = useSelector(state => state.auth)
  if (isPrivate && !auth.isAuthenticated) {
    return <Redirect exact to='/auth/sign-in' />
  }
  return <Route {...rest} />
}

const Routes = () => {
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route path='/auth/sign-in' component={SignIn} />

          {childRoutes(AuthLayout, companySelectionRoute)}

          {childRoutes(DashboardLayout, homeRoutes)}
          {childRoutes(DashboardLayout, roomRoutes)}
          {childRoutes(DashboardLayout, reservationRoutes)}
          {childRoutes(DashboardLayout, counterSaleRoutes)}
          {childRoutes(DashboardLayout, chatRoutes)}
          {childRoutes(DashboardLayout, shiftRoutes)}
          {childRoutes(DashboardLayout, currentShiftRoutes)}
          {childRoutes(DashboardLayout, surveillanceRoutes)}
          {childRoutes(DashboardLayout, adminRoutes)}
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(AuthLayout, pageRoutes)}

          <Redirect to='/auth/sign-in' />

          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

export default Routes
