import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import { ProductContext, ProductProvider } from './ProductContext'
import { Button, Container, Form } from 'reactstrap'
import { ProductInfo } from './ProductInfo'
import { ProductFlow } from './ProductFlow'
import ConfirmationDialog from 'components/ConfirmationDialog'

export default function ProductSettings({ match }) {
  const productId =
    match.params.productId === 'new' ? null : match.params.productId

  return (
    <ProductProvider productId={productId}>
      <ProductLayout />
    </ProductProvider>
  )
}

function ProductLayout() {
  const {
    productInfo,
    categories,
    isSaving,
    saveInfo,
    deleteProduct,
  } = useContext(ProductContext)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: productInfo?.product.title || '',
      price: productInfo?.product.price || '',
      category:
        productInfo?.product.category || categories[0]?.category || 'new',
      barcode: productInfo?.product.barcode || '',
      newCategory: '',
    },
    onSubmit: values => saveInfo(values),
  })

  return (
    <Container fluid className='p-0'>
      <Form onSubmit={formik.handleSubmit}>
        <div className='d-flex justify-content-between mb-2'>
          <h1 className='h3 mb-3'>Produto</h1>
          <div>
            <Button
              outline
              type='button'
              className='ml-2'
              color='danger'
              disabled={isSaving}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Deletar
            </Button>

            <Button
              outline
              type='submit'
              className='ml-2'
              color='primary'
              disabled={isSaving}
            >
              Salvar
            </Button>

            <ConfirmationDialog
              isOpen={isDeleteModalOpen}
              setIsOpen={setIsDeleteModalOpen}
              title='Deletar Produto'
              body='Gostaria de deletar este produto?'
              onConfirm={deleteProduct}
            />
          </div>
        </div>

        <ProductInfo formik={formik} />
        {productInfo?.product && <ProductFlow />}
      </Form>
    </Container>
  )
}
