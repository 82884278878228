import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import useRooms from 'hooks/useRooms'
import { calculateDuration } from 'util/reservation-duration'
import { ColorPallet } from 'constants/Color'
import RoomStatusModal from './RoomStatusModal'
import useSessionCheck from 'hooks/sessionCheck'
import { Container, Row, Nav, NavItem, NavLink, Badge } from 'reactstrap'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

import Car from '@material-ui/icons/DirectionsCarTwoTone'
import Motorcycle from '@material-ui/icons/MotorcycleTwoTone'
import Pedestrian from '@material-ui/icons/DirectionsWalkTwoTone'
import CameraIcon from '@material-ui/icons/LinkedCameraTwoTone'

const statusColor = status => {
  switch (status) {
    case 'Ocupado':
      return ColorPallet.yellow
    case 'Pendente':
      return ColorPallet.blue
    case 'Limpeza':
      return ColorPallet.purple
    case 'Manutenção':
      return ColorPallet.red
    default:
      return ColorPallet.green
  }
}

export default function Rooms() {
  const [rooms] = useRooms()
  const interval = useRef(null)
  const [now, setNow] = useState(moment())

  const [activeNav, setActiveNav] = useState('Todos')
  const [statusRoom, setStatusRoom] = useState(null)
  const [showStatusModal, setShowStatusModal] = useState(false)

  useSessionCheck()
  useEffect(() => {
    interval.current = setInterval(() => setNow(moment()), 1000)
    return () => clearInterval(interval.current)
  }, [])

  const countStatus = status => {
    if (!rooms || rooms.length === 0) return 0
    if (status === 'Todos') return rooms.length
    return rooms.filter(room => room.status === status).length
  }

  const navItem = (title, status, showBadge = false, alwaysShow = false) => {
    if (alwaysShow || rooms.some(room => room.status === status)) {
      return (
        <NavItem className='d-none d-sm-block'>
          <NavLink
            active={activeNav === status}
            onClick={() => setActiveNav(status)}
          >
            {title}
            {showBadge && (
              <Badge className='ml-1' color='primary'>
                {countStatus(status)}
              </Badge>
            )}
          </NavLink>
        </NavItem>
      )
    }
    return <></>
  }

  return (
    <Container fluid className='p-0'>
      <Row className='mb-4'>
        <h1 className='h3'>Quartos</h1>
        <Nav className='ml-auto' pills>
          {navItem('Todos', 'Todos', false, true)}
          {navItem('Disponíveis', 'Disponível')}
          {navItem('Ocupados', 'Ocupado', true)}
          {navItem('Pendente', 'Pendente', true)}
          {navItem('Limpeza', 'Limpeza', true)}
          {navItem('Manutenção', 'Manutenção', true)}
        </Nav>
      </Row>

      <Row className='justify-content-around'>
        {rooms
          .filter(room =>
            activeNav === 'Todos' ? true : room.status === activeNav
          )
          .map(room => (
            <RoomCard
              key={room.roomId}
              now={now}
              room={room}
              setStatusRoom={setStatusRoom}
              handleStatusModalShow={() => setShowStatusModal(true)}
            />
          ))}
      </Row>

      <RoomStatusModal
        room={statusRoom}
        showStatusModal={showStatusModal}
        setShowStatusModal={setShowStatusModal}
      />
    </Container>
  )
}

const RoomCard = ({ room, now, setStatusRoom, handleStatusModalShow }) => {
  const history = useHistory()
  return (
    <Card
      className='mb-4'
      variant='outlined'
      style={{ width: 200, cursor: 'pointer' }}
      onClick={() => {
        if (room.currentReservation) {
          history.push(`/reservations/${room.currentReservation.reservationId}`)
        } else {
          setStatusRoom(room)
          handleStatusModalShow()
        }
      }}
    >
      <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: statusColor(room.status) }}>
            {room.abbrev}
          </Avatar>
        }
        title={room.title}
        subheader={<div style={{ fontSize: 12 }}>{room.roomType.title}</div>}
        action={
          room.currentReservation && (
            <div>
              {!!room.currentReservation?.automaticCapture && (
                <CameraIcon style={{ height: 14 }} />
              )}
              {room.currentReservation.transportation === 'Carro' && (
                <Car style={{ height: 16 }} />
              )}
              {room.currentReservation.transportation === 'Moto' && (
                <Motorcycle style={{ height: 16 }} />
              )}
              {room.currentReservation.transportation === 'Pedestre' && (
                <Pedestrian style={{ height: 16 }} />
              )}
            </div>
          )
        }
      />
      {room.currentReservation && (
        <Typography
          className='text-center font-weight-bold mb-2'
          variant='h6'
          color='textSecondary'
          component='p'
        >
          {calculateDuration(
            moment(room.currentReservation.checkin),
            room.currentReservation.checkout
              ? moment(room.currentReservation.checkout)
              : now
          )}
        </Typography>
      )}
      {(room.status === 'Disponível' ||
        room.status === 'Limpeza' ||
        room.status === 'Manutenção') &&
        room.metadata.statusMessage && (
          <Typography
            className='text-center mx-2 mb-3'
            variant='body2'
            color='textSecondary'
            component='p'
          >
            {room.metadata.statusMessage}
          </Typography>
        )}
    </Card>
  )
}
