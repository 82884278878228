// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE'
export const SIDEBAR_VISIBILITY_SHOW = 'SIDEBAR_VISIBILITY_SHOW'
export const SIDEBAR_VISIBILITY_HIDE = 'SIDEBAR_VISIBILITY_HIDE'
export const SIDEBAR_STICKY_TOGGLE = 'SIDEBAR_STICKY_TOGGLE'
export const SIDEBAR_STICKY_ENABLE = 'SIDEBAR_STICKY_ENABLE'
export const SIDEBAR_STICKY_DISABLE = 'SIDEBAR_STICKY_DISABLE'

// Layout
export const LAYOUT_BOXED_TOGGLE = 'LAYOUT_BOXED_TOGGLE'
export const LAYOUT_BOXED_ENABLE = 'LAYOUT_BOXED_ENABLE'
export const LAYOUT_BOXED_DISABLE = 'LAYOUT_BOXED_DISABLE'

// Settings
export const AUTOMATIC_VEHICLE_CAPTURE = 'AUTOMATIC_VEHICLE_CAPTURE'

// Theme
export const THEME_TOGGLE = 'THEME_TOGGLE'

// Printers
export const SET_PRINTER_NAME = 'SET_PRINTER_NAME'
export const SET_PRINTER_PAPER_SIZE = 'SET_PRINTER_PAPER_SIZE'

// Authentication
export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE'
export const UPDATE_CURRENT_COMPANY = 'UPDATE_CURRENT_COMPANY'

// Errors
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

// Rooms
export const ROOMS_LOADING = 'ROOMS_LOADING'
export const ROOMS_LOADED = 'ROOMS_LOADED'
export const ROOM_LOADED = 'ROOM_LOADED'
export const ROOMS_CLEAR = 'ROOMS_CLEAR'

// Chat
export const CHAT_USERS = 'CHAT_USERS'
export const CHAT_UPDATE_USER_ONLINE = 'CHAT_UPDATE_USER_ONLINE'
export const CHAT_UPDATE_UNREAD_MESSAGES = 'CHAT_UPDATE_UNREAD_MESSAGES'
export const CHAT_CLEAR_UNREAD_MESSAGES_COUNT =
  'CHAT_CLEAR_UNREAD_MESSAGES_COUNT'
export const CHAT_LOGOUT = 'CHAT_LOGOUT'
