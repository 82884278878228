import React, { useContext } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { HomeContext } from './HomeContext'
import { Button, Col, Row } from 'reactstrap'
import { RefreshCw } from 'react-feather'

const Header = () => {
  const { user } = useSelector(state => state.auth)
  const { loadInfo, isReloading } = useContext(HomeContext)

  const greetings = () => {
    const currentHour = moment().format('HH')

    if (currentHour >= 5 && currentHour < 12) {
      return 'Bom dia'
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Boa tarde'
    } else if (currentHour >= 18 || currentHour < 5) {
      return 'Boa noite'
    } else {
      return 'Olá'
    }
  }

  return (
    <Row className='mb-2 mb-xl-4'>
      <Col xs='auto' className='d-none d-sm-block'>
        <h3>
          {greetings()}, {user?.firstname}!
        </h3>
      </Col>

      <Col xs='auto' className='ml-auto text-right'>
        <Button
          color='primary'
          className='shadow-sm mr-1'
          onClick={loadInfo}
          disabled={isReloading}
        >
          <RefreshCw className='feather' />
        </Button>
      </Col>
    </Row>
  )
}

export default Header
