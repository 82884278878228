import * as types from '../constants'
import api from '../../api'

const dispatchError = (dispatch, msg) => {
  dispatch({
    type: types.AUTH_ERROR,
    payload: msg,
  })
}

// Check token & load user
export const loadUser = () => async dispatch => {
  // User loading
  dispatch({ type: types.USER_LOADING })

  // Fetch user
  api
    .get('/auth/user')
    .then(res => {
      dispatch({ type: types.USER_LOADED, payload: res.data })
    })
    .catch(err => dispatchError(dispatch, err.name))
}

export const signIn = params => async dispatch => {
  api
    .post('/auth/signin', params)
    .then(({ data }) => dispatch({ type: types.LOGIN_SUCCESS, payload: data }))
    .catch(err => dispatchError(dispatch, err.response.data))
}

export const updateUserInfo = values => dispatch => {
  dispatch({ type: types.UPDATE_USER_INFO, payload: values })
}

export const updateUserImage = imageUrl => dispatch => {
  dispatch({ type: types.UPDATE_USER_IMAGE, payload: imageUrl })
}

export const updateCurrentCompany = companyId => dispatch => {
  dispatch({ type: types.UPDATE_CURRENT_COMPANY, payload: companyId })
}

export const signOut = () => async dispatch => {
  dispatch({ type: types.ROOMS_CLEAR, payload: null })
  dispatch({ type: types.CHAT_LOGOUT, payload: null })
  dispatch({ type: types.LOGOUT_SUCCESS, payload: null })
}
