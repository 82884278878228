import React, { useContext } from 'react'
import { UserContext } from './UserContext'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
} from 'reactstrap'

export default function UserInfo({ formik }) {
  const ctx = useContext(UserContext)

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Informações Gerais
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          {ctx.user && (
            <Col md='3'>
              <div className='text-center'>
                {ctx.uploadingImage ? (
                  <Spinner
                    color='primary'
                    style={{ width: 128, height: 128 }}
                  />
                ) : (
                  <img
                    alt='Foto'
                    src={ctx.user.imageUrl + `?t=${Date.now()}`}
                    className='rounded-circle img-responsive mt-2'
                    width='128'
                    height='128'
                  />
                )}

                <div className='mt-2'>
                  <label htmlFor='files' className='btn btn-primary'>
                    Subir Foto
                  </label>
                  <input
                    id='files'
                    type='file'
                    name='file'
                    accept='image/*'
                    style={{ display: 'none' }}
                    onChange={e => ctx.uploadUserImage(e.target.files[0])}
                  />
                </div>
              </div>
            </Col>
          )}
          <Col md='9'>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor='firstname'>Nome</Label>
                  <Input
                    type='text'
                    id='firstname'
                    name='firstname'
                    placeholder='Nome'
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.firstname && !!formik.errors.firstname
                    }
                  />
                  <FormFeedback>{formik.errors.firstname}</FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor='surname'>Sobrenome</Label>
                  <Input
                    type='text'
                    id='surname'
                    name='surname'
                    placeholder='Sobrenome'
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.surname && !!formik.errors.surname}
                  />
                  <FormFeedback>{formik.errors.surname}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor='dob'>Data de Nascimento</Label>
                  <Input
                    type='date'
                    id='dob'
                    name='dob'
                    placeholder='Nascimento'
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.dob && !!formik.errors.dob}
                  />
                  <FormFeedback>{formik.errors.dob}</FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor='gender'>Gênero</Label>
                  <Input
                    type='select'
                    id='gender'
                    name='gender'
                    placeholder='Gênero'
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.gender && !!formik.errors.gender}
                  >
                    <option value='M'>Masculino</option>
                    <option value='F'>Feminino</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
