import React, { useContext } from 'react'
import { RoomTypeContext, RoomTypeProvider } from './RoomTypeContext'
import { useFormik } from 'formik'
import { weekdays } from '../../../util'
import CurrencyInput from 'react-currency-input-field'

import {
  Container,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Button,
} from 'reactstrap/lib'

export default function RoomTypeSettings({ match }) {
  const roomTypeId =
    match.params.roomTypeId === 'new' ? null : match.params.roomTypeId

  console.log(weekdays)

  return (
    <RoomTypeProvider roomTypeId={roomTypeId}>
      <RoomTypeLayout />
    </RoomTypeProvider>
  )
}

function RoomTypeLayout() {
  const { roomType, submit } = useContext(RoomTypeContext)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: roomType,
    onSubmit: values => submit(values),
  })

  return (
    <Container fluid className='p-0'>
      <Form onSubmit={formik.handleSubmit}>
        <div className='d-flex justify-content-between mb-2'>
          <h1 className='h3 mb-3'>Categoria de Quarto</h1>

          <div>
            <Button
              outline
              type='submit'
              color='primary'
              className='ml-2'
              // disabled={ctx.isLoading || ctx.isUpdating}
            >
              Salvar
            </Button>
          </div>
        </div>

        <RoomTypeInfo formik={formik} />
        <RoomTypeHourlyRates formik={formik} />
        <RoomTypeOvernightRates formik={formik} />
      </Form>
    </Container>
  )
}

function RoomTypeInfo({ formik }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Informações Gerais
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md='5'>
            <FormGroup>
              <Label htmlFor='title'>Título</Label>
              <Input
                type='text'
                id='title'
                name='title'
                placeholder='Título da Categoria'
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik.errors.title}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md='5'>
            <FormGroup>
              <Label htmlFor='allowance'>Tolerância</Label>
              <Input
                type='number'
                id='allowance'
                name='pricing.allowance'
                placeholder='Tolerância'
                value={formik.values.pricing.allowance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik.errors.title}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md='5'>
            <FormGroup>
              <Label htmlFor='description'>Descrição</Label>
              <Input
                type='textarea'
                id='description'
                name='description'
                placeholder='Descrição da Categoria (opcional)'
                style={{ resize: 'none' }}
                value={formik.values.description || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik?.errors.description}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

function RoomTypeHourlyRates({ formik }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Preço - Padrão
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          {Array.from({ length: 7 }).map((_, idx) => (
            <Col key={idx}>
              <FormGroup>
                <Label>Preço {weekdays[idx]}</Label>
                <CurrencyInput
                  className='form-control'
                  name={`pricing.hourly.rate[${idx + 1}]`}
                  placeholder='R$'
                  allowNegativeValue={false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  maxLength={10}
                  value={formik.values.pricing.hourly.rate[idx + 1]}
                  onValueChange={value => {
                    formik.setFieldValue(
                      `pricing.hourly.rate[${idx + 1}]`,
                      value
                    )
                  }}
                  onBlur={formik.handleBlur}
                  // invalid={
                  //   formik.touched.firstname && !!formik.errors.firstname
                  // }
                />
                <FormFeedback>{formik.errors.title}</FormFeedback>
              </FormGroup>
            </Col>
          ))}
        </Row>

        <Row>
          {Array.from({ length: 7 }).map((_, idx) => (
            <Col key={idx}>
              <FormGroup>
                <Label>Hora Adicional {weekdays[idx]}</Label>
                <CurrencyInput
                  className='form-control'
                  name={`pricing.hourly.extraTime[${idx + 1}]`}
                  placeholder={`R$ ${weekdays[idx]}`}
                  allowNegativeValue={false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  maxLength={10}
                  value={formik.values.pricing.hourly.extraTime[idx + 1]}
                  onValueChange={value => {
                    formik.setFieldValue(
                      `pricing.hourly.extraTime[${idx + 1}]`,
                      value
                    )
                  }}
                  onBlur={formik.handleBlur}
                />
                <FormFeedback>{formik.errors.title}</FormFeedback>
              </FormGroup>
            </Col>
          ))}
        </Row>

        <Row>
          <Col md='2'>
            <FormGroup>
              <Label>Hóspede Extra</Label>
              <CurrencyInput
                className='form-control'
                name='pricing.hourly.additionalGuestRate'
                placeholder={`R$`}
                allowNegativeValue={false}
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                maxLength={10}
                value={formik.values.pricing.hourly.additionalGuestRate}
                onValueChange={value => {
                  formik.setFieldValue(
                    'pricing.hourly.additionalGuestRate',
                    value
                  )
                }}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik.errors.title}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md='2'>
            <FormGroup>
              <Label>Tempo Inicial</Label>
              <Input
                type='number'
                name='pricing.hourly.initialTimeMinutes'
                placeholder='Tempo (minutos)'
                value={formik.values.pricing.hourly.initialTimeMinutes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik.errors.title}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

function RoomTypeOvernightRates({ formik }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Preço - Pernoite
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          {Array.from({ length: 7 }).map((_, idx) => (
            <Col key={idx}>
              <FormGroup>
                <Label>Preço {weekdays[idx]}</Label>
                <CurrencyInput
                  className='form-control'
                  name={`pricing.overnight.rate[${idx + 1}]`}
                  placeholder={`R$ ${weekdays[idx]}`}
                  allowNegativeValue={false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  maxLength={10}
                  value={formik.values.pricing.overnight.rate[idx + 1]}
                  onValueChange={value => {
                    formik.setFieldValue(
                      `pricing.overnight.rate[${idx + 1}]`,
                      value
                    )
                  }}
                  onBlur={formik.handleBlur}
                />
                <FormFeedback>{formik.errors.title}</FormFeedback>
              </FormGroup>
            </Col>
          ))}
        </Row>

        <Row>
          {Array.from({ length: 7 }).map((_, idx) => (
            <Col key={idx}>
              <FormGroup>
                <Label>Hora Adicional {weekdays[idx]}</Label>
                <CurrencyInput
                  className='form-control'
                  name={`pricing.overnight.extraTime[${idx + 1}]`}
                  placeholder={`R$ ${weekdays[idx]}`}
                  allowNegativeValue={false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  maxLength={10}
                  value={formik.values.pricing.overnight.extraTime[idx + 1]}
                  onValueChange={value => {
                    formik.setFieldValue(
                      `pricing.overnight.extraTime[${idx + 1}]`,
                      value
                    )
                  }}
                  onBlur={formik.handleBlur}
                />
                <FormFeedback>{formik.errors.title}</FormFeedback>
              </FormGroup>
            </Col>
          ))}
        </Row>

        <Row>
          <Col md='2'>
            <FormGroup>
              <Label>Hóspede Extra</Label>
              <CurrencyInput
                className='form-control'
                name='pricing.overnight.additionalGuestRate'
                placeholder={`R$`}
                allowNegativeValue={false}
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                maxLength={10}
                value={formik.values.pricing.overnight.additionalGuestRate}
                onValueChange={value => {
                  formik.setFieldValue(
                    'pricing.overnight.additionalGuestRate',
                    value
                  )
                }}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik.errors.title}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md='2'>
            <FormGroup>
              <Label>Tempo Inicial</Label>
              <Input
                type='number'
                name='pricing.overnight.initialTimeMinutes'
                placeholder='Tempo (minutos)'
                value={formik.values.pricing.overnight.initialTimeMinutes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormFeedback>{formik.errors.title}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
