import React, { useContext, useRef, useState } from 'react'
import { SocketContext } from 'api/SocketProvider'
import { CheckinContext, CheckinProvider } from './CheckinProvider'
import { useSelector } from 'react-redux'
import {
  Button,
  ButtonGroup,
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { Formik, Form, Field } from 'formik'

import Car from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'
import Pedestrian from '@material-ui/icons/DirectionsWalk'

export default function Checkin() {
  return (
    <CheckinProvider>
      <Layout />
    </CheckinProvider>
  )
}

function Layout() {
  const {
    vehicleQueue,
    setVehicleQueue,
    activeIndex,
    setActiveIndex,
    isAutomaticCheckin,
  } = useContext(CheckinContext)
  const userRole = useSelector(state => state.auth?.user?.role)

  return (
    <Modal
      isOpen={vehicleQueue.length > 0}
      fade={false}
      size='sm'
      backdrop='static'
      keyboard={false}
    >
      <ModalHeader
        style={{ justifyContent: 'center' }}
        toggle={
          isAutomaticCheckin &&
          userRole === 'Admin' &&
          (() => setVehicleQueue([]))
        }
      >
        <Nav pills style={{ justifyContent: 'center' }}>
          {vehicleQueue.map((vehicle, idx) => {
            return (
              <NavItem key={idx}>
                <NavLink
                  active={activeIndex === idx}
                  onClick={() => setActiveIndex(idx)}
                >
                  {vehicle.plate || 'Hóspede'}
                </NavLink>
              </NavItem>
            )
          })}
        </Nav>
      </ModalHeader>

      {vehicleQueue[activeIndex]?.mode === 'Checkin' ? (
        <CheckinForm />
      ) : (
        vehicleQueue[activeIndex]?.mode === 'Cancellation' && (
          <CancelationForm />
        )
      )}
    </Modal>
  )
}

function CheckinForm() {
  const { removeManualCheckins } = useContext(SocketContext)
  const {
    rooms,
    initialValues,
    isAutomaticCheckin,
    activeIndex,
    submit,
    searchVisits,
    setModeForActiveVehicle,
  } = useContext(CheckinContext)

  const [visits, setVisits] = useState(null)

  const validation = values => {
    const errors = {}

    if (
      (values.transportation === 'Carro' || values.transportation === 'Moto') &&
      !values.noPlate &&
      values.plate.length !== 7
    ) {
      errors.plate = 'Placa do veículo obrigatória'
    }

    if (
      (values.transportation === 'Carro' || values.transportation === 'Moto') &&
      !values.noPlate &&
      values.plate.length === 7 &&
      !/[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/.test(values.plate)
    ) {
      errors.plate = 'Placa do veículo mal formatada'
    }

    if (values.roomId === '') {
      errors.roomId = 'Quarto não escolhido'
    }

    return errors
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues(activeIndex)}
      validate={validation}
      onSubmit={submit}
    >
      {({ values, errors, isSubmitting, isValid, dirty, setFieldValue }) => (
        <Form>
          <ModalBody>
            <PlateImage />

            <div style={{ textAlign: 'center' }}>
              <ButtonGroup>
                <Button
                  color='primary'
                  outline={values.transportation !== 'Carro'}
                  onClick={() => setFieldValue('transportation', 'Carro')}
                >
                  <Car />
                </Button>

                <Button
                  color='primary'
                  outline={values.transportation !== 'Moto'}
                  onClick={() => setFieldValue('transportation', 'Moto')}
                >
                  <Motorcycle />
                </Button>

                <Button
                  color='primary'
                  outline={values.transportation !== 'Pedestre'}
                  onClick={() => {
                    setVisits(null)
                    setFieldValue('plate', '')
                    setFieldValue('noPlate', false)
                    setFieldValue('transportation', 'Pedestre')
                  }}
                  disabled={isAutomaticCheckin}
                >
                  <Pedestrian />
                </Button>
              </ButtonGroup>
            </div>

            <FormGroup style={{ marginTop: 20 }}>
              <Label>Identificação</Label>
              <InputGroup>
                <Field
                  as={Input}
                  type='text'
                  name='plate'
                  placeholder={
                    values.noPlate ? 'Veículo sem placa' : 'Placa do veículo'
                  }
                  value={values.plate}
                  onInput={e => {
                    e.target.value = ('' + e.target.value).toUpperCase()
                    if (e.target.value.length === 7)
                      searchVisits(e.target.value).then(visits =>
                        setVisits(visits)
                      )
                    else setVisits(null)
                  }}
                  valid={values.plate.length === 7 && !errors.plate}
                  invalid={values.plate.length === 7 && !!errors.plate}
                  maxLength={7}
                  disabled={
                    values.transportation === 'Pedestre' ||
                    isAutomaticCheckin ||
                    !!values.noPlate
                  }
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    {visits === null ? '-' : `${visits} visitas`}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

              <div style={{ marginTop: 10 }}>
                <Field
                  as={CustomInput}
                  type='checkbox'
                  id='noPlate'
                  name='noPlate'
                  label='Veículo sem placa'
                  onChange={e => {
                    const value = e.target.checked
                    setFieldValue('noPlate', value)
                    if (value) {
                      setFieldValue('plate', '')
                      setVisits(null)
                    }
                  }}
                  disabled={
                    values.transportation === 'Pedestre' || isAutomaticCheckin
                  }
                />
              </div>
            </FormGroup>

            <FormGroup style={{ marginTop: 20 }}>
              <Label>Quarto</Label>
              <Field
                as={Input}
                type='select'
                name='roomId'
                id='exampleCustomSelect'
                valid={rooms.map(r => r.roomId).includes(values.roomId)}
                invalid={!!errors.roomId}
              >
                <option value={''}>Escolha um quarto</option>
                {rooms
                  .filter(room => room.status === 'Disponível')
                  .map(room => (
                    <option key={room.roomId} value={room.roomId}>
                      {room.title} - {room.roomType.title}
                    </option>
                  ))}
              </Field>
            </FormGroup>

            <FormGroup>
              <Field
                as={CustomInput}
                type='checkbox'
                id='isOvernight'
                name='isOvernight'
                label='Pernoite'
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button
              color='secondary'
              type='button'
              onClick={() =>
                isAutomaticCheckin
                  ? setModeForActiveVehicle('Cancellation')
                  : removeManualCheckins()
              }
            >
              Cancelar
            </Button>

            <Button
              color='primary'
              type='submit'
              disabled={!dirty || !isValid || isSubmitting}
            >
              Cadastrar
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function CancelationForm() {
  const { setModeForActiveVehicle, cancelCheckin } = useContext(CheckinContext)

  const descriptionRef = useRef()
  const [cancelationReason, setCancelationReason] = useState(null)

  const changeReason = reason => {
    descriptionRef.current.value = ''
    setCancelationReason(reason)
  }

  return (
    <div>
      <ModalBody>
        <PlateImage />

        <FormGroup>
          <Label>
            <strong>Informe o motivo do cancelamento:</strong>
          </Label>
          <CustomInput
            type='radio'
            id='cancelationRadion1'
            name='cancelation'
            label='Captura errada'
            checked={cancelationReason === 'Captura errada'}
            onChange={_ => changeReason('Captura errada')}
          />
          <CustomInput
            type='radio'
            id='cancelationRadion2'
            name='cancelation'
            label='Desistência'
            checked={cancelationReason === 'Desistência'}
            onChange={_ => changeReason('Desistência')}
          />
          <CustomInput
            type='radio'
            id='cancelationRadion3'
            name='cancelation'
            label='Funcionário'
            checked={cancelationReason === 'Funcionário'}
            onChange={_ => changeReason('Funcionário')}
          />
          <CustomInput
            type='radio'
            id='cancelationRadion4'
            name='cancelation'
            label='Veículo de passageiros'
            checked={cancelationReason === 'Veículo de passageiros'}
            onChange={_ => changeReason('Veículo de passageiros')}
          />
          <CustomInput
            type='radio'
            id='cancelationRadion5'
            name='cancelation'
            label='Outro motivo'
            checked={cancelationReason === 'Outro motivo'}
            onChange={_ => changeReason('Outro motivo')}
          />
          <Input
            type='textarea'
            innerRef={descriptionRef}
            placeholder='Informe o motivo'
            disabled={cancelationReason !== 'Outro motivo'}
            style={{ marginTop: 12, resize: 'none' }}
          />
          <br />
          <div style={{ fontSize: 12 }}>
            <strong>Atenção:</strong> após realizar esta ação você não poderá
            desfazê-la.
          </div>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button
          color='secondary'
          type='button'
          onClick={() => setModeForActiveVehicle('Checkin')}
        >
          Voltar
        </Button>

        <Button
          color='primary'
          type='submit'
          onClick={() =>
            cancelCheckin(cancelationReason, descriptionRef.current.value)
          }
          //   disabled={!dirty || !isValid || isSubmitting}
        >
          Finalizar
        </Button>
      </ModalFooter>
    </div>
  )
}

function PlateImage() {
  const { vehicleQueue, activeIndex } = useContext(CheckinContext)
  if (!vehicleQueue[activeIndex]?.img64) {
    return <></>
  }

  return (
    <div style={{ flexDirection: 'row' }}>
      <div style={{ textAlign: 'center' }}>
        <img
          height='100'
          src={`data:image/gif;base64,${vehicleQueue[activeIndex]?.img64}`}
          alt=''
          style={{ borderRadius: 4, marginBottom: 20 }}
        />
      </div>
    </div>
  )
}
