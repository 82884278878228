import React, { createContext, useEffect, useState } from 'react'
import useGetApi from 'hooks/useGetApi'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import api from 'api'

export const ProductContext = createContext()

export function ProductProvider({ children, productId }) {
  const history = useHistory()
  const [productInfo, , , setProductInfo] = useGetApi(
    productId ? `/products/${productId}` : null
  )

  const [isSaving, setIsSaving] = useState(false)
  const [isUpdatingProductFlow, setIsUpdatingProductFlow] = useState(false)
  const [categories, setCategories] = useState([])

  const isNew = !productId

  useEffect(() => {
    if (!productId) {
      api.get('/products/categories').then(({ data }) => setCategories(data))
    }
  }, [productId])

  if (!productInfo && !isNew) return <div>Carregando...</div>

  const saveInfo = values => {
    if (values.category === 'new' && values.newCategory) {
      values.category = values.newCategory
    }

    if (isNew) createProduct(values)
    else updateProduct(values)
  }

  const createProduct = async values => {
    values.price = parseFloat(values.price.replace(',', '.'))

    setIsSaving(true)
    api
      .post('/products', values)
      .then(() => {
        setIsSaving(false)
        history.push('/admin/products')
      })
      .catch(e => {
        setIsSaving(false)
        toastr.error(e.response.data)
      })
  }

  const updateProduct = async values => {
    values.price = parseFloat(values.price.replace(',', '.'))

    setIsSaving(true)
    api
      .put(`/products/${productId}`, values)
      .then(() => {
        setIsSaving(false)
        history.push('/admin/products')
      })
      .catch(e => {
        setIsSaving(false)
        toastr.error(e.response.data)
      })
  }

  const updateProductFlow = (type, quantity) => {
    setIsUpdatingProductFlow(true)

    const data = {
      type,
      quantity: parseInt(quantity),
    }
    api
      .post(`/products/${productId}/productFlow`, data)
      .then(response => {
        setProductInfo(response.data)
        setIsUpdatingProductFlow(false)
      })
      .catch(e => {
        setIsUpdatingProductFlow(false)
        toastr.error(e.response.data)
      })
  }

  const deleteProduct = async () => {
    setIsSaving(true)
    api
      .delete(`/products/${productId}`)
      .then(() => {
        setIsSaving(false)
        history.push('/admin/products')
      })
      .catch(e => {
        setIsSaving(false)
        toastr.error(e.response.data)
      })
  }

  return (
    <ProductContext.Provider
      value={{
        productInfo,
        isNew,
        categories,
        isSaving,
        isUpdatingProductFlow,
        saveInfo,
        updateProductFlow,
        deleteProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
