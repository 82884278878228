import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import useGetApi from 'hooks/useGetApi'
import Loader from 'components/Loader'
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Table,
} from 'reactstrap'

export default function Users() {
  const [users, isLoading] = useGetApi('/admin/users')

  if (isLoading) return <Loader />

  return (
    <Container fluid className='p-0'>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='h3 mb-3'>Usuários</h1>

        <div>
          <Link to='/admin/users/new'>
            <Button outline color='primary' className='mt-n1 ml-2'>
              Novo Usuário
            </Button>
          </Link>
        </div>
      </div>

      <Row>
        <Col>
          <UserTable users={users} />
        </Col>
      </Row>
    </Container>
  )
}

function UserTable({ users }) {
  const history = useHistory()
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Usuários Cadastrados
        </CardTitle>
      </CardHeader>
      <Table className='mb-0' striped hover>
        <thead>
          <tr>
            <th></th>
            <th>Nome</th>
            <th>Função</th>
            <th>Usuário</th>
            <th>Email</th>
            <th>Telefone</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr
              key={user.userId}
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(`/admin/users/${user.userId}`)}
            >
              <td>
                <img
                  src={user.imageUrl}
                  width='56'
                  height='56'
                  className='rounded-circle my-n1'
                  alt='Avatar'
                />
              </td>
              <td>{[user.firstname, user.surname].join(' ')}</td>
              <td>{user.userRole.role}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>
                {user.phone?.replace(/^(\d{2})(\d{5})(\d{4})$/g, '($1) $2-$3')}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
