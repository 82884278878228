import * as types from '../constants'

export const setChatUsers = users => dispatch => {
  dispatch({ type: types.CHAT_USERS, payload: users })
}

export const updateChatUserOnline = user => dispatch => {
  dispatch({ type: types.CHAT_UPDATE_USER_ONLINE, payload: user })
}

export const updateChatUnreadMessageCount = message => dispatch => {
  dispatch({ type: types.CHAT_UPDATE_UNREAD_MESSAGES, payload: message })
}

export const clearUnreadMessageCount = userId => dispatch => {
  dispatch({ type: types.CHAT_CLEAR_UNREAD_MESSAGES_COUNT, payload: userId })
}

export const chatLogout = () => dispatch => {
  dispatch({ type: types.CHAT_LOGOUT, payload: null })
}
