import React, { createContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useGetApi from 'hooks/useGetApi'
import api from 'api'
import { toastr } from 'react-redux-toastr'
import { isElectron } from 'util/electron-util'
import { saleInvoiceData } from 'services/printer/product-sale-invoice'
import Loader from 'components/Loader'

const ProductSaleContext = createContext()

function ProductSaleProvider({ children, reservationId, onSave }) {
  const [products, isLoadingProducts] = useGetApi('/products')

  const printer = useSelector(state => state.printer)

  const [cartProducts, setCartProducts] = useState([])
  const [isSavingProducts, setIsSavingProducts] = useState(false)
  const [payments, setPayments] = useState([])

  const paymentMatchCart = useMemo(() => {
    const productsTotal = cartProducts.reduce(
      (acc, item) => acc + item.subtotal,
      0
    )
    const paymentsTotal = payments.reduce((acc, item) => acc + item.amount, 0)
    return (
      productsTotal === paymentsTotal &&
      productsTotal !== 0 &&
      paymentsTotal !== 0
    )
  }, [cartProducts, payments])

  // Room Sale --------------------------------------------------
  const addToCart = productId => {
    const product = products.find(product => product.productId === productId)
    const currentCartProduct = cartProducts.find(
      product => product.productId === productId
    )

    if (currentCartProduct) {
      setCartProducts(oldValues =>
        oldValues.map(item =>
          item.productId === productId
            ? {
                ...item,
                quantity: item.quantity + 1,
                subtotal: item.unitPrice * (item.quantity + 1),
              }
            : item
        )
      )
    } else {
      const cartProduct = {
        productId,
        title: product.title,
        quantity: 1,
        unitPrice: parseFloat(product.price),
        subtotal: parseFloat(product.price),
      }
      setCartProducts(oldValues => [...oldValues, cartProduct])
    }
  }

  const removeFromCart = productId => {
    const currentCartProduct = cartProducts.find(
      product => product.productId === productId
    )

    if (!currentCartProduct) return

    if (currentCartProduct.quantity > 1) {
      setCartProducts(oldValues =>
        oldValues.map(item =>
          item.productId === productId
            ? {
                ...item,
                quantity: item.quantity - 1,
                subtotal: item.unitPrice * (item.quantity - 1),
              }
            : item
        )
      )
    } else {
      deleteProductFromCart(productId)
    }
  }

  const deleteProductFromCart = productId => {
    setCartProducts(oldValues =>
      oldValues.filter(item => item.productId !== productId)
    )
  }

  const saveProducts = () => {
    if (reservationId) performReservationSale()
    else performCounterSale()
  }

  const performReservationSale = () => {
    if (cartProducts.length === 0) return
    setIsSavingProducts(true)

    const products = cartProducts.map(item => ({
      productId: item.productId,
      quantity: item.quantity,
    }))

    api
      .post(`/reservations/${reservationId}/products`, products)
      .then(() => {
        setIsSavingProducts(false)
        onSave()
      })
      .catch(err => {
        setIsSavingProducts(false)
        toastr.error(err.response?.data)
      })
  }
  // ============================================================

  // Counter Sale: uses payments, while room sale does not  -----
  const addPayment = (method, amount) => {
    setPayments(oldValue => [
      ...oldValue,
      { method, amount: parseFloat(amount) },
    ])
  }

  const removePayment = (method, amount) => {
    setPayments(oldValue =>
      oldValue.filter(
        value => value.method !== method && value.amount !== parseFloat(amount)
      )
    )
  }

  const performCounterSale = () => {
    const productsTotal = cartProducts.reduce(
      (acc, item) => acc + item.subtotal,
      0
    )
    const paymentsTotal = payments.reduce((acc, item) => acc + item.amount, 0)

    if (paymentsTotal < productsTotal) {
      toastr.error('Não é possível realizar venda com pagamento a menor')
      return
    }

    const data = {
      products: cartProducts,
      payments,
    }

    setIsSavingProducts(true)
    api
      .post('/sale', data)
      .then(() => {
        setIsSavingProducts(false)
        setCartProducts([])
        setPayments([])
        printInvoice(cartProducts, payments)
        toastr.success('Venda realizada com sucesso')
      })
      .catch(e => {
        setIsSavingProducts(false)
        toastr.error(e.response.data)
      })
  }
  // ============================================================

  const printInvoice = (products, payments) => {
    if (isElectron) {
      const { ipcRenderer } = window.require('electron')
      const invoiceData = saleInvoiceData(products, payments)
      ipcRenderer.send(
        'print',
        JSON.stringify({ printer, content: invoiceData })
      )
    }
  }

  if (isLoadingProducts) {
    return <Loader />
  }

  return (
    <ProductSaleContext.Provider
      value={{
        reservationId,
        products,
        cartProducts,
        payments,
        isLoadingProducts,
        isSavingProducts,
        paymentMatchCart,
        addToCart,
        removeFromCart,
        deleteProductFromCart,
        saveProducts,
        addPayment,
        removePayment,
      }}
    >
      {children}
    </ProductSaleContext.Provider>
  )
}

export { ProductSaleContext, ProductSaleProvider }
