import React from 'react'

import { Container, Row, Col } from 'reactstrap'

import HomeProvider from './HomeContext'
import Header from './Header'
import Statistics from './Statistics'
import ClientsLine from './ClientsLine'
import BulletinBoard from './BulletinBoard'

export default function Home() {
  return (
    <HomeProvider>
      <Container fluid className='p-0'>
        <Header />
        <Statistics />
        <Row>
          <Col lg='8'>
            <ClientsLine />
          </Col>
          <Col lg='4'>
            <BulletinBoard />
          </Col>
        </Row>
      </Container>
    </HomeProvider>
  )
}
