import React, { useState } from 'react'
import api from 'api'
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

export default function RoomStatusModal({
  room,
  showStatusModal,
  setShowStatusModal,
}) {
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [statusMessage, setStatusMessage] = useState(null)

  const close = () => setShowStatusModal(false)

  const changeRoomStatus = () => {
    if (!selectedStatus) return close()

    api
      .put(`/rooms/${room.roomId}/status`, {
        status: selectedStatus,
        statusMessage,
      })
      .then(() => {
        setSelectedStatus(null)
        setStatusMessage(null)
        close()
      })
  }

  return (
    <Modal isOpen={showStatusModal} toggle={close} size='sm'>
      <ModalHeader toggle={close}>
        {room?.abbrev} - {room?.title}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for='exampleCheckbox'>Selecione o status:</Label>
            <div>
              <CustomInput
                type='radio'
                id='exampleCustomRadio'
                name='status'
                label='Disponível'
                defaultChecked={room?.status === 'Disponível'}
                onChange={_ => setSelectedStatus('Disponível')}
              />
              <CustomInput
                type='radio'
                id='exampleCustomRadio2'
                name='status'
                label='Limpeza'
                defaultChecked={room?.status === 'Limpeza'}
                onChange={_ => setSelectedStatus('Limpeza')}
              />
              <CustomInput
                type='radio'
                id='exampleCustomRadio3'
                name='status'
                label='Manutenção'
                defaultChecked={room?.status === 'Manutenção'}
                onChange={_ => setSelectedStatus('Manutenção')}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <legend className='col-form-label'>Descrição (opcional)</legend>
            <Input
              type='textarea'
              rows={3}
              placeholder='Descrição'
              defaultValue={room?.metadata?.statusMessage}
              onChange={e => setStatusMessage(e.target.value)}
              style={{ resize: 'none' }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={close}>
          Fechar
        </Button>
        <Button color='primary' onClick={changeRoomStatus}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
