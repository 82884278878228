const moment = require('moment')

export function fullRoomRates(room, reservation, showDetailed = false) {
  const isInvoiceClosed = !!reservation.checkout

  let hospitalityItems = []
  let productItems = []
  let discountItems = []

  if (isInvoiceClosed) {
    // Rates closed
    const hospitality = reservation.invoice.invoiceItems.find(
      elem =>
        elem.type === 'Hospedagem' && elem.item === 'Hospedagem - Principal'
    )

    const additionalHours = reservation.invoice.invoiceItems
      .filter(
        elem =>
          elem.type === 'Hospedagem' &&
          /Hospedagem.+Hora Adicional/.test(elem.item)
      )
      .sort((a, b) => new Date(a.date) - new Date(b.date))
    const products = reservation.invoice.invoiceItems
      .filter(elem => elem.type === 'Consumo')
      .sort((a, b) => new Date(a.date) - new Date(b.date))
    const discounts = reservation.invoice.invoiceItems
      .filter(elem => elem.type === 'Desconto')
      .sort((a, b) => new Date(a.date) - new Date(b.date))

    hospitalityItems = [hospitality, ...additionalHours].map(item => ({
      ...item,
      price: parseFloat(item.price),
      date: moment(item.date).format('DD/MM/YY HH:mm'),
    }))

    productItems = products.map(item => ({
      ...item,
      price: parseFloat(item.price),
      date: moment(item.date).format('DD/MM/YY HH:mm'),
    }))

    discountItems = discounts.map(item => ({
      ...item,
      price: parseFloat(item.price),
      date: moment(item.date).format('DD/MM/YY HH:mm'),
    }))
  } else {
    // Rates still rolling
    const rate = calculateRoomRate(room, reservation)
    hospitalityItems = hospitalityInvoiceItems(
      rate.rateBreakdown,
      showDetailed,
      'DD/MM/YY HH:mm'
    )

    productItems = reservation.invoice.invoiceItems
      .filter(elem => elem.type === 'Consumo')
      .map(product => ({
        ...product,
        price: parseFloat(product.price),
        date: moment(product.date).format('DD/MM/YY HH:mm'),
      }))

    discountItems = reservation.invoice.invoiceItems
      .filter(elem => elem.type === 'Desconto')
      .map(discount => ({
        ...discount,
        price: parseFloat(discount.price),
        date: moment(discount.date).format('DD/MM/YY HH:mm'),
      }))
  }

  const hospitalityTotal = hospitalityItems.reduce(
    (acc, item) => acc + item.price,
    0
  )
  const productsTotal = productItems.reduce((acc, item) => acc + item.price, 0)
  const discountsTotal = discountItems.reduce(
    (acc, item) => acc + item.price,
    0
  )
  const total = hospitalityTotal + productsTotal + discountsTotal

  return {
    hospitalityItems,
    productItems,
    discountItems,
    hospitalityTotal,
    productsTotal,
    total,
  }
}

export function calculateRoomRate(room, reservation) {
  if (!room || !room.roomType || !room.roomType.pricing) return null

  const checkin = moment(reservation.checkin)
  // const extras = room.Reservation.additionalGuests
  const isOvernight = reservation.isOvernight

  const currentDurationInMinutes = moment(Date.now()).diff(checkin, 'minutes')

  const pricing = room.roomType.pricing[isOvernight ? 'overnight' : 'hourly']

  const todaysWeekday = moment().isoWeekday()
  const todaysPricing = pricing.rate[todaysWeekday]

  const allowanceMax =
    pricing.initialTimeMinutes + room.roomType.pricing.allowance

  let rateBreakdown = [
    {
      date: checkin,
      rate: todaysPricing,
    },
  ]

  if (currentDurationInMinutes > allowanceMax) {
    // Guest needs to pay for extra time
    const exceedingMinutes = currentDurationInMinutes - allowanceMax
    const exceedingHours = Math.ceil(exceedingMinutes / 60)

    for (let i = 0; i < exceedingHours; i++) {
      const newDate = checkin.clone().add(allowanceMax + i * 60, 'minutes')
      const newDateWeekday = newDate.isoWeekday()
      const newDatePricing = pricing.extraTime[newDateWeekday]

      rateBreakdown.push({
        date: newDate,
        rate: newDatePricing,
      })
    }
  }

  const total = rateBreakdown.map(rb => rb.rate).reduce((a, b) => a + b, 0)
  return { rateBreakdown, total }
}

export function hospitalityInvoiceItems(
  rateBreakdown,
  showDetailed,
  dateFormat = 'YYYY/MM/DD HH:mm:ss'
) {
  let rateItems = []

  if (showDetailed) {
    rateItems = rateBreakdown.map((item, idx) => ({
      itemId: idx,
      type: 'Hospitalidade',
      date: item.date.format(dateFormat),
      item: idx === 0 ? 'Hospedagem' : `${idx}ª Hora Adicional`,
      quantity: 1,
      price: item.rate,
    }))
  } else {
    const [hospitality, ...additionalHours] = rateBreakdown
    rateItems.push({
      itemId: 0,
      type: 'Hospitalidade',
      date: hospitality.date.format(dateFormat),
      item: 'Hospedagem',
      quantity: 1,
      price: hospitality.rate,
    })
    if (additionalHours.length > 0) {
      rateItems.push({
        itemId: 1,
        type: 'Hospitalidade',
        date: additionalHours[additionalHours.length - 1].date.format(
          dateFormat
        ),
        item: 'Horas Adicionais',
        quantity: additionalHours.length,
        price: additionalHours.map(i => i.rate).reduce((a, b) => a + b, 0),
      })
    }
  }
  return rateItems
}

// export function productInvoiceItems(
//   products,
//   showDetailed,
//   dateFormat = 'YYYY/MM/DD HH:mm:ss'
// ) {
//   let rateItems = []

//   if (showDetailed) {
//     rateItems = products.map((item, idx) => ({
//       itemId: idx,
//       type: 'Consumo',
//       date: item.date.format(dateFormat),
//       item: item.item,
//       quantity: 1,
//       price: item.rate,
//     }))
//   } else {
//   }

//   return rateItems
// }
