import api from '../api'
import { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'

export default function useGetApi(route, dependencies = []) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchInfo = () => {
    if (route === null) {
      setIsLoading(false)
      return
    }

    api
      .get(route)
      .then(response => {
        const data = response.data
        setData(data)
        setIsLoading(false)
      })
      .catch(e => {
        setError(e)
        toastr.error(e.response?.data || 'Erro')
        setIsLoading(false)
      })
  }

  // eslint-disable-next-line
  useEffect(() => fetchInfo(), dependencies)

  return [data, isLoading, error, setData]
}
