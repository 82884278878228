import React from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

export default function UserPermissions({ formik }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Permissões
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md='4'>
            <FormGroup>
              <Label htmlFor='role'>Função</Label>
              <Input
                type='select'
                id='role'
                name='role'
                placeholder='Nome de Usuário'
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option>Admin</option>
                <option>Estoque</option>
                <option>Governança</option>
                <option>Recepção</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
