import React from 'react'
import { useHistory, useLocation } from 'react-router'
import ArrowBack from '@material-ui/icons/ArrowBack'

export default function GoBack() {
  const history = useHistory()
  const location = useLocation()
  const returns = new URLSearchParams(location.search).get('returns')

  if (!returns) return <></>

  return (
    <ArrowBack
      className='text-primary mr-1'
      style={{ cursor: 'pointer' }}
      onClick={() => history.push(returns)}
    />
  )
}
