import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useSessionCheck from 'hooks/sessionCheck'
import { Badge, Card, Col, ListGroupItem, Media, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import ChatMessageList from './ChatMessageList'
import useWindowDimensions from 'hooks/useWindowDimentions'

export default function Chat() {
  const { users } = useSelector(state => state.chat)
  const [selectedUser, setSelectedUser] = useState(null)
  const { height } = useWindowDimensions()

  useSessionCheck()

  return (
    <Card>
      <Row noGutters>
        <Col
          lg={5}
          xl={3}
          className='border-right'
          style={{ overflowY: 'scroll', height: height - 207 }}
        >
          {users?.map(user => (
            <ChatUser
              key={user.userId}
              user={user}
              isSelected={user.userId === selectedUser?.userId}
              setSelectedUser={setSelectedUser}
            />
          ))}

          <hr className='d-block d-lg-none mt-1 mb-0' />
        </Col>

        <Col lg={7} xl={9}>
          {selectedUser && (
            <ChatMessageList
              key={selectedUser.userId}
              addressed={selectedUser}
            />
          )}
        </Col>
      </Row>
    </Card>
  )
}

const ChatUser = ({ user, isSelected, setSelectedUser }) => (
  <ListGroupItem
    action
    tag='a'
    className='border-0'
    style={{ backgroundColor: isSelected ? '#f6f6f6' : 'transparent' }}
    onClick={() => setSelectedUser(user)}
  >
    <Badge color='success' className='float-right'>
      {user.newMessagesCount > 0 ? user.newMessagesCount : null}
    </Badge>
    <Media>
      <img
        src={user.imageUrl}
        className='rounded-circle mr-1'
        alt=''
        width='40'
        height='40'
      />
      <Media body className='ml-3'>
        {`${user.firstname} ${user.surname}`}
        <div className='small'>
          {user.online ? (
            <FontAwesomeIcon icon={faCircle} className='chat-online mr-1' />
          ) : (
            <FontAwesomeIcon icon={faCircle} className='chat-offline mr-1' />
          )}
          {user.companies.length > 1
            ? 'Várias empresas'
            : user.companies[0].name}
        </div>
      </Media>
    </Media>
  </ListGroupItem>
)
