import React, { useContext, useRef } from 'react'
import moment from 'moment'
import { HomeContext } from './HomeContext'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Media,
} from 'reactstrap'

const Feed = () => {
  const {
    summary: { bulletinBoard },
    isSendingBulletinContent,
    sendBulletingContent,
  } = useContext(HomeContext)

  const contentRef = useRef()

  const onSubmit = e => {
    e.preventDefault()
    const content = contentRef.current.value
    sendBulletingContent(content.trim()).then(success => {
      if (success) contentRef.current.value = ''
    })
  }

  return (
    <Card className='flex-fill w-100'>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Quadro de Avisos
        </CardTitle>
      </CardHeader>
      <CardBody>
        {bulletinBoard.length > 0 ? (
          bulletinBoard.map(item => <FeedItem key={item.itemId} item={item} />)
        ) : (
          <div className='py-5' style={{ textAlign: 'center' }}>
            Nenhum aviso
          </div>
        )}

        {bulletinBoard.length >= 3 ? (
          <Button block color='primary'>
            Carregar mais
          </Button>
        ) : null}

        <Form className='py-3 border-top' onSubmit={onSubmit}>
          <InputGroup>
            <Input
              innerRef={contentRef}
              type='textarea'
              placeholder='Digite um aviso aqui'
              style={{ resize: 'none' }}
              disabled={isSendingBulletinContent}
            />
            <InputGroupAddon addonType='append'>
              <Button type='submit' color='primary'>
                Enviar
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </CardBody>
    </Card>
  )
}

function FeedItem({ item }) {
  return (
    <>
      <Media>
        <img
          src={item.user?.imageUrl}
          width='36'
          height='36'
          className='rounded-circle mr-2'
          alt=''
        />
        <Media body>
          <strong>{item.user?.firstname}</strong>
          <br />
          {item.content}
          <br />
          <small className='text-muted'>
            {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
          </small>
        </Media>
      </Media>

      <hr />
    </>
  )
}

export default Feed
