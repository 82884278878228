import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CustomInput } from 'reactstrap'
import { updateCurrentCompany } from 'redux/actions/authActions'

const CompanySelection = ({ history }) => {
  const dispatch = useDispatch()
  const { user, currentCompany } = useSelector(state => state.auth)

  return (
    <div>
      <div className='text-center'>
        {/* <h1 className='h2'>Empresas</h1> */}
        <p className='lead'>Selecione a empresa:</p>
      </div>

      <Card>
        <CardBody>
          <div className='m-sm-2'>
            {user.companies.map(company => (
              <CustomInput
                key={company.companyId}
                id={company.companyId}
                type='radio'
                name='status'
                label={company.name}
                defaultChecked={currentCompany.companyId === company.companyId}
                onChange={_ => dispatch(updateCurrentCompany(company))}
              />
            ))}
          </div>
        </CardBody>
      </Card>

      <div className='text-center mt-2'>
        <Button
          color='primary'
          disabled={!currentCompany.companyId}
          onClick={() => history.push('/home')}
        >
          Continuar
        </Button>
      </div>
    </div>
  )
}

export default CompanySelection
