import * as types from '../constants'

const initialState = {
  showAutomaticVehicleCaptures:
    localStorage.getItem('@showAutomaticVehicleCaptures') === 'true',
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.AUTOMATIC_VEHICLE_CAPTURE:
      const newValue = !state.showAutomaticVehicleCaptures
      localStorage.setItem('@showAutomaticVehicleCaptures', newValue.toString())
      return {
        ...state,
        showAutomaticVehicleCaptures: newValue,
      }
    default:
      return state
  }
}
