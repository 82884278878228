import React from 'react'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default function ConfirmationDialog({
  isOpen,
  setIsOpen,
  title,
  body,
  onConfirm,
  onCancel,
  confirmTitle = 'Confirmar',
  cancelTitle = 'Cancelar',
  showCancelButton = true,
  confirmDisabled = false,
  cancelDisabled = false,
  centered = false,
}) {
  const toggle = () => setIsOpen(!isOpen)

  const cancel = () => {
    if (onCancel) onCancel()
    toggle()
  }

  const confirm = () => {
    if (onConfirm) onConfirm()
    toggle()
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='sm'
      backdrop='static'
      keyboard={false}
      centered={centered}
    >
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>{body}</ModalBody>

      <ModalFooter>
        {showCancelButton && (
          <Button color='secondary' onClick={cancel} disabled={cancelDisabled}>
            {cancelTitle}
          </Button>
        )}

        <Button color='primary' onClick={confirm} disabled={confirmDisabled}>
          {confirmTitle}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
