import React, { useContext } from 'react'
import moment from 'moment'
import { Context } from './ShiftContext'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { Clock, Activity } from 'react-feather'

export const Details = () => {
  const ctx = useContext(Context)

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Detalhes
        </CardTitle>
      </CardHeader>

      <hr className='my-0' />

      <CardBody>
        <ul className='list-unstyled mb-0'>
          <li
            className={`mb-1 ${
              ctx.shift.endDate ? 'text-danger' : 'text-success'
            }`}
          >
            <Activity width={14} height={14} className='mr-1' />{' '}
            <strong>
              {ctx.shift.endDate ? 'Turno Fechado' : 'Turno Aberto'}
            </strong>
          </li>

          <li className='mb-1'>
            <Clock
              width={14}
              height={14}
              className='mr-1'
              style={{ transform: 'scaleX(-1)' }}
            />{' '}
            Aberto em{' '}
            <strong>
              {moment(ctx.shift.startDate).format('DD/MM/YY HH:mm')}
            </strong>
          </li>

          {ctx.shift.endDate && (
            <li className='mb-1'>
              <Clock width={14} height={14} className='mr-1' /> Fechado em{' '}
              <strong>
                {moment(ctx.shift.endDate).format('DD/MM/YY HH:mm')}
              </strong>
            </li>
          )}
        </ul>
      </CardBody>

      <hr className='my-0' />

      {/* <CardBody>
        <ul className='list-unstyled mb-0'>
          <li className='mb-1'>
            <Archive width={14} height={14} className='mr-1' />
            Caixa: {stringToCurrency(400)}
          </li>
        </ul>
      </CardBody> */}
    </Card>
  )
}
