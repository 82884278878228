import React, { useContext, useEffect, useState } from 'react'
import { ProductSaleContext, ProductSaleProvider } from './ProductSaleContext'
import { stringToCurrency } from 'util/currency-util'
import CurrencyInput from 'react-currency-input-field'
import { PlusCircle, MinusCircle, Trash } from 'react-feather'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from 'reactstrap'

export const ProductSale = ({ reservationId, onSave }) => {
  return (
    <ProductSaleProvider reservationId={reservationId} onSave={onSave}>
      <Container fluid className='p-0'>
        <Row>
          <Col md='7'>
            <ProductPicker />
          </Col>
          <Col md='5'>
            <ProductCart showSaveButton={!!reservationId} />
            {!reservationId && <Payment />}
          </Col>
        </Row>
      </Container>
    </ProductSaleProvider>
  )
}

const ProductPicker = () => {
  const ctx = useContext(ProductSaleContext)

  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    setFilteredProducts(ctx.products || [])
  }, [ctx.products])

  const filterProducts = e => {
    const searchString = e.target.value
    if (searchString && ctx.products) {
      setFilteredProducts(
        ctx.products.filter(product =>
          product.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(searchString.toLowerCase())
        )
      )
    } else {
      setFilteredProducts(ctx.products || [])
    }
  }

  return (
    <Card>
      <Col className='mt-3 mb-2'>
        <Input
          type='text'
          placeholder='Pesquisar...'
          aria-label='Search'
          onChange={filterProducts}
        />
      </Col>

      <Table>
        <thead>
          <tr>
            <th>Produto</th>
            <th style={{ width: '10%' }}>Preço</th>
            <th style={{ width: 120 }}></th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map(product => (
            <tr key={product.productId}>
              <td>{product.title}</td>
              <td>{stringToCurrency(product.price)}</td>
              <td className='text-right'>
                <button
                  className='btn btn-icon btn-light btn-sm'
                  onClick={() => ctx.removeFromCart(product.productId)}
                >
                  <MinusCircle className='align-middle' size={18} />
                </button>
                <button
                  className='btn btn-icon btn-light btn-sm'
                  onClick={() => ctx.addToCart(product.productId)}
                >
                  <PlusCircle className='align-middle' size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}

const ProductCart = ({ showSaveButton }) => {
  const ctx = useContext(ProductSaleContext)

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Carrinho
        </CardTitle>
      </CardHeader>
      <Table>
        <thead>
          <tr>
            <th>Produto</th>
            <th className='text-center' style={{ width: '10%' }}>
              Qtd
            </th>
            <th style={{ width: '15%' }}>Subtotal</th>
            <th style={{ width: '15%' }}></th>
          </tr>
        </thead>
        <tbody>
          {ctx.cartProducts.map(product => (
            <tr key={product.productId}>
              <td>{product.title}</td>
              <td className='text-center'>{product.quantity}</td>
              <td>{stringToCurrency(product.subtotal)}</td>
              <td className='text-right'>
                <button
                  className='btn btn-icon btn-light btn-sm'
                  onClick={() => ctx.deleteProductFromCart(product.productId)}
                >
                  <Trash className='align-middle' size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <strong>TOTAL</strong>
            </td>
            <td></td>
            <td>
              <strong>
                {stringToCurrency(
                  ctx.cartProducts.reduce((acc, item) => acc + item.subtotal, 0)
                )}
              </strong>
            </td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
      {showSaveButton && (
        <Button
          className='mt-4'
          color='primary'
          disabled={ctx.isSavingProducts}
          onClick={ctx.saveProducts}
        >
          Finalizar
        </Button>
      )}
    </Card>
  )
}

const Payment = () => {
  const ctx = useContext(ProductSaleContext)

  const [method, setMethod] = useState('Dinheiro')
  const [amount, setAmount] = useState('')

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Pagamento
        </CardTitle>
      </CardHeader>

      <CardBody>
        <Row>
          <Col>
            <Input type='select' onChange={e => setMethod(e.target.value)}>
              <option>Dinheiro</option>
              <option>Debito</option>
              <option>Credito</option>
              <option>PIX</option>
              <option>Cheque</option>
            </Input>
          </Col>

          <Col>
            <CurrencyInput
              className='form-control'
              name='amountinput'
              placeholder='R$'
              allowNegativeValue={false}
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              maxLength={10}
              value={amount}
              onValueChange={value => setAmount(value)}
            />
          </Col>

          <Col>
            <Button
              color='primary'
              onClick={() => ctx.addPayment(method, amount)}
              disabled={
                !method ||
                !amount ||
                isNaN(parseFloat(amount)) ||
                amount <= 0 ||
                ctx.isSavingPayment
              }
            >
              Adicionar
            </Button>
          </Col>
        </Row>
      </CardBody>

      <Table>
        <thead>
          <tr>
            <th style={{ width: 200 }}>Forma</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ctx.payments.map((payment, idx) => (
            <tr key={idx}>
              <td>{payment.method}</td>
              <td>{stringToCurrency(payment.amount)}</td>
              <td className='text-right pr-3' style={{ minWidth: 90 }}>
                <button
                  className='btn btn-icon btn-light btn-sm'
                  disabled={ctx.isDeletingPayment}
                  onClick={() =>
                    ctx.removePayment(payment.method, payment.amount)
                  }
                >
                  <Trash className='align-middle' size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <strong>TOTAL</strong>
            </td>
            <td></td>
            <td>
              <strong>
                {stringToCurrency(
                  ctx.cartProducts.reduce((acc, item) => acc + item.subtotal, 0)
                )}
              </strong>
            </td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
      <Button
        className='mt-4'
        color='primary'
        disabled={ctx.isSavingProducts || !ctx.paymentMatchCart}
        onClick={ctx.saveProducts}
      >
        Finalizar
      </Button>
    </Card>
  )
}
