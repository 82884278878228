import * as types from '../constants'

const initialState = {
  token: sessionStorage.getItem('@token'),
  user: sessionStorage.hasOwnProperty('@user')
    ? JSON.parse(sessionStorage.getItem('@user'))
    : null,
  currentCompany: sessionStorage.hasOwnProperty('@currentCompany')
    ? JSON.parse(sessionStorage.getItem('@currentCompany'))
    : sessionStorage.hasOwnProperty('@user')
    ? JSON.parse(sessionStorage.getItem('@user')).companies[0]
    : null,
  isAuthenticated:
    !!sessionStorage.hasOwnProperty('@token') &&
    !!sessionStorage.hasOwnProperty('@user'),
  isLoading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.USER_LOADING:
      return { ...state, isLoading: true }
    case types.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload.user,
        currentCompany: action.payload.user.companies[0],
        error: null,
      }
    case types.LOGIN_SUCCESS:
    case types.REGISTER_SUCCESS:
      sessionStorage.setItem('@token', action.payload.token)
      sessionStorage.setItem('@user', JSON.stringify(action.payload.user))
      return {
        token: action.payload.token,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload.user,
        currentCompany: action.payload.user.companies[0],
        error: null,
      }
    case types.AUTH_ERROR:
    case types.LOGIN_FAIL:
    case types.REGISTER_FAIL:
    case types.LOGOUT_SUCCESS:
      sessionStorage.removeItem('@token')
      sessionStorage.removeItem('@user')
      return {
        token: null,
        user: null,
        currentCompany: null,
        isAuthenticated: false,
        isLoading: false,
        error: action.payload,
      }
    case types.UPDATE_USER_INFO:
      sessionStorage.setItem('@user', JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload,
      }
    case types.UPDATE_USER_IMAGE:
      const user = {
        ...state.user,
        imageUrl: action.payload,
      }
      sessionStorage.setItem('@user', JSON.stringify(user))
      return {
        ...state,
        user,
      }
    case types.UPDATE_CURRENT_COMPANY:
      sessionStorage.setItem('@currentCompany', JSON.stringify(action.payload))
      return {
        ...state,
        currentCompany: action.payload,
      }
    default:
      return state
  }
}
