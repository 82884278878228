import React, { useContext } from 'react'
import { UserContext } from './UserContext'

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap'

export default function UserAccount({ formik }) {
  const ctx = useContext(UserContext)

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Informações de Conta
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor='username'>Nome de Usuário</Label>
              <Input
                type='text'
                id='username'
                name='username'
                placeholder='Nome de Usuário'
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!ctx.isNew}
                invalid={formik.touched.username && !!formik.errors.username}
              />
              <FormFeedback>{formik.errors.username}</FormFeedback>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label htmlFor='email'>Email</Label>
              <Input
                type='text'
                id='email'
                name='email'
                placeholder='Email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!ctx.isNew}
                invalid={formik.touched.email && !!formik.errors.email}
              />
              <FormFeedback>{formik.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor='password'>Alterar Senha</Label>
              <Input
                type='password'
                id='password'
                name='password'
                placeholder='Senha'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.password && !!formik.errors.password}
              />
              <FormFeedback>{formik.errors.password}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label htmlFor='confirmPassword'>
                Digite novamente sua senha
              </Label>
              <Input
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                placeholder='Senha'
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.confirmPassword &&
                  !!formik.errors.confirmPassword
                }
              />
              <FormFeedback>{formik.errors.confirmPassword}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
