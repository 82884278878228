import React, { createContext, useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import api from 'api'
import Loader from 'components/Loader'

export const HomeContext = createContext()

export default function HomeProvider({ children }) {
  const [summary, setSummary] = useState(null)
  const [isReloading, setIsReloading] = useState(false)
  const [isStartingShift, setIsStartingShift] = useState(false)
  const [isSendingBulletinContent, setIsSendingBulletinContent] = useState(
    false
  )

  useEffect(() => loadInfo(), [])

  const loadInfo = () => {
    setIsReloading(true)
    api
      .get('/summary')
      .then(({ data }) => {
        setSummary(data)
        setIsReloading(false)
      })
      .catch(e => {
        toastr.error(e.response?.data || 'Erro')
        setIsReloading(false)
      })
  }

  const startShift = () => {
    setIsStartingShift(true)
    api
      .put('/shifts')
      .then(({ data: { shiftId, user } }) => {
        setSummary({
          ...summary,
          currentShift: {
            shiftId,
            user,
          },
        })
        setIsStartingShift(false)
      })
      .catch(err => {
        setIsStartingShift(false)
        toastr.error(err.response?.data)
      })
  }

  const sendBulletingContent = async content => {
    setIsSendingBulletinContent(true)
    return api
      .post('/bulletin-board', { content })
      .then(({ data }) => {
        setIsSendingBulletinContent(false)
        console.log(data)
        setSummary({
          ...summary,
          bulletinBoard: [data, ...summary.bulletinBoard],
        })
        return true
      })
      .catch(err => {
        setIsSendingBulletinContent(false)
        toastr.error(err.response?.data)
        return false
      })
  }

  if (!summary) {
    return <Loader />
  }

  return (
    <HomeContext.Provider
      value={{
        summary,
        isReloading,
        isStartingShift,
        isSendingBulletinContent,
        loadInfo,
        startShift,
        sendBulletingContent,
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}
