import React from 'react'
import { Link } from 'react-router-dom'
import { stringToCurrency } from 'util/currency-util'
import useGetApi from 'hooks/useGetApi'
import Loader from 'components/Loader'
import { Button, Container, Row, Col, Card, CardBody, Table } from 'reactstrap'

export default function RoomTypes() {
  const [roomTypes, isLoading] = useGetApi('/admin/room-types')

  if (isLoading) return <Loader />

  return (
    <Container fluid className='p-0'>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='h3 mb-3'>Categorias de Quarto</h1>

        <div>
          <Link to='/admin/room-types/new'>
            <Button outline color='primary' className='mt-n1 ml-2'>
              Nova Categoria
            </Button>
          </Link>
        </div>
      </div>

      <Row>
        <Col>
          <RoomTypesTable roomTypes={roomTypes} />
        </Col>
      </Row>
    </Container>
  )
}

function RoomTypesTable({ roomTypes }) {
  return (
    <Card>
      <CardBody>
        <Container>
          {roomTypes.map(roomType => (
            <div key={roomType.roomTypeId}>
              <div className='d-flex justify-content-between'>
                <h3>{roomType.title}</h3>
                <Link to={`/admin/room-types/${roomType.roomTypeId}`}>
                  Editar
                </Link>
              </div>

              <p
                style={{
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                {roomType.description}
              </p>

              <Table className='mb-5' bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>Tempo</th>
                    <th>Hóspede Extra</th>
                    <th>Dom</th>
                    <th>Seg</th>
                    <th>Ter</th>
                    <th>Qua</th>
                    <th>Qui</th>
                    <th>Sex</th>
                    <th>Sab</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Preço Padrão</th>
                    <td>{roomType.pricing.hourly.initialTimeMinutes} min</td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.hourly.additionalGuestRate
                      )}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.rate['1'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.rate['2'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.rate['3'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.rate['4'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.rate['5'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.rate['6'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.rate['7'])}
                    </td>
                  </tr>
                  <tr>
                    <th>Hora Adicional</th>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.extraTime['1'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.extraTime['2'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.extraTime['3'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.extraTime['4'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.extraTime['5'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.extraTime['6'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.hourly.extraTime['7'])}
                    </td>
                  </tr>
                  <tr>
                    <th>Pernoite</th>
                    <td>{roomType.pricing.overnight.initialTimeMinutes} min</td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.additionalGuestRate
                      )}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.overnight.rate['1'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.overnight.rate['2'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.overnight.rate['3'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.overnight.rate['4'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.overnight.rate['5'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.overnight.rate['6'])}
                    </td>
                    <td>
                      {stringToCurrency(roomType.pricing.overnight.rate['7'])}
                    </td>
                  </tr>
                  <tr>
                    <th>Hora Adicional</th>
                    <th>-</th>
                    <td>-</td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.extraTime['1']
                      )}
                    </td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.extraTime['2']
                      )}
                    </td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.extraTime['3']
                      )}
                    </td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.extraTime['4']
                      )}
                    </td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.extraTime['5']
                      )}
                    </td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.extraTime['6']
                      )}
                    </td>
                    <td>
                      {stringToCurrency(
                        roomType.pricing.overnight.extraTime['7']
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ))}
        </Container>
      </CardBody>
    </Card>
  )
}
