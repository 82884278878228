import React from 'react'
import useGetApi from 'hooks/useGetApi'
import Loader from 'components/Loader'
import api from 'api'
import { Trash } from 'react-feather'
import { Container, Col, Card, Table } from 'reactstrap'

export default function Sessions() {
  const [sessions, isLoading, , setSessions] = useGetApi('/sessions')

  return (
    <Container fluid className='p-0'>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='h3 mb-3'>Sessões</h1>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <Col md='6'>
          <SessionsTable sessions={sessions} setSessions={setSessions} />
        </Col>
      )}
    </Container>
  )
}

function SessionsTable({ sessions, setSessions }) {
  const deleteSession = session => {
    api.delete(`/sessions/${session}`).then(() => {
      const updatedSessions = sessions.filter(s => s.session !== session)
      setSessions(updatedSessions)
    })
  }

  return (
    <Card className='flex-fill w-100'>
      <Table striped>
        <thead>
          <tr>
            <th>Usuário</th>
            <th>Plataforma</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sessions?.map(session => {
            const sessionComponents = session.session.split(':')

            let platform
            switch (sessionComponents[sessionComponents.length - 1]) {
              case 'mobile':
                platform = 'Mobile'
                break
              case 'electron':
                platform = 'Desktop'
                break
              default:
                platform = 'Plataforma desconhecida'
                break
            }

            return (
              <tr key={session.session}>
                <td>{session.username}</td>
                <td>{platform}</td>
                <td className='text-right'>
                  <button
                    className='btn btn-icon btn-light btn-sm'
                    onClick={() => deleteSession(session.session)}
                  >
                    <Trash size={18} />
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Card>
  )
}
