import React, { createContext, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
import api, { baseURL } from 'api'
import { fullRoomRates } from 'util/motel-room-pricing'
import Loader from 'components/Loader'
import { isElectron } from 'util/electron-util'
import {
  guestCheckoutInvoiceData,
  companyCheckoutInvoiceData,
} from 'services/printer/reservation-invoice'

const Context = createContext()

function ReservationProvider({ children, reservationId }) {
  const history = useHistory()

  const interval = useRef(null)
  const { token } = useSelector(state => state.auth)
  const printer = useSelector(state => state.printer)

  const [now, setNow] = useState(moment())
  const [reservationInfo, setReservationInfo] = useState(null)
  const [activePage, setActivePage] = useState('Invoice')
  const [hospitalityItems, setHospitalityItems] = useState([])
  const [productItems, setProductItems] = useState([])
  const [discountItems, setDiscountItems] = useState([])
  const [invoiceTotal, setInvoiceTotal] = useState(0)

  const [isCheckingOut, setIsCheckingOut] = useState(false)
  const [isReleasingRoom, setIsReleasingRoom] = useState(false)
  const [isSavingPayment, setIsSavingPayment] = useState(false)
  const [isSavingDiscount, setIsSavingDiscount] = useState(false)
  const [isDeletingPayment, setIsDeletingPayment] = useState(false)
  const [isDeletingInvoiceItem, setIsDeletingInvoiceItem] = useState(false)

  useEffect(() => {
    const socket = io(baseURL + '/reservation', {
      autoConnect: false,
      reconnection: true,
      auth: { token, reservationId },
    })

    const setReservation = data => setReservationInfo(data)
    const setReservationUpdate = data => setReservationInfo(data)

    socket.on('reservation:info', setReservation)
    socket.on('reservation:update', setReservationUpdate)
    socket.connect()

    return () => {
      socket.off('reservation:info', setReservation)
      socket.off('reservation:update', setReservationUpdate)
      socket.disconnect()
    }
  }, [token])

  // Start the timer
  useEffect(() => {
    if (reservationInfo && !reservationInfo.reservation.checkout)
      interval.current = setInterval(() => setNow(moment()), 1000)
    return () => clearInterval(interval.current)
  }, [reservationInfo])

  // Calculate room rate and invoice total on every timer tick
  useEffect(() => {
    if (reservationInfo) {
      const { room, reservation } = reservationInfo
      const rate = fullRoomRates(room, reservation)
      setHospitalityItems(rate.hospitalityItems)
      setProductItems(rate.productItems)
      setDiscountItems(rate.discountItems)
      setInvoiceTotal(rate.total)
    }
  }, [now, reservationInfo])

  if (!reservationInfo) return <Loader />

  const changeRoom = selectedRoomId => {
    api
      .put(`/rooms/${reservationInfo.room.roomId}/change-reservation-room`, {
        reservationId,
        newRoomId: selectedRoomId,
      })
      .then(() => {
        history.push('/rooms')
      })
      .catch(e => {
        toastr.error(e.response.data)
      })
  }

  const onSaveProduct = () => {
    setActivePage('Invoice')
  }

  const addPayment = async (method, amount) => {
    const value = parseFloat(amount.replace(',', '.'))
    if (isNaN(value) || value <= 0) {
      toastr.error('Valor inválido')
      return
    }

    setIsSavingPayment(true)
    return api
      .post(`/reservations/${reservationId}/payment`, {
        method,
        amount: value,
      })
      .then(() => {
        setIsSavingPayment(false)
        return true
      })
      .catch(e => {
        setIsSavingPayment(false)
        toastr.error(e.response.data)
        return false
      })
  }

  const addDiscount = async (description, amount) => {
    const value = parseFloat(amount.replace(',', '.'))
    if (isNaN(value) || value <= 0) {
      toastr.error('Valor inválido')
      return
    }

    setIsSavingDiscount(true)
    return api
      .post(`/reservations/${reservationId}/discount`, {
        description,
        amount: value,
      })
      .then(() => {
        setIsSavingDiscount(false)
        return true
      })
      .catch(e => {
        setIsSavingDiscount(false)
        toastr.error(e.response?.data)
        return false
      })
  }

  const deleteDiscount = async item => {
    setIsDeletingInvoiceItem(true)
    api
      .delete(`/reservations/${reservationId}/discount/${item.itemId}`)
      .then(() => {
        setIsDeletingInvoiceItem(false)
      })
      .catch(e => {
        setIsDeletingInvoiceItem(false)
        toastr.error(e.response.data)
      })
  }

  const deletePayment = paymentId => {
    setIsDeletingPayment(true)
    api
      .delete(`/reservations/${reservationId}/payment/${paymentId}`)
      .then(() => {
        setIsDeletingPayment(false)
      })
      .catch(e => {
        setIsDeletingPayment(false)
        toastr.error(e.response.data)
      })
  }

  const deleteInvoiceItem = item => {
    setIsDeletingInvoiceItem(true)
    api
      .delete(`/reservations/${reservationId}/invoice-item/${item.itemId}`)
      .then(() => {
        setIsDeletingInvoiceItem(false)
      })
      .catch(e => {
        setIsDeletingInvoiceItem(false)
        toastr.error(e.response.data)
      })
  }

  const performCheckout = () => {
    setIsCheckingOut(true)
    api
      .put(`/reservations/${reservationId}/checkout`)
      .then(() => {
        setIsCheckingOut(false)
        toastr.success('Fatura fechada com sucesso')
        printCostumerInvoice()
      })
      .catch(e => {
        setIsCheckingOut(false)
        toastr.error(e.response.data)
      })
  }

  const releaseRoom = () => {
    setIsReleasingRoom(true)
    api
      .post(`/reservations/${reservationId}/release`)
      .then(() => {
        setIsReleasingRoom(false)
        printCompanyInvoice()
        toastr.success('Quarto liberado com sucesso')
        history.push('/rooms')
      })
      .catch(e => {
        setIsReleasingRoom(false)
        toastr.error(e.response.data)
      })
  }

  const printCostumerInvoice = () => {
    if (isElectron) {
      const { ipcRenderer } = window.require('electron')
      const invoiceData = guestCheckoutInvoiceData(
        reservationInfo,
        hospitalityItems,
        productItems,
        discountItems,
        invoiceTotal
      )
      ipcRenderer.send(
        'print',
        JSON.stringify({ printer, content: invoiceData })
      )
    }
  }

  const printCompanyInvoice = () => {
    if (isElectron) {
      const { ipcRenderer } = window.require('electron')
      const invoiceData = companyCheckoutInvoiceData(
        reservationInfo,
        hospitalityItems,
        productItems,
        discountItems,
        invoiceTotal
      )
      ipcRenderer.send(
        'print',
        JSON.stringify({ printer, content: invoiceData })
      )
    }
  }

  const onNewComment = newComment => {
    setReservationInfo({
      ...reservationInfo,
      reservation: {
        ...reservationInfo.reservation,
        vehicle: {
          ...reservationInfo.reservation.vehicle,
          metadata: {
            ...reservationInfo.reservation.vehicle.metadata,
            comments: reservationInfo.reservation.vehicle.metadata?.comments
              ? [
                  ...reservationInfo.reservation.vehicle.metadata.comments,
                  newComment,
                ]
              : [newComment],
          },
        },
      },
    })
  }

  return (
    <Context.Provider
      value={{
        now,
        reservationInfo,
        activePage,
        setActivePage,
        hospitalityItems,
        productItems,
        discountItems,
        invoiceTotal,
        changeRoom,
        onSaveProduct,
        addPayment,
        addDiscount,
        deleteDiscount,
        deletePayment,
        deleteInvoiceItem,
        performCheckout,
        releaseRoom,
        printCompanyInvoice,
        onNewComment,
        isCheckingOut,
        isReleasingRoom,
        isSavingPayment,
        isSavingDiscount,
        isDeletingPayment,
        isDeletingInvoiceItem,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, ReservationProvider }
