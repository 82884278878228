import React, { useContext } from 'react'
import { Context } from './ShiftContext'
import { stringToCurrency } from 'util/currency-util'

import {
  Card,
  CardHeader,
  CardTitle,
  UncontrolledTooltip,
  Table,
} from 'reactstrap'

export const Payments = () => {
  const ctx = useContext(Context)

  const financialTransactions = ctx.shift.financialTransactions

  const revenue = financialTransactions.reduce(
    (acc, item) =>
      item.type === 'Receita' ? acc + parseFloat(item.value) : acc,
    0
  )

  const expenses = financialTransactions.reduce(
    (acc, item) =>
      item.type === 'Despesa' ? acc + parseFloat(item.value) : acc,
    0
  )

  const withdrawals = financialTransactions.reduce(
    (acc, item) =>
      item.type === 'Sangria' ? acc + parseFloat(item.value) : acc,
    0
  )

  const payments = financialTransactions
    .filter(t => t.type === 'Receita')
    .map(t => t.invoice.payments)
    .flat()
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.method]:
          parseFloat(acc[item.method] || 0) + parseFloat(item.amount),
      }),
      {}
    )

  const balance =
    revenue -
    expenses -
    withdrawals -
    Object.keys(payments)
      .filter(method => method !== 'Dinheiro')
      .reduce((acc, method) => acc + payments[method], 0)

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Fluxo de Caixa
        </CardTitle>
      </CardHeader>

      <hr className='my-0' />

      <Table striped size='sm'>
        <tbody>
          <tr>
            <td>+ Faturamento</td>
            <td>{stringToCurrency(revenue)}</td>
          </tr>

          <tr>
            <td>- Despesas</td>
            <td>{stringToCurrency(expenses)}</td>
          </tr>

          <tr>
            <td>- Sangrias</td>
            <td>{stringToCurrency(withdrawals)}</td>
          </tr>

          {Object.keys(payments)
            .sort()
            .map(key => (
              <tr key={key}>
                <td>
                  {key === 'Dinheiro' ? '' : '-'}{' '}
                  {key === 'Dinheiro' ? '(Dinheiro)' : key}
                </td>
                <td>{stringToCurrency(payments[key])}</td>
              </tr>
            ))}

          <tr>
            <td>
              <strong id='valor_caixa'>= Valor em Caixa</strong>
              <UncontrolledTooltip placement='bottom' target='valor_caixa'>
                O valor de caixa é calculado da seguinte maneira:
                <br />
                (Faturamento) - (Despesas) - (Sangrias) - (Formas de pagamento
                exceto dinheiro).
                <br />É indicado zerar o caixa antes dos fechamentos de turno.
              </UncontrolledTooltip>
            </td>
            <td>
              <strong>{stringToCurrency(balance)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}
