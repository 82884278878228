export class CheckinGuest {
  constructor(data) {
    this.roomId = data?.roomId || ''
    this.transportation = data?.transportation || 'Carro'
    this.plate = data?.plate || ''
    this.noPlate = data?.noPlate || false
    this.isOvernight = data?.isOvernight || false

    this.captureId = data?.captureId

    this.companyId = data?.companyId
    this.img64 = data?.img64
    this.visits = data?.visits

    this.mode = 'Checkin' // Checkin | Cancellation
  }

  isAutomaticCheckin() {
    return !!this.captureId
  }
}
