import React, { useContext } from 'react'
import { HomeContext } from './HomeContext'
import { Bar } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { Badge, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

const ClienstLine = ({ theme }) => {
  const {
    summary: { lastClients },
  } = useContext(HomeContext)

  const data = {
    labels: lastClients.map(i => i.hour).reverse(),
    datasets: [
      {
        backgroundColor: theme.primary,
        borderColor: theme.primary,
        hoverBackgroundColor: theme.primary,
        hoverBorderColor: theme.primary,
        data: lastClients.map(i => i.guests).reverse(),
        barPercentage: 0.75,
        categoryPercentage: 0.75,
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            stepSize: 1,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: 'transparent',
          },
        },
      ],
    },
  }

  return (
    <Card className='flex-fill w-100'>
      <CardHeader>
        <Badge color='primary' className='float-right'>
          últimas 24 horas
        </Badge>
        <CardTitle tag='h5' className='mb-0'>
          Hóspedes
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className='chart chart-lg'>
          <Bar data={data} options={options} />
        </div>
      </CardBody>
    </Card>
  )
}

export default connect(store => ({
  theme: store.theme.currentTheme,
}))(ClienstLine)
