import React, { useContext } from 'react'
import { Context } from './ShiftContext'

import { Card, CardHeader, CardTitle, Table } from 'reactstrap'

export const Products = () => {
  const ctx = useContext(Context)

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Produtos Vendidos
        </CardTitle>
      </CardHeader>

      <hr className='my-0' />

      <Table size='sm' striped>
        <thead>
          <tr>
            <th>Qnt</th>
            <th>Produto</th>
          </tr>
        </thead>
        <tbody>
          {ctx.shift.productsSold?.map((product) => (
            <tr key={product.title}>
              <td>{product.quantity}</td>
              <td>{product.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
