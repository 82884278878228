import * as types from '../constants'

const initialState = {
  rooms: [],
  isLoading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ROOMS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case types.ROOMS_LOADED:
      return {
        rooms: action.payload,
        isLoading: false,
      }
    case types.ROOM_LOADED:
      return {
        ...state,
        rooms: state.rooms.map(room =>
          room.roomId === action.payload.roomId
            ? { ...room, ...action.payload }
            : room
        ),
      }
    case types.ROOMS_CLEAR:
      return {
        rooms: [],
        isLoading: false,
      }
    default:
      return state
  }
}
