import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'

import { Badge, Collapse } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Zap } from 'react-feather'

import routes from '../routes/index-template'

const initOpenRoutes = location => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname

  let _routes = {}

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0
    const isOpen = route.open
    const isHome = route.containsHome && pathName === '/' ? true : false

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    })
  })

  return _routes
}

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === '/' && path === '/dashboard')
        ? 'active'
        : ''
    }

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(to)}>
        <span
          data-toggle='collapse'
          className={'sidebar-link ' + (!isOpen ? 'collapsed' : '')}
          onClick={onClick}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          <Icon size={18} className='align-middle mr-3' />
          <span className='align-middle'>{name}</span>
          {badgeColor && badgeText && (
            <Badge color={badgeColor} size={18} className='sidebar-badge'>
              {badgeText}
            </Badge>
          )}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id='item' className={'sidebar-dropdown list-unstyled'}>
            {children}
          </ul>
        </Collapse>
      </li>
    )
  }
)

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const { users } = useSelector(state => state.chat)

    const getSidebarItemClass = path => {
      return location.pathname === path ? 'active' : ''
    }

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(to)}>
        <NavLink to={to} className='sidebar-link' activeClassName='active'>
          {Icon ? <Icon size={18} className='align-middle mr-3' /> : null}
          {name}
          {name === 'Chat' ? (
            <Badge color='info' size={18} className='sidebar-badge'>
              {users.reduce((acc, item) => item.newMessagesCount + acc, 0) > 0
                ? users.reduce((acc, item) => item.newMessagesCount + acc, 0)
                : ''}
            </Badge>
          ) : badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className='sidebar-badge'>
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    )
  }
)

const Sidebar = ({ location, sidebar, layout }) => {
  const userRole = useSelector(state => state.auth?.user?.role)
  const currentCompany = useSelector(state => state.auth?.currentCompany)
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location))

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item =>
        openRoutes[index] ||
        setOpenRoutes(openRoutes =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    )

    // Toggle selected element
    setOpenRoutes(openRoutes =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    )
  }

  return (
    <nav
      className={
        'sidebar' +
        (!sidebar.isOpen ? ' toggled' : '') +
        (sidebar.isSticky ? ' sidebar-sticky' : '')
      }
    >
      <div className='sidebar-content'>
        <PerfectScrollbar>
          <NavLink className='sidebar-brand' to='/home'>
            <Zap className='align-middle text-primary' size={24} />{' '}
            <span className='align-middle'>
              {currentCompany ? currentCompany.name : 'Motelo'}
            </span>
          </NavLink>

          <ul className='sidebar-nav'>
            {routes
              .filter(route =>
                route.allowedRoles
                  ? route.allowedRoles.includes(userRole)
                  : true
              )
              .map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <li className='sidebar-header'>{category.header}</li>
                    ) : null}

                    {category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={openRoutes[index]}
                        onClick={() => toggle(index)}
                      >
                        {category.children.map((route, index) => {
                          if (!route.hideInSidebar) {
                            return (
                              <SidebarItem
                                key={index}
                                name={route.name}
                                to={route.path}
                                badgeColor={route.badgeColor}
                                badgeText={route.badgeText}
                              />
                            )
                          }
                          return <React.Fragment key={index}></React.Fragment>
                        })}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    )}
                  </React.Fragment>
                )
              })}
          </ul>

          {/* {!layout.isBoxed && !sidebar.isSticky ? (
            <div className='sidebar-bottom d-none d-lg-block'>
              <div className='media'>
                <img
                  className='rounded-circle mr-3'
                  src={avatar}
                  alt='Chris Wood'
                  width='40'
                  height='40'
                />
                <div className='media-body'>
                  <h5 className='mb-1'>Chris Wood</h5>
                  <div>
                    <FontAwesomeIcon icon={faCircle} className='text-success' />{' '}
                    Online
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar,
    layout: store.layout,
  }))(Sidebar)
)
