import React, { useContext, useState } from 'react'
import { Context } from './ReservationContext'
import ConfirmationDialog from 'components/ConfirmationDialog'
import GoBack from 'components/GoBack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'

import Car from '@material-ui/icons/DirectionsCarTwoTone'
import Motorcycle from '@material-ui/icons/MotorcycleTwoTone'
import Pedestrian from '@material-ui/icons/DirectionsWalkTwoTone'
import CameraIcon from '@material-ui/icons/LinkedCameraTwoTone'

export function Header() {
  const ctx = useContext(Context)

  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false)
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false)

  const icons = () => {
    if (!ctx.reservationInfo) return <></>

    return (
      <span style={{ marginLeft: 12 }}>
        {!!ctx.reservationInfo.reservation.captureId && (
          <CameraIcon style={{ height: 18, marginBottom: 4 }} />
        )}
        {ctx.reservationInfo.reservation.transportation === 'Carro' && (
          <Car style={{ height: 20, marginBottom: 4 }} />
        )}
        {ctx.reservationInfo.reservation.transportation === 'Moto' && (
          <Motorcycle style={{ height: 20, marginBottom: 4 }} />
        )}
        {ctx.reservationInfo.reservation.transportation === 'Pedestre' && (
          <Pedestrian style={{ height: 20, marginBottom: 4 }} />
        )}
      </span>
    )
  }

  if (ctx.reservationInfo.reservation.roomReleasedAt) {
    return (
      <div className='d-flex mb-2'>
        <h1 className='h3 mb-3'>
          <GoBack />
          {ctx.reservationInfo?.room.title}
          {icons()}
        </h1>
      </div>
    )
  }

  return (
    <div className='d-flex justify-content-between mb-2'>
      <h1 className='h3 mb-3'>
        <GoBack />
        {ctx.reservationInfo?.room.title}
        {icons()}
      </h1>

      <div className='d-block d-md-none'>
        <UncontrolledButtonDropdown>
          <DropdownToggle color='primary' caret>
            Opções
          </DropdownToggle>
          <DropdownMenu>
            {!ctx.reservationInfo.reservation.checkout && (
              <>
                <DropdownItem onClick={() => ctx.setActivePage('ChangeRoom')}>
                  Alterar Quarto
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            <DropdownItem onClick={() => ctx.setActivePage('ProductSale')}>
              <FontAwesomeIcon icon={faPlus} /> Consumo
            </DropdownItem>
            <DropdownItem onClick={() => ctx.setActivePage('Discount')}>
              <FontAwesomeIcon icon={faPlus} /> Desconto
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={!!ctx.reservationInfo.reservation.checkout}
              onClick={() => setIsCheckoutModalOpen(true)}
            >
              Fechar Fatura
            </DropdownItem>
            <DropdownItem
              disabled={!ctx.reservationInfo.reservation.checkout}
              onClick={() => setIsReleaseModalOpen(true)}
            >
              Liberar Quarto
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>

      <div className='d-none d-md-block'>
        {!ctx.reservationInfo.reservation.checkout && (
          <Button
            outline
            color='primary'
            className='mt-n1 ml-2'
            onClick={() => ctx.setActivePage('ChangeRoom')}
          >
            Alterar Quarto
          </Button>
        )}

        <Button
          outline
          color='primary'
          className='mt-n1 ml-2'
          onClick={() => ctx.setActivePage('ProductSale')}
        >
          <FontAwesomeIcon icon={faPlus} /> Consumo
        </Button>

        <Button
          outline
          color='primary'
          className='mt-n1 ml-2'
          onClick={() => ctx.setActivePage('Discount')}
        >
          <FontAwesomeIcon icon={faPlus} /> Desconto
        </Button>

        <Button
          outline
          color='primary'
          className='mt-n1 ml-2'
          disabled={!!ctx.reservationInfo.reservation.checkout}
          onClick={() => setIsCheckoutModalOpen(true)}
        >
          Fechar Fatura
        </Button>

        <Button
          outline
          color='primary'
          className='mt-n1 ml-2'
          disabled={!ctx.reservationInfo.reservation.checkout}
          onClick={() => setIsReleaseModalOpen(true)}
        >
          Liberar Quarto
        </Button>
      </div>

      <ConfirmationDialog
        isOpen={isCheckoutModalOpen}
        setIsOpen={setIsCheckoutModalOpen}
        title='Fechar Fatura'
        body='Deseja fechar esta fatura e realizar o checkout?'
        onConfirm={ctx.performCheckout}
      />

      <ConfirmationDialog
        isOpen={isReleaseModalOpen}
        setIsOpen={setIsReleaseModalOpen}
        title='Liberar Quarto'
        body='Deseja liberar este quarto e colocá-lo em limpeza?'
        onConfirm={ctx.releaseRoom}
      />
    </div>
  )
}
