import React, { createContext, useState } from 'react'
import useGetApi from 'hooks/useGetApi'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import api from 'api'

const RoomTypeContext = createContext()

const RoomTypeProvider = ({ children, roomTypeId }) => {
  const history = useHistory()
  const [roomType] = useGetApi(
    !!roomTypeId ? `/admin/room-types/${roomTypeId}` : null
  )

  const [isSaving, setIsSaving] = useState(false)

  if (!roomType) return <div>Carregando...</div>

  const submit = values => {
    const extractFloat = obj => {
      return Object.entries(obj).reduce(
        (acc, [key, item]) => ({
          ...acc,
          [key]: parseFloat(item),
        }),
        {}
      )
    }

    const data = {
      title: values.title,
      description: values.description || '',
      pricing: {
        allowance: values.pricing.allowance,
        hourly: {
          rate: extractFloat(values.pricing.hourly.rate),
          extraTime: extractFloat(values.pricing.hourly.extraTime),
          additionalGuestRate: parseFloat(
            values.pricing.hourly.additionalGuestRate
          ),
          initialTimeMinutes: values.pricing.hourly.initialTimeMinutes,
        },
        overnight: {
          rate: extractFloat(values.pricing.overnight.rate),
          extraTime: extractFloat(values.pricing.overnight.extraTime),
          additionalGuestRate: parseFloat(
            values.pricing.overnight.additionalGuestRate
          ),
          initialTimeMinutes: values.pricing.overnight.initialTimeMinutes,
        },
      },
    }

    setIsSaving(true)
    api
      .put(`/admin/room-types/${roomTypeId}`, data)
      .then(() => {
        setIsSaving(false)
        history.push('/admin/room-types')
      })
      .catch(e => {
        setIsSaving(false)
        toastr.error(e.response.data)
      })
  }

  return (
    <RoomTypeContext.Provider value={{ roomType, isSaving, submit }}>
      {children}
    </RoomTypeContext.Provider>
  )
}

export { RoomTypeContext, RoomTypeProvider }
