import React, { useContext, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Context } from '../ReservationContext'
import { stringToCurrency } from 'util/currency-util'
import { Trash } from 'react-feather'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Table,
  Input,
} from 'reactstrap'

export function Payment() {
  const ctx = useContext(Context)

  const [method, setMethod] = useState('Dinheiro')
  const [amount, setAmount] = useState('')

  const reservation = ctx.reservationInfo.reservation
  const invoiceTotal = ctx.invoiceTotal

  const payments = reservation.invoice.payments.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  )

  const paymentsTotal = payments.reduce(
    (acc, item) => acc + parseFloat(item.amount),
    0
  )

  const submit = e => {
    e.preventDefault()
    ctx.addPayment(method, amount).then(success => success && setAmount(''))
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Pagamentos
        </CardTitle>
      </CardHeader>

      <CardBody>
        <Form className='row' onSubmit={submit}>
          <Input
            className='ml-2'
            type='select'
            style={{ width: 150 }}
            onChange={e => setMethod(e.target.value)}
          >
            <option>Dinheiro</option>
            <option>Debito</option>
            <option>Credito</option>
            <option>PIX</option>
            <option>Cheque</option>
          </Input>

          <CurrencyInput
            className='form-control ml-3'
            style={{ width: 150 }}
            name='amountinput'
            placeholder='R$'
            allowNegativeValue={false}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            maxLength={10}
            value={amount}
            onValueChange={value => setAmount(value)}
          />

          <Button
            className='ml-3'
            color='primary'
            type='submit'
            disabled={
              !method ||
              !amount ||
              isNaN(parseFloat(amount)) ||
              amount <= 0 ||
              ctx.isSavingPayment
            }
          >
            Adicionar
          </Button>
        </Form>
      </CardBody>

      <Table>
        <thead>
          <tr>
            <th style={{ width: 200 }}>Forma</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {payments.map(payment => (
            <tr key={payment.paymentId}>
              <td>{payment.method}</td>
              <td>{stringToCurrency(payment.amount)}</td>
              <td className='text-right pr-3' style={{ minWidth: 90 }}>
                <button
                  className='btn btn-icon btn-light btn-sm'
                  disabled={ctx.isDeletingPayment}
                  onClick={() => ctx.deletePayment(payment.paymentId)}
                >
                  <Trash className='align-middle' size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr
            className={
              paymentsTotal - invoiceTotal < 0 || paymentsTotal === 0
                ? 'table-warning'
                : 'table-success'
            }
          >
            <td>
              <strong>TOTAL PAGO</strong>
            </td>
            <td>
              <strong>{stringToCurrency(paymentsTotal)}</strong>
            </td>
            <td></td>
          </tr>
          <tr
            className={
              paymentsTotal - invoiceTotal < 0 || paymentsTotal === 0
                ? 'table-warning'
                : 'table-success'
            }
          >
            <td>
              <strong>TOTAL FATURA</strong>
            </td>
            <td>
              <strong>{stringToCurrency(invoiceTotal)}</strong>
            </td>
            <td></td>
          </tr>
          {paymentsTotal - invoiceTotal < 0 && (
            <tr className='table-danger'>
              <td>RESTANTE</td>
              <td>{stringToCurrency(paymentsTotal - invoiceTotal)}</td>
              <td></td>
            </tr>
          )}
        </tfoot>
      </Table>
    </Card>
  )
}
