import React, { useContext } from 'react'
import { HomeContext } from './HomeContext'
import { User } from 'react-feather'
import { Button, Col, Card, CardBody, Media, Progress, Row } from 'reactstrap'

export default function Statistics() {
  const {
    summary: { roomStatus, currentShift },
    startShift,
    isStartingShift,
  } = useContext(HomeContext)

  const availableRoomCount =
    roomStatus.find(item => item.status === 'Disponível')?.count ?? 0
  const occupiedRoomCount =
    (roomStatus.find(item => item.status === 'Ocupado')?.count ?? 0) +
    (roomStatus.find(item => item.status === 'Pendente')?.count ?? 0)
  const housekeepingRoomCount =
    roomStatus.find(item => item.status === 'Limpeza')?.count ?? 0
  const totalRoomCount = roomStatus.reduce((acc, item) => acc + item.count, 0)

  return (
    <Row>
      <Col xs='6' md='6' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <User className='feather-lg text-primary' />
              </div>
              <Media body>
                {currentShift ? (
                  <h3 className='mb-2'>{currentShift?.user.firstname}</h3>
                ) : (
                  <Button
                    color='primary'
                    onClick={startShift}
                    disabled={isStartingShift}
                  >
                    Começar turno
                  </Button>
                )}

                <div className='mb-0'>Turno Atual</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='6' xl>
        <ProgressStatisticsCard
          title='Disponíveis'
          badge='agora'
          statusRoomCount={availableRoomCount}
          totalRoomCount={totalRoomCount}
          color='success'
        />
      </Col>

      <Col xs='6' md='6' xl>
        <ProgressStatisticsCard
          title='Ocupados'
          badge='agora'
          statusRoomCount={occupiedRoomCount}
          totalRoomCount={totalRoomCount}
          color='warning'
        />
      </Col>

      <Col xs='6' md='6' xl>
        <ProgressStatisticsCard
          title='Limpeza'
          badge='agora'
          statusRoomCount={housekeepingRoomCount}
          totalRoomCount={totalRoomCount}
          color='info'
        />
      </Col>
    </Row>
  )
}

const ProgressStatisticsCard = ({
  title,
  badge,
  statusRoomCount,
  totalRoomCount,
  color,
}) => (
  <Card className='flex-fill'>
    <div
      style={{
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 12,
        paddingBottom: 10,
      }}
    >
      <span className={`badge badge-${color} float-right`}>{badge}</span>
      <h5 className='card-title mb-0'>{title}</h5>
    </div>
    <div style={{ paddingRight: 20, paddingLeft: 20, paddingBottom: 12 }}>
      <Row className='d-flex align-items-center mb-2'>
        <Col xs='8'>
          <h2 className='d-flex align-items-center mb-0 font-weight-light'>
            {statusRoomCount}
          </h2>
        </Col>
        <Col xs='4' className='text-right'>
          <span className='text-muted'>
            {((statusRoomCount / totalRoomCount) * 100).toFixed(0)}%
          </span>
        </Col>
      </Row>

      <Progress
        color={color}
        value={(statusRoomCount / totalRoomCount) * 100}
        className='progress-sm shadow-sm mb-1'
      />
    </div>
  </Card>
)
