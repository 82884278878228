import React from 'react'

import { Header } from './Header'
import { ProductSale } from '../sale/ProductSale'

export default function CounterSale() {
  return (
    <>
      <Header />
      <ProductSale />
    </>
  )
}
