import React, { useContext } from 'react'
import { UserContext, UserProvider } from './UserContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Container, Form, Button } from 'reactstrap'

import UserInfo from './UserInfo'
import UserAccount from './UserAccount'
import UserPermissions from './UserPermissions'

export default function UserSettings({ match }) {
  const userId = match.params.userId === 'new' ? null : match.params.userId
  return (
    <UserProvider userId={userId}>
      <UserLayout />
    </UserProvider>
  )
}

function UserLayout() {
  const ctx = useContext(UserContext)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: ctx.user?.firstname || '',
      surname: ctx.user?.surname || '',
      dob: ctx.user?.dob || '',
      gender: ctx.user?.gender || 'M',
      username: ctx.user?.username || '',
      email: ctx.user?.email || '',
      phone: ctx.user?.phone || '',
      password: '',
      confirmPassword: '',
      role: ctx.user?.userRole.role || 'Admin',
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required('Obrigatório')
        .min(2, 'Mínimo de 2 caracteres'),
      surname: Yup.string()
        .required('Obrigatório')
        .min(2, 'Mínimo de 2 caracteres'),
      dob: Yup.date().required('Obrigatório'),
      username: Yup.string()
        .required('Obrigatório')
        .min(4, 'Mínimo de 4 caracteres'),
      email: Yup.string()
        .email('Endereço de email inválido')
        .required('Obrigatório'),
      password: Yup.string().optional().min(6, 'Mínimo de 6 caracteres'),
      confirmPassword: Yup.string()
        .optional()
        .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais'),
    }),
    onSubmit: values => ctx.onSubmit(values),
  })

  return (
    <Container fluid className='p-0'>
      <Form onSubmit={formik.handleSubmit}>
        <div className='d-flex justify-content-between mb-2'>
          <h1 className='h3 mb-3'>Configurações de Usuário</h1>

          <div>
            <Button
              outline
              type='submit'
              color='primary'
              className='ml-2'
              disabled={ctx.isLoading || ctx.isUpdating}
            >
              {ctx.isNew ? 'Salvar Novo Usuário' : 'Salvar Alterações'}
            </Button>

            {!ctx.isNew && (
              <Button
                outline
                type='submit'
                color='danger'
                className='ml-2'
                onClick={ctx.deleteUser}
                disabled={ctx.isLoading || ctx.isUpdating}
              >
                Deletar Usuário
              </Button>
            )}
          </div>
        </div>

        <UserInfo formik={formik} />
        <UserAccount formik={formik} />
        <UserPermissions formik={formik} />
      </Form>
    </Container>
  )
}
