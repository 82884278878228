import React, { useContext, useState } from 'react'
import moment from 'moment'
import { ProductContext } from './ProductContext'
import {
  Button,
  FormGroup,
  FormText,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Input,
  Label,
  Table,
} from 'reactstrap'

export function ProductFlow() {
  const { productInfo, isUpdatingProductFlow, updateProductFlow } = useContext(
    ProductContext
  )

  const [quantity, setQuantity] = useState(0)
  const [category, setCategory] = useState('Entrada')

  const quantityText = () => {
    switch (category) {
      case 'Entrada':
        return 'Digite a quantidade adquirida do produto.'
      case 'Descarte':
        return 'Digite a quantidade descartada do produto.'
      case 'Ajuste':
        return 'Digite a quantidade do produto em relação ao estoque atual.'
      case 'RetornoFornecedor':
        return 'Digite a quantidade do produto que foi retornada ao fornecedor.'
      default:
        return ''
    }
  }

  const categoryText = () => {
    switch (category) {
      case 'Entrada':
        return 'Entrada de produto através de compra com fornecedores.'
      case 'Descarte':
        return 'Descarte de produto devido à avarias ou vencimento.'
      case 'Ajuste':
        return 'Ajuste para quando quantidade real de estoque do produto é diferente do que está cadastrado.'
      case 'RetornoFornecedor':
        return 'Retorno ao fornecedor quando entrada de produto foi realizada, mas produto foi devolvido ao fornecedor.'
      default:
        return ''
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          Movimentação do Produto
        </CardTitle>
      </CardHeader>

      <CardBody>
        <div>
          Estoque atual: <strong>{productInfo.product.stock}</strong>
        </div>
        <div className='mb-2'>
          Estoque após atualização:{' '}
          <strong>
            {parseInt(productInfo.product.stock) + parseInt(quantity || 0)}
          </strong>
        </div>

        <Row>
          <Col md='4'>
            <FormGroup>
              <Label htmlFor='quantity'>Quantidade</Label>
              <Input
                type='number'
                id='quantity'
                name='quantity'
                placeholder='Quantidade'
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
              />
              <FormText color='muted'>{quantityText()}</FormText>
            </FormGroup>
          </Col>

          <Col md='4'>
            <FormGroup>
              <Label htmlFor='category'>Categoria</Label>
              <Input
                type='select'
                id='category'
                name='category'
                placeholder='Categoria'
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                <option value='Entrada'>Entrada</option>
                <option value='Ajuste'>Ajuste</option>
                <option value='Descarte'>Descarte</option>
                <option value='RetornoFornecedor'>Retorno Fornecedor</option>
              </Input>
              <FormText color='muted'>{categoryText()}</FormText>
            </FormGroup>
          </Col>

          <Col sm='12' md='4'>
            <Button
              outline
              color='primary'
              style={{ marginTop: 29 }}
              disabled={isUpdatingProductFlow}
              onClick={() => updateProductFlow(category, quantity)}
            >
              Salvar Movimentacao
            </Button>
          </Col>
        </Row>
      </CardBody>
      <Table size='sm'>
        <thead>
          <tr>
            <th>Data</th>
            <th>Movimentação</th>
            <th>Qtd</th>
          </tr>
        </thead>

        <tbody>
          {productInfo.productFlow.map(flow => (
            <tr key={flow.productFlowId}>
              <td>{moment(flow.date).format('DD/MM/YYYY HH:mm:ss')}</td>
              <td>{flow.type}</td>
              <td>
                {['Saida'].includes(flow.type) ? '-' : '+'} {flow.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}
