import api from '../api'
import { useEffect } from 'react'

export default function useSessionCheck() {
  // this hook force checks the status of the session
  // to see if it's still available. If the session
  // is no longer active, the system will route the
  // user to the login screen automatically.
  useEffect(() => {
    api.get('/sessions/status')
  }, [])
}
