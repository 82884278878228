import * as types from '../constants'

const initialState = {
  users: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CHAT_USERS:
      return {
        users: action.payload,
      }
    case types.CHAT_UPDATE_USER_ONLINE: {
      return {
        users: state.users.map(user => {
          if (user.userId === action.payload.userId) {
            return { ...user, online: action.payload.online }
          } else {
            return user
          }
        }),
      }
    }
    case types.CHAT_UPDATE_UNREAD_MESSAGES:
      return {
        users: state.users.map(user => {
          if (user.userId === action.payload.senderId) {
            return { ...user, newMessagesCount: user.newMessagesCount + 1 }
          } else {
            return user
          }
        }),
      }
    case types.CHAT_CLEAR_UNREAD_MESSAGES_COUNT:
      return {
        users: state.users.map(user => {
          if (user.userId === action.payload) {
            return { ...user, newMessagesCount: 0 }
          } else {
            return user
          }
        }),
      }
    case types.CHAT_LOGOUT:
      return {
        users: [],
      }
    default:
      return state
  }
}
