import React, { useContext } from 'react'
import moment from 'moment'
import { Context } from './ReservationContext'
import { calculateDuration } from 'util/reservation-duration'
import { stringToCurrency } from 'util/currency-util'
import { Download, Upload, Clock, DollarSign } from 'react-feather'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'

export const ReservationInfo = () => {
  const ctx = useContext(Context)

  const { checkin, checkout } = ctx.reservationInfo.reservation

  return (
    <Row className='justify-content-around'>
      <Col xs='6' md='6' lg='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <Download
                  className='feather-lg text-primary'
                  style={{ transform: 'rotate(270deg)' }}
                />
              </div>
              <Media body>
                <h3 className='mb-2'>{moment(checkin).format('HH:mm')}</h3>
                <div className='mb-0'>{moment(checkin).format('DD/MM/YY')}</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='6' lg='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <Upload
                  className='feather-lg text-warning'
                  style={{ transform: 'rotate(90deg)' }}
                />
              </div>
              <Media body>
                <h3 className='mb-2'>
                  {checkout ? moment(checkout).format('HH:mm') : '-'}
                </h3>
                <div className='mb-0'>
                  {checkout ? moment(checkout).format('DD/MM/YY') : 'Saída'}
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='6' lg='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <Clock className='feather-lg text-danger' />
              </div>
              <Media body>
                <h3 className='mb-2'>
                  {calculateDuration(
                    moment(checkin),
                    checkout ? moment(checkout) : ctx.now
                  )}
                </h3>
                <div className='mb-0'>Permanencia</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>

      <Col xs='6' md='6' lg='3' xl>
        <Card className='flex-fill'>
          <CardBody className='py-4'>
            <Media>
              <div className='d-inline-block mt-2 mr-3'>
                <DollarSign className='feather-lg text-success' />
              </div>
              <Media body>
                <h3 className='mb-2'>{stringToCurrency(ctx.invoiceTotal)}</h3>
                <div className='mb-0'>Total</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
