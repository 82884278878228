import React from 'react'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { SocketProvider } from './api/SocketProvider'

import store from './redux/store/index'
import Routes from './routes/Routes'
import Checkin from './pages/checkin/Checkin'

const App = () => {
  return (
    <Provider store={store}>
      <SocketProvider>
        <Routes />
        <Checkin />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position='bottom-right'
          transitionIn='fadeIn'
          transitionOut='fadeOut'
          progressBar
          closeOnToastrClick
        />
      </SocketProvider>
    </Provider>
  )
}

export default App
