import moment from 'moment'

export const calculateDuration = (startDate, endTime) => {
  const duration = moment.duration(endTime.diff(startDate))
  const hours = Math.floor(duration.asHours())
  const minutes = Math.floor(duration.asMinutes() % 60)
  const seconds = Math.floor(duration.asSeconds() % 60)
  return `${hours < 10 ? ('0' + hours).slice(-2) : hours}:${(
    '0' + minutes
  ).slice(-2)}:${('0' + seconds).slice(-2)}`
}
