import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from 'redux/actions/authActions'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap'
import Version from 'components/Version'

const SignIn = ({ history }) => {
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)

  useEffect(() => {
    if (auth.isAuthenticated) history.push('/home')
  }, [auth, history])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const login = e => {
    e.preventDefault()
    dispatch(signIn({ email, password }))
  }

  return (
    <React.Fragment>
      <div className='text-center'>
        <h2 className='mt-4'>Motelo</h2>
      </div>

      <div className='col-12 col-sm-8 col-lg-4' style={{ margin: 'auto' }}>
        <Card>
          <CardBody>
            <div className='m-sm-4'>
              <Form onSubmit={login}>
                <FormGroup>
                  <Label>Usuário/Email</Label>
                  <Input
                    bsSize='lg'
                    name='email'
                    placeholder='Digite seu usuário ou email'
                    onChange={e => setEmail(e.target.value.trim())}
                    autoFocus={true}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Senha</Label>
                  <Input
                    bsSize='lg'
                    type='password'
                    name='password'
                    placeholder='Digite sua senha'
                    onChange={e => setPassword(e.target.value)}
                  />
                  {/* <small>
                    <Link to='/auth/reset-password'>Esqueceu a senha?</Link>
                  </small> */}
                </FormGroup>
                {/* <div>
                  <CustomInput
                    type='checkbox'
                    id='rememberMe'
                    label='Remember me next time'
                    defaultChecked
                  />
                </div> */}
                <div className='text-center mt-3'>
                  <Button
                    type='submit'
                    disabled={auth.isLoading}
                    color='primary'
                    size='lg'
                  >
                    {auth.isLoading ? <Spinner color='white' /> : 'Entrar'}
                  </Button>
                  {auth.error && (
                    <Alert className='mt-4 p-2' color='danger'>
                      {auth.error}
                    </Alert>
                  )}
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
        <div className='text-center'>
          <Version />
        </div>
      </div>
    </React.Fragment>
  )
}

export default SignIn
