import * as types from '../constants'
import api from '../../api'

export const loadRooms = companyId => async dispatch => {
  dispatch({ type: types.ROOMS_LOADING })
  const route = companyId ? `/rooms?companyId=${companyId}` : '/rooms'
  api
    .get(route)
    .then(({ data: rooms }) => {
      dispatch({ type: types.ROOMS_LOADED, payload: rooms })
    })
    .catch(error => {
      console.log(error)
    })
}

export const setRooms = rooms => dispatch => {
  dispatch({ type: types.ROOMS_LOADED, payload: rooms })
}

export const setRoom = room => dispatch => {
  dispatch({ type: types.ROOM_LOADED, payload: room })
}
