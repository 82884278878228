import React, { createContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateUserInfo, updateUserImage } from 'redux/actions/authActions'
import { setChatUsers } from 'redux/actions/chatActions'
import { toastr } from 'react-redux-toastr'
import useGetApi from 'hooks/useGetApi'
import api from 'api'
import Loader from 'components/Loader'

const UserContext = createContext()

function UserProvider({ children, userId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const loggedInUserId = useSelector(state => state.auth.user?.userId)

  const [user, isLoading, , setUser] = useGetApi(
    !!userId ? `/admin/users/${userId}` : null
  )

  const [isUpdating, setIsUpdating] = useState(false)
  const [uploadingImage, setUploadingImage] = useState(false)

  const isNew = !userId

  if (!isNew && isLoading) return <Loader />

  const onSubmit = values => {
    if (isNew) createUser(values)
    else updateUser(values)
  }

  const createUser = values => {
    setIsUpdating(true)
    api
      .post('/admin/users', values)
      .then(() => {
        setIsUpdating(false)
        toastr.success('Novo usuário salvo com sucesso')
        history.push('/admin/users')
        api.get('/chat/users').then(({ data }) => dispatch(setChatUsers(data)))
      })
      .catch(e => {
        setIsUpdating(false)
        toastr.error(e.response.data)
      })
  }

  const updateUser = values => {
    if (values.password === '') {
      delete values.password
    }

    if (values.phone === '') {
      delete values.phone
    }

    setIsUpdating(true)
    api
      .put(`/admin/users/${userId}`, values)
      .then(({ data: user }) => {
        setIsUpdating(false)
        if (user.userId === loggedInUserId) {
          dispatch(updateUserInfo(user))
        }
        toastr.success('Informações alteradas com sucesso')
        history.push('/admin/users')
      })
      .catch(e => {
        setIsUpdating(false)
        toastr.error(e.response.data)
      })
  }

  const uploadUserImage = file => {
    const data = new FormData()
    data.append('file', file)

    setUploadingImage(true)
    api
      .put(`/users/${user.userId}/image`, data)
      .then(({ data }) => {
        if (user.userId === loggedInUserId) dispatch(updateUserImage(data))
        else setUser({ ...user, imageUrl: data })
        setUploadingImage(false)
      })
      .catch(e => {
        setUploadingImage(false)
        toastr.error(e.response?.data)
      })
  }

  const deleteUser = () => {
    setIsUpdating(true)
    api
      .delete(`/admin/users/${userId}`)
      .then(() => {
        toastr.success('Usuário deletado com sucesso')
        history.push('/admin/users')
      })
      .catch(e => {
        setIsUpdating(false)
        toastr.error(e.response.data)
      })
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isNew,
        isLoading,
        isUpdating,
        uploadingImage,
        onSubmit,
        uploadUserImage,
        deleteUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
